import React from "react"
import Icon from '@ant-design/icons';

function IconSvg()
{
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" style={{ stroke: "currentcolor" }} xmlns="http://www.w3.org/2000/svg">
      <g id="shield">
        <path id="Vector" d="M9.66667 18.6667C9.66667 18.6667 16.3333 15.3333 16.3333 10.3333V4.5L9.66667 2L3 4.5V10.3333C3 15.3333 9.66667 18.6667 9.66667 18.6667Z" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  )
}

export function IconShield(props)
{
  return <Icon component={IconSvg} {...props} />
}

