import React, { useEffect } from "react";
import styles from "./SidebarNew.module.css";
import { Layout, Menu, Button, ConfigProvider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getCollapsed } from "../../../store/sidebar/selectors";
import background_image from "../../../images/sidebarBackground.png";
import logo from "../../../images/logo_white.svg";
import logo_small from "../../../images/logo_small.svg";
import { useLocation, useNavigate } from "react-router-dom";
import pages from "../../../utils/pagesData";
import { setCollapsed, setPageId } from "../../../store/sidebar";
import { IconArrow, IconDashboard, IconOrganization } from "../../../icons";
import { IconBug } from "../../../icons/IconBug";
import { IconClipboard } from "../../../icons/IconClipboard";
import { IconFilterUrl } from "../../../icons/IconFilterUrl";
import { IconLoop } from "../../../icons/IconLoop";
import { IconDocs } from "../../../icons/IconDocs";
import { IconFilter } from "../../../icons/IconFilter";
import { getUser } from "../../../store/auth/selectors";
import { IconLicense } from "../../../icons/IconLicense";
import { IconSupport } from "../../../icons/IconSupport";

function SidebarNew() {
  const collapsed = useSelector(getCollapsed);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userPermissions = useSelector(getUser);
  const edlAccess = sessionStorage.getItem("edlTokenPC");
  const { pathname } = useLocation();

  useEffect(() => {
  
    const key = menuItems.find((el) => el?.url === pathname)?.key;
    dispatch(setPageId(key))
  }, [pathname]);

  const getMenuItem = (pathname) => {
    const key = menuItems.find((el) => el?.url === pathname)?.key;
    return key;
  };
  const menuItems = [
    createMenuItem(pages.dashboard, "Dashboard", <IconDashboard />, true),
    (userPermissions?.permissions?.customer_assets?.full_access ||
      userPermissions?.permissions?.customer_assets?.read_access) &&
    createMenuItem(pages.organization, "Организация", <IconOrganization />),
    (userPermissions?.permissions?.customer_urlf?.full_access ||
      userPermissions?.permissions?.customer_urlf?.read_access) &&
    createMenuItem(pages.url_filtering, "URL FILTERING", <IconFilterUrl />),
    edlAccess &&
    (userPermissions?.permissions?.customer_dtl?.full_access ||
      userPermissions?.permissions?.customer_dtl?.read_access) &&
    createMenuItem(
      pages.threat_lists,
      "THREAT LISTS",
      <IconClipboard />,
      !edlAccess ? true : false
    ),
    (userPermissions?.permissions?.customer_sandbox?.full_access ||
      userPermissions?.permissions?.customer_sandbox?.read_access) &&
    createMenuItem(pages.sandbox, "SANDBOX", <IconBug />, false),
    (userPermissions?.permissions?.customer_threatvault?.full_access ||
      userPermissions?.permissions?.customer_threatvault?.read_access) &&
    createMenuItem(pages.threat_vault, "THREAT VAULT", <IconLoop />),
    (userPermissions?.permissions?.customer_docs?.full_access ||
      userPermissions?.permissions?.customer_docs?.read_access) &&
    createMenuItem(pages.documentation, "Документация", <IconDocs />, false),
    (userPermissions?.permissions?.customer_pim?.full_access ||
      userPermissions?.permissions?.customer_pim?.read_access) &&
    createMenuItem(pages.distributions, "PIM", <IconFilter />, false),
    createMenuItem(pages.eula, "EULA", <IconLicense />, false),
  ];

  return (
    <>
      <Layout.Sider
        collapsible
        collapsed={collapsed}
        trigger={null}
        width={256}
        collapsedWidth={88}
      >
        <div
          style={{
            height: "100vh",
            position: "sticky",
            left: 0,
            top: 0,
            bottom: 0,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#000",
            backgroundImage: `linear-gradient(0deg, rgba(43, 43, 67, 0.2) 0, #000 650px), url(${background_image})`,
            backgroundPosition: "0 0, right bottom",
            backgroundSize: "100% 100%, auto 650px",
            backgroundRepeat: "repeat, no-repeat",
          }}
        >
          <img
            src={collapsed ? logo_small : logo}
            alt="logo"
            style={{
              height: 30,
              width: "fit-content",
              marginLeft: collapsed ? 28 : 32,
              marginTop: 20,
              marginBottom: 2,
              transition: "margin 250ms",
            }}
          />

          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorPrimary: "#101921",
                  colorPrimaryHover: "#31445D",
                  colorPrimaryActive: "#101921",
                },
              },
            }}
          >
            <Button
              type="primary"
              onClick={() => dispatch(setCollapsed(!collapsed))}
              style={{
                width: "18px",
                height: "32px",
                alignSelf: "end",
                padding: "0",
                border: "none",
                borderRadius: "2px 0 0 2px",
                marginBottom: 16,
                color: "#CED9EE",
              }}
            >
              <IconArrow direction={collapsed ? "right" : "left"} />
            </Button>
          </ConfigProvider>

          <ConfigProvider
            theme={{
              components: {
                Menu: {
                  itemColor: "#989AA5",
                  itemHoverColor: "#327FEF",
                  colorBgTextHover: "#101921",
                  colorPrimary: "#101921",
                },
              },
            }}
          >
            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={[getMenuItem(pathname)]}
              items={menuItems}
              className={styles.menu}
              onClick={({ key }) => {
                  dispatch(setPageId(key));
                  const url = menuItems.find((el) => el?.key === key)?.url;
                  if (url) {
                    navigate(url);
                  }
              }}
            />
          </ConfigProvider>
          <a style={{paddingLeft:!collapsed?  '24px' : '32px' }} className={styles.link} href="https://sd.styletele.com" target="_blank"><IconSupport  /> {!collapsed && "Поддержка"}</a>
        </div>
        
      </Layout.Sider>
    </>
  );
}

// function getItem(
//     label,
//     key,
//     icon,
//     children,
//     type,
//   ): MenuItem {
//     return {
//       key,
//       icon,
//       children,
//       label,
//       type,
//     } as MenuItem;
//   }
function createMenuItem(page, label, icon, disabled, children, type) {
  return {
    key: page.id,
    url: page.path,
    label,
    icon,
    disabled: false || disabled,
    children,
    type,
  };
}

export default SidebarNew;
