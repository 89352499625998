import React from "react"
import Icon from '@ant-design/icons';

function IconSvg()
{
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" style={{ stroke: "currentcolor" }} xmlns="http://www.w3.org/2000/svg">
      <g id="Icon Frame">
        <path id="Ellipse 25" d="M1.99984 7.99988C1.99984 4.68617 4.68613 1.99988 7.99984 1.99988C9.88468 1.99988 11.5665 2.86899 12.6665 4.22832" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector 71" d="M13 1.99988L13 4.66654L10.3333 4.66654" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Ellipse 26" d="M14.0002 7.99988C14.0002 11.3136 11.3139 13.9999 8.00016 13.9999C6.11532 13.9999 4.43347 13.1308 3.3335 11.7714" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector 72" d="M3 13.9999L3 11.3332L5.66667 11.3332" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  )
}

export function IconReload(props)
{
  return <Icon component={IconSvg} {...props} />
}
