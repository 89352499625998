import React from "react"
import Icon from '@ant-design/icons';

function IconSvg()
{
  return (
    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" style={{ stroke: "currentcolor" }} xmlns="http://www.w3.org/2000/svg">
      <path id="Vector" d="M0.928711 5.36364L4.36621 9L11.9287 1" strokeWidth="1.2" strokeLinecap="round" />
    </svg>
  )
}

export function IconMarkTrue(props)
{
  return <Icon component={IconSvg} {...props} />
}



