import React from "react";
import { Alert, Button, Modal, Typography } from "antd";
import { IconError } from "../../icons";
import {
  deleteEdlList,
  patchEdlList,
  switchAсtivateListSuccess,
  switchDeleteListSuccess,
} from "../../store/edl";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../store/auth/selectors";
import {
  getEdlCheckErrors,
  getEdlDeleteLoaders,
  getEdlSuccessActivateList,
  getEdlSuccessDeleteList,
} from "../../store/edl/selectors";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";

function ModalConfirm({ open, close, action, item }) {
  const userData = useSelector(getUser);
  const dispatch = useDispatch();
  const isSuccessActivate = useSelector(getEdlSuccessActivateList);
  const isSuccessDelete = useSelector(getEdlSuccessDeleteList);
  const errors = useSelector(getEdlCheckErrors);
  const isLoading = useSelector(getEdlDeleteLoaders);

  const handleActivateList = () => {
    let user = "";
    if (userData?.first_name || userData?.last_name)
      user = `${userData?.first_name} ${userData?.last_name}`;
    else user = `${userData?.email}`;
    dispatch(
      patchEdlList({ isActive: !item?.is_active, idList: item.id, user: user })
    );
  };

  const handleClose = () => {
    dispatch(switchAсtivateListSuccess());
    dispatch(switchDeleteListSuccess());
    close();
  };

  return (
    <Modal open={open} closable={false} footer={null} centered>
      {/* <Button
				onClick={close}
				icon={<IconClose style={{ width: 12, height: 12 }} />}
				type="ghost"
				style={{
					position: "absolute",
					right: 16,
					top: 16,
					width: 32,
					height: 32,
					backgroundColor: "#F6F8FD",
					padding: 0,
				}}
			/> */}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          flexDirection: "column",
          gap: 24,
          position: "relative",
        }}
      >
        {isLoading && <PreloaderNew />}
        {errors && (
          <Alert
            Alert
            message={errors}
            type="error"
            key={+"error"}
            showIcon
            icon={<IconError />}
            closable
          ></Alert>
        )}
        {!isSuccessActivate && !isSuccessDelete && (
          <Typography.Title level={3}>
            {action === "delete"
              ? "Вы уверены, что хотите удалить запись?"
              : `Вы уверены, что хотите сделать список ${
                  item?.is_active ? "неактивным" : "активным"
                }?`}
          </Typography.Title>
        )}
        {isSuccessActivate && (
          <Typography.Title level={3}>
            {isSuccessActivate.message}
          </Typography.Title>
        )}
        {isSuccessDelete && (
          <Typography.Title level={3}>Список успешно удален</Typography.Title>
        )}
        {!isSuccessActivate && !isSuccessDelete && (
          <div
            style={{
              display: "flex",
              gap: 24,
            }}
          >
            <Button onClick={close} style={{ flexGrow: 1 }} htmlType="reset">
              Отмена
            </Button>
            <Button
              // onClick={() => {
              // 	close();
              // 	setModalSuccessOpen(true);
              // }}
              style={{ flexGrow: 1, background: "#2E496F" }}
              type="primary"
              onClick={() =>
                action === "delete"
                  ? dispatch(
                      deleteEdlList({ idList: item.id, userUpdated: userData })
                    )
                  : handleActivateList()
              }
            >
              {action === "delete" ? "Удалить" : "Сделать"}
            </Button>
          </div>
        )}
        {(isSuccessDelete || isSuccessActivate) && (
          <Button
            // onClick={() => {
            // 	close();
            // 	setModalSuccessOpen(true);
            // }}
            style={{ flexGrow: 1, background: "#2E496F" }}
            type="primary"
            onClick={() => {
              handleClose();
            }}
          >
            {"Закрыть"}
          </Button>
        )}
      </div>
    </Modal>
  );
}

export default ModalConfirm;
