export const getError = ({ auth }) => auth.errors;
export const getLoginError = ({ auth }) => auth.loginErrors;
export const getAuth = ({ auth }) => auth.isAuthenticated;
export const getOrganisation = ({ auth }) => auth.organisation;
export const getAssets = ({ auth }) => auth.assets;
export const getDetailAsset = ({ auth }) => auth.assetDetail;
export const getAuthLoader = ({ auth }) => auth.loaders.common;
export const getOrganisationLoader = ({ auth }) => auth.loaders.organisation;
export const getUser = ({ auth }) => auth.user;
export const getIsSuccessChangePassword = ({ auth }) => auth.successChangePassword;
export const getChangePassLoader = ({ auth }) => auth.loaders.changePassword;
