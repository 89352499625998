import React from "react"
import Icon from '@ant-design/icons';

function IconSvg()
{
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" style={{ stroke: "currentcolor" }} xmlns="http://www.w3.org/2000/svg">
      <g id="Icon Frame">
        <rect id="Rectangle 89" x="10" y="14.1667" width="0.00892857" height="0.00892857" strokeLinejoin="round" stroke= "currentcolor"/>
        <path id="Vector 26" d="M10 10.8333L10 7.49992" strokeLinecap="round" strokeLinejoin="round" stroke= "currentcolor"/>
        <path stroke= "currentcolor" id="Polygon 17" d="M9.60995 1.557C9.78291 1.25867 10.2171 1.25867 10.39 1.557L19.4388 17.1651C19.6093 17.4593 19.3982 17.8333 19.0487 17.8333H0.951285C0.601779 17.8333 0.390682 17.4593 0.561239 17.1651L9.60995 1.557Z" />
      </g>
    </svg>
  )
}

export function IconWarning(props)
{
  return <Icon component={IconSvg} {...props} />
}


