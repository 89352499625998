import React from "react";
import { Drawer } from "antd";
import AddEdlListNew from "./AddEdlListNew";

function ModalAddList({ open, close }) {
  return (
    <Drawer
      open={open}
      onClose={close}
      maskClosable={false}
      width={704}
      styles={{ header: { display: "none" } }}
    >
      {/* <Space style={{
				display: "flex",
				justifyContent: "end",
				paddingInline: 16,
				height: 56,
			}}>
				<Button
					onClick={() => { dispatch(switchAddListSuccess()); dispatch(setProgress(0)); close() }}
					icon={<IconClose style={{ width: 12, height: 12 }} />}
					type="ghost"
					style={{
						width: 32,
						height: 32,
						backgroundColor: "#F6F8FD",
						padding: 0,
					}}
				/>
			</Space> */}

      <AddEdlListNew close={close} />
    </Drawer>
  );
}

export default ModalAddList;
