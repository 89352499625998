import React from "react";
import styles from "./PasswordRecoverNew.module.css"
import background_image from "../../../images/loginBackground.jpg";
import { useSelector, useDispatch } from "react-redux";
import { resetPassword } from "../../../store/auth";
import { getChangePassLoader, getError, getIsSuccessChangePassword } from "../../../store/auth/selectors";
import { Form, Input, Button, Spin, Alert, Typography } from "antd";
import { getUrlLoaders } from "../../../store/urlfilter/selectors";
import PreloaderNew from "../PreloaderNew/PreloaderNew";

const { Title } = Typography;

function PasswordRecoverNew() {
    const dispatch = useDispatch();
    const errors = useSelector(getError);
    const isSuccess = useSelector(getIsSuccessChangePassword);
    const isLoading = useSelector(getChangePassLoader);
    const isLoader = useSelector(getUrlLoaders);

    return (
        <Spin size="large" spinning={isLoader}>
            <div style={{
                display: "grid",
                gridTemplateColumns: "auto 490px",
                height: "100vh",
            }}>

                <img
                    src={background_image}
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                    }}
                    alt='background'
                />
                <div
                    style={{
                        padding: "80px 64px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <Title level={2} style={{ marginBottom: 24 }}>Восстановление пароля</Title>
                    {/* {errors && <Alert type="error" message={JSON.stringify(errors, null, 4)} style={{ marginBottom: 16, maxWidth: 350 }} />} */}
                    {errors && <Alert
                        message="Authorization Error"
                        description={errors}
                        type="error"
                        showIcon
                    />}
                    {isSuccess && <Alert
                        message={isSuccess}

                        type="success"
                        showIcon
                    />}
                    <Form
                        size="large"
                        name="auth"
                        layout="vertical"
                        requiredMark={false}
                        className={styles.form}
                        style={{ width: "100%", position: "relative" }}
                        onFinish={({ email }) => {

                            dispatch(resetPassword({ email: email }));
                        }}
                    >
                        {isLoading && <PreloaderNew />}
                        <Form.Item
                            label="E-mail"
                            name="email"
                            rules={[{ required: true, message: "Пожалуйста, введите Ваш email!" }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item>
                            <div style={{
                                display: "flex"
                            }}>

                                <div style={{ flexGrow: 1 }} />
                                <Button type="link" size="small" style={{ padding: 0, fontSize: 14 }} href='/login'>
                                    Вход на портал
                                </Button>
                            </div>
                        </Form.Item>

                        <Form.Item style={{ marginBottom: 0 }}>
                            <Button
                                disabled={isSuccess || isLoading ? true : false}
                                type="primary"
                                htmlType="submit"
                                style={{
                                    width: "100%",
                                    borderRadius: 4,
                                    fontSize: 14,
                                    background: '#2E496F'
                                }}
                            >
                                Отправить
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Spin>

    )
}

export default PasswordRecoverNew;