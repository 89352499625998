export const getError = ({ organisation }) => organisation.errors;

export const getAssets = ({ organisation }) => organisation.assets;
export const getLastUpdate = ({ organisation }) => organisation.lastUpdate;
export const getAssetsCount = ({ organisation }) => organisation.assetsCount;
export const getAssetsNextPage = ({ organisation }) => organisation.assetsNextPage;
export const getDetailAsset = ({ organisation }) => organisation.assetDetail;
export const getAssetsLoader = ({ organisation }) => organisation.loaders.common;
export const getOrganisationLoader = ({ organisation }) => organisation.loaders.organisation;

//Для фильтров
export const getCitiesData = ({ organisation }) => organisation.cities;
export const getPlatformsData = ({ organisation }) => organisation.platforms;
export const getOrganisationsData = ({ organisation }) => organisation.organisations;