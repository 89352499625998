import React from "react";
import { Col, Row, Card, Typography, Button } from "antd";
import { useNavigate } from "react-router-dom";

export default function WithoutAuthorization() {
    const { Title } = Typography;
const navigate = useNavigate()
    return (
        <>
            <Row>
                <Col span={24}>
                    <Card
                        style={{
                            minHeight: "400px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Title level={3} style={{ margin: 0 }}>
                            У Вас нет прав для просмотра данного раздела!
                        </Title>
                        <div style={{display: "flex", width: '100%', justifyContent: "center"}}>
                        <Button  style={{margin: '20px auto'}} type='primary' onClick={()=> navigate('dashboards')}>На главную</Button></div>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
