import "../../styles/App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import LoginPage from "../LoginPage";
import { useDispatch, useSelector } from "react-redux";
import { getAuth, getUser } from "../../store/auth/selectors";
import ProtectedRoute from "../../utils/ProtectedRoute";
import { useEffect, useState } from "react";
import { getOrgInfo, getRefreshToken, getUserInfo } from "../../store/auth";
import WindowFocusHandler from "../../components/UI/WindowActivity";
import PasswordRecoverPage from "../PasswordRecoverPage";
import Layout from "../../components/UI/Layout";
import OrgAreaNew from "../../components/OrganisationNew/OrgAreaNew";
import PasswordConfirmNew from "../../components/UI/PasswordConfirm/PasswordConfirmNew";
import UrlFilterAreaNew from "../../components/UrlFilter/UrlFilterAreaNew";
import ThreatVaultAreaNew from "../../components/ThreatVault/ThreatVaultAreaNew";
import EdlAreaNew from "../../components/EdlNew/EdlAreaNew";
import DocumentationArea from "../../components/Documenation/DocumentationArea";
import { DistributionsArea } from "../../components/Distributions/DistributionsArea";
import SandboxArea from "../../components/Sandbox/SandboxArea";
import Eula from "../../components/Eula/Eula";
import { resetDataAllNextPages } from "../../store/distributions";

function App() {
  const jwt = sessionStorage.getItem("accessTokenPC");
  const auth = useSelector(getAuth);
  const dispatch = useDispatch();
  const location = useLocation();
  const userPermissions = useSelector(getUser);
  const [workingTimer, setWorkingTimer] = useState(0);

  useEffect(() => {
    if (auth === true) setWorkingTimer(true);
    if (!workingTimer) return;
    const timerRefresh = setInterval(() => {
      dispatch(getRefreshToken());
    }, [180000]);
    // stop
    return () => {
      setWorkingTimer(false);
      clearInterval(timerRefresh);
    };
  }, [workingTimer, auth]);

  useEffect(() => {
    if (jwt) {
      dispatch(getOrgInfo());
    }
  }, [jwt, dispatch]);

  useEffect(() => {
    if (jwt && auth) {
      dispatch(getUserInfo());
    }
  }, [auth, dispatch]);
  useEffect(() => {
    if (location.pathname !== "distributions") {
      dispatch(resetDataAllNextPages());
    }
  }, [location]);

  return (
    <div className="App">
      <WindowFocusHandler />
      <Routes location={location} key={location.pathname}>
        {!auth && !jwt && <Route path="/*" element={<LoginPage />} />}
        {!auth && (
          <Route path="/passrecover" element={<PasswordRecoverPage />} />
        )}
        {!auth && (
          <Route
            path="/passrecoverconfirm/:uid/:token/"
            element={<PasswordConfirmNew />}
          />
        )}
        {jwt && (
          <Route
            path="*"
            element={
              <Layout auth={auth}>
                <ProtectedRoute>
                  <OrgAreaNew
                    auth={auth}
                    permissions={userPermissions?.permissions?.customer_assets}
                  />
                </ProtectedRoute>{" "}
              </Layout>
            }
          />
        )}
        {jwt && (
          <Route
            path="/url_filter"
            element={
              <Layout auth={auth}>
                <ProtectedRoute>
                  <UrlFilterAreaNew
                    permissions={userPermissions?.permissions?.customer_urlf}
                  />{" "}
                </ProtectedRoute>
              </Layout>
            }
          />
        )}
        {jwt && (
          <Route
            path="/threat_vault"
            element={
              <Layout auth={auth}>
                <ProtectedRoute>
                  <ThreatVaultAreaNew
                    permissions={
                      userPermissions?.permissions?.customer_threatvault
                    }
                  />{" "}
                </ProtectedRoute>
              </Layout>
            }
          />
        )}
        {jwt && (
          <Route
            path="/edl"
            element={
              <Layout auth={auth}>
                <ProtectedRoute>
                  <EdlAreaNew
                    permissions={userPermissions?.permissions?.customer_dtl}
                  />
                </ProtectedRoute>
              </Layout>
            }
          />
        )}
        {jwt && (
          <Route
            path="/documentation"
            element={
              <Layout auth={auth}>
                <ProtectedRoute>
                  <DocumentationArea
                    permissions={userPermissions?.permissions?.customer_docs}
                  />{" "}
                </ProtectedRoute>
              </Layout>
            }
          />
        )}
        {jwt && (
          <Route
            path="/sandbox"
            element={
              <Layout auth={auth}>
                <ProtectedRoute>
                  <SandboxArea
                    permissions={userPermissions?.permissions?.customer_sandbox}
                  />{" "}
                </ProtectedRoute>
              </Layout>
            }
          />
        )}
        {jwt && (
          <Route
            path="/distributions"
            element={
              <Layout auth={auth}>
                <ProtectedRoute>
                  <DistributionsArea
                    permissions={userPermissions?.permissions?.customer_pim}
                  />{" "}
                </ProtectedRoute>
              </Layout>
            }
          />
        )}
        {jwt && (
          <Route
            path="/eula"
            element={
              <Layout auth={auth}>
                <ProtectedRoute>
                  <Eula/>
                </ProtectedRoute>
              </Layout>
            }
          />
        )}
      </Routes>
    </div>
  );
}

export default App;
