import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../utils/axios';
import { logout } from '../auth';
export const getDataArrayThreat = createAsyncThunk(
  'getDataArrayThreat',
  async ({ name, type }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.get(`api/v1/check-cve/?${type}=${name}`);
      return data;
    }
    catch (err) {
      if (err.response.status === 401) dispatch(logout())
      else
        return rejectWithValue(err.response.data)
    }
  }

);
export const getDataArrayThreatSha = createAsyncThunk(
  'getDataArrayThreatSha',
  async ({ name, type }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.get(`api/v1/check-cve/sha_md/?${type}=${name}`);
      return data;
    }
    catch (err) {
      if (err.response.status === 401) dispatch(logout())
      else
        return rejectWithValue(err.response.data)
    }
  }

);
export const getDataIdThreat = createAsyncThunk(
  'getDataThreat',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.get(`api/v1/check-cve/?id=${id}`);
      return data;
    }
    catch (err) {
      if (err.response.status === 401) dispatch(logout())
      else
        return rejectWithValue(err.response.data)
    }
  }
);

const initialState = {
  threatArrData: null,
  threatIdData: null,
  threatArrDataSha: null,
  perMinute: null,
  perDay: null,
  errors: null,
  loaders: {
    common: false,
  },
};

const threatVaultSlice = createSlice({
  name: 'threatvault',
  initialState,
  reducers: {

  },

  extraReducers: (builder) => {

    //getDataArrayThreat
    builder.addCase(getDataArrayThreat.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getDataArrayThreat.fulfilled, (state, { payload }) => {
      state.threatArrData = payload;
      state.threatArrDataSha = null;
      if (payload.req_per_day_remaining !== null) state.perDay = payload.req_per_day_remaining
      if (payload.req_per_minute_remaining !== null) state.perMinute = payload.req_per_minute_remaining
      state.loaders.common = false;
      state.errors = null;
    });
    builder.addCase(getDataArrayThreat.rejected, (state, action) => {
      state.threatArrData = null;
      state.loaders.common = false;
      state.threatArrDataSha = null;
      state.errors = action?.payload?.detail || action?.payload?.error || action?.payload?.message || action?.payload?.non_field_errors ||'Неизвестная ошибка'

    });

    //getDataArrayThreatSha
    builder.addCase(getDataArrayThreatSha.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getDataArrayThreatSha.fulfilled, (state, { payload }) => {
      state.threatArrDataSha = payload;
      state.threatArrData = null;
      if (payload.req_per_day_remaining !== null) state.perDay = payload.req_per_day_remaining
      if (payload.req_per_minute_remaining !== null) state.perMinute = payload.req_per_minute_remaining
      state.loaders.common = false;
      state.errors = null;
    });
    builder.addCase(getDataArrayThreatSha.rejected, (state, action) => {
      state.threatArrDataSha = null;
      state.threatArrData = null;
      state.loaders.common = false;
      if (!action.payload) { state.errors = ['Ошибка сервера'] }
      else {
        state.errors = action?.payload?.detail || action?.payload?.error || action?.payload?.message || action?.payload?.non_field_errors ||'Неизвестная ошибка'
      }

    });

    //getDataIdThreat
    builder.addCase(getDataIdThreat.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getDataIdThreat.fulfilled, (state, { payload }) => {
      state.threatIdData = payload;
      state.loaders.common = false;
      if (payload.req_per_day_remaining !== null) state.perDay = payload.req_per_day_remaining
      if (payload.req_per_minute_remaining !== null) state.perMinute = payload.req_per_minute_remaining
      state.errors = null;
    });
    builder.addCase(getDataIdThreat.rejected, (state, action) => {
      state.threatIdData = null;

      state.loaders.common = false;
      state.errors = action?.payload?.detail || action?.payload?.error || action?.payload?.message || action?.payload?.non_field_errors ||'Неизвестная ошибка'

    });
  }

});
export const { resetUrlData, resetUpdUrlData } = threatVaultSlice.actions;
export default threatVaultSlice.reducer;