import React from 'react'
import Icon from '@ant-design/icons';

function IconSvg() {
    return (
        <svg style={{ stroke: "currentcolor" }} fill="currentcolor" width="24px" height="24px" viewBox="0 0 36 36" version="1.1" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg">
            <path d="M32,6H4A2,2,0,0,0,2,8V28a2,2,0,0,0,2,2H19l.57-.7.93-1.14L20.41,28H4V8H32l0,8.56a8.41,8.41,0,0,1,2,1.81V8A2,2,0,0,0,32,6Z" className="clr-i-outline clr-i-outline-path-1"></path><rect x="7" y="12" width="17" height="1.6" className="clr-i-outline clr-i-outline-path-2"></rect><rect x="7" y="16" width="11" height="1.6" className="clr-i-outline clr-i-outline-path-3"></rect><rect x="7" y="23" width="10" height="1.6" className="clr-i-outline clr-i-outline-path-4"></rect><path d="M27.46,17.23a6.36,6.36,0,0,0-4.4,11l-1.94,2.37.9,3.61,3.66-4.46a6.26,6.26,0,0,0,3.55,0l3.66,4.46.9-3.61-1.94-2.37a6.36,6.36,0,0,0-4.4-11Zm0,10.68a4.31,4.31,0,1,1,4.37-4.31A4.35,4.35,0,0,1,27.46,27.91Z" className="clr-i-outline clr-i-outline-path-5"></path>
        </svg>
    )
}

export function IconLicense(props) {
    return <Icon component={IconSvg} {...props} />
}