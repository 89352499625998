import React from "react"
import Icon from '@ant-design/icons';

function IconSvg()
{
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" style={{ fill: "currentcolor" }} xmlns="http://www.w3.org/2000/svg">
      <g id="Search" clipPath="url(#clip0_5623_5595)">
        <path id="Vector" d="M15.1003 14.1183L10.4628 9.4808C11.1824 8.55045 11.5717 7.41295 11.5717 6.21652C11.5717 4.78438 11.0128 3.44152 10.0021 2.42902C8.99135 1.41652 7.64492 0.859375 6.21456 0.859375C4.78421 0.859375 3.43778 1.4183 2.42706 2.42902C1.41456 3.43973 0.857422 4.78438 0.857422 6.21652C0.857422 7.64688 1.41635 8.9933 2.42706 10.004C3.43778 11.0165 4.78242 11.5737 6.21456 11.5737C7.41099 11.5737 8.54671 11.1844 9.47707 10.4665L14.1146 15.1022C14.1282 15.1158 14.1443 15.1266 14.1621 15.134C14.1799 15.1414 14.1989 15.1452 14.2181 15.1452C14.2374 15.1452 14.2564 15.1414 14.2742 15.134C14.292 15.1266 14.3081 15.1158 14.3217 15.1022L15.1003 14.3254C15.1139 14.3118 15.1247 14.2957 15.132 14.2779C15.1394 14.2602 15.1432 14.2411 15.1432 14.2219C15.1432 14.2026 15.1394 14.1836 15.132 14.1658C15.1247 14.148 15.1139 14.1319 15.1003 14.1183ZM9.04314 9.04509C8.28599 9.80045 7.28242 10.2165 6.21456 10.2165C5.14671 10.2165 4.14314 9.80045 3.38599 9.04509C2.63064 8.28795 2.21456 7.28438 2.21456 6.21652C2.21456 5.14866 2.63064 4.1433 3.38599 3.38795C4.14314 2.63259 5.14671 2.21652 6.21456 2.21652C7.28242 2.21652 8.28778 2.6308 9.04314 3.38795C9.79849 4.14509 10.2146 5.14866 10.2146 6.21652C10.2146 7.28438 9.79849 8.28973 9.04314 9.04509Z" />
      </g>
      <defs>
        <clipPath id="clip0_5623_5595">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function IconSearch(props)
{
  return <Icon component={IconSvg} {...props} />
}
