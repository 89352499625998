import React from "react"
import Icon from '@ant-design/icons';

function IconSvg() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.94285 6.33332C7.4701 6.33332 6.27617 7.52722 6.27617 9C6.27617 10.4727 7.4701 11.6666 8.94285 11.6666C10.4156 11.6666 11.6095 10.4727 11.6095 9C11.6095 7.52722 10.4156 6.33332 8.94285 6.33332ZM16.4277 7.09705L15.7057 7.69142C14.8821 8.36942 14.8821 9.63054 15.7057 10.3085L16.4277 10.9029C16.6835 11.1136 16.7496 11.4783 16.5839 11.7653L15.1582 14.2346C14.9925 14.5217 14.6436 14.6468 14.3333 14.5305L13.4575 14.2025C12.4585 13.8282 11.3664 14.4588 11.191 15.511L11.0373 16.4335C10.9828 16.7604 10.6999 17 10.3685 17H7.51716C7.18577 17 6.90292 16.7604 6.84844 16.4335L6.69468 15.511C6.51933 14.4588 5.42716 13.8282 4.42821 14.2025L3.55248 14.5305C3.2421 14.6468 2.89319 14.5217 2.72746 14.2346L1.3018 11.7653C1.1361 11.4783 1.20217 11.1136 1.45804 10.9029L2.18007 10.3085C3.00363 9.63054 3.00363 8.36942 2.18007 7.69142L1.45804 7.09705C1.20217 6.88641 1.1361 6.52166 1.3018 6.23464L2.72746 3.76532C2.89319 3.47831 3.2421 3.35315 3.55248 3.46942L4.42821 3.79749C5.42716 4.17173 6.51933 3.54119 6.69468 2.48895L6.84844 1.56647C6.90292 1.23959 7.18577 1 7.51716 1H10.3685C10.6999 1 10.9828 1.23959 11.0373 1.56647L11.191 2.48895C11.3664 3.54119 12.4585 4.17173 13.4575 3.79749L14.3333 3.46942C14.6436 3.35315 14.9925 3.47831 15.1582 3.76532L16.5839 6.23464C16.7496 6.52166 16.6835 6.88641 16.4277 7.09705Z" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export function IconSandboxSettings(props) {
    return <Icon component={IconSvg} {...props} />
}