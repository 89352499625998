import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";

export const getLogin = createAsyncThunk(
  "getLogin",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        "api/v1/auth/token/login/",
        params
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        "api/v1/auth/users/reset_password/",
        params
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const resetPasswordConfirm = createAsyncThunk(
  "resetPasswordConfirm",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        "api/v1/auth/users/reset_password_confirm/",
        params
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const setPassword = createAsyncThunk(
  "setPassword",
  async ({ new_password, current_password }, { rejectWithValue }) => {
    try {
      const params = {};
      params.new_password = new_password;
      params.current_password = current_password;
      const { data } = await axiosInstance.patch(
        "api/v1/auth/users/set_password/",
        params
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getRefreshToken = createAsyncThunk("getRefreshToken", async () => {
  const params = {};
  const refToken = sessionStorage.getItem("refreshTokenPC");
  params.refresh = refToken;
  const { data } = await axiosInstance.post(
    "api/v1/auth/token/refresh/",
    params
  );
  return data;
});

export const getVerifyToken = createAsyncThunk(
  "getVerifyToken ",
  async ({ reloadAndReset }, { rejectWithValue }) => {
    try {
      const token = sessionStorage.getItem("accessTokenPC");
      const params = {};
      params.token = token;
      const { data } = await axiosInstance.post(
        "api/v1/auth/token/verify/",
        params
      );
      return data;
    } catch (err) {
      reloadAndReset();
      return rejectWithValue(err.response.data);
    }
  }
);
export const getOrgInfo = createAsyncThunk(
  "getOrgInfo",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("api/v1/organisation/me/");
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());

      return rejectWithValue(err.response.data);
    }
  }
);
export const getUserInfo = createAsyncThunk(
  "getUserInfo",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("api/v1/auth/users/me/");
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());

      return rejectWithValue(err.response.data);
    }
  }
);
const initialState = {
  user: null,
  isAuthenticated: false,
  organisation: null,
  errors: null,
  loginErrors: null,
  successChangePassword: null,
  email: null,
  loaders: {
    common: false,
    changePassword: false,
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.errors = null;
      state.organisation = null;
      state.user = null;
      sessionStorage.removeItem("accessTokenPC");
      sessionStorage.removeItem("refreshTokenPC");
      sessionStorage.removeItem("edlTokenPC");
    },
    handleError: (state, { payload }) => {
      state.errors = payload;
    },
  },
  extraReducers: (builder) => {
    //getLogin
    builder.addCase(getLogin.pending, (state) => {
      state.loaders.common = true;
      state.loginErrors = null;
    });
    builder.addCase(getLogin.fulfilled, (state, { payload }) => {
      const { access, refresh, edl_access_token } = payload;
      sessionStorage.setItem("accessTokenPC", access);
      sessionStorage.setItem("refreshTokenPC", refresh);
      if (edl_access_token)
        sessionStorage.setItem("edlTokenPC", edl_access_token);
      state.isAuthenticated = true;
      state.loaders.common = false;
      state.loginErrors = null;
    });
    builder.addCase(getLogin.rejected, (state, action) => {
      state.loaders.common = false;
      state.loginErrors =
        action.payload?.message ||
        action.payload?.detail ||
        action.payload?.error ||
        "Ошибка сервера";
      state.isAuthenticated = false;
    });

    //resetPassword
    builder.addCase(resetPassword.pending, (state) => {
      state.loaders.changePassword = true;
      state.successChangePassword = null;
      state.errors = null;
    });
    builder.addCase(resetPassword.fulfilled, (state, { payload }) => {
      state.errors = null;
      state.successChangePassword = payload?.message;
      state.loaders.changePassword = false;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.loaders.changePassword = false;
      state.errors =
        action.payload?.message ||
        action.payload?.detail ||
        action.payload?.error ||
        "Ошибка сервера";
    });
    //resetPasswordConfirm
    builder.addCase(resetPasswordConfirm.pending, (state) => {
      state.loaders.changePassword = true;
      state.successChangePassword = null;
      state.errors = null;
    });
    builder.addCase(resetPasswordConfirm.fulfilled, (state, { payload }) => {
      state.errors = null;
      state.successChangePassword = payload?.message;
      state.loaders.changePassword = false;
    });
    builder.addCase(resetPasswordConfirm.rejected, (state, action) => {
      let errors = [];
      for (var key in action.payload) {
        errors.push(action.payload[key]);
      }
      state.loaders.changePassword = false;
      state.errors = errors;
    });
    //setPassword
    builder.addCase(setPassword.pending, (state) => {
      state.loaders.changePassword = true;
      state.successChangePassword = null;
      state.errors = null;
    });
    builder.addCase(setPassword.fulfilled, (state) => {
      state.errors = null;
      state.successChangePassword = true;
      state.loaders.changePassword = false;
    });
    builder.addCase(setPassword.rejected, (state, action) => {
      let errors = [];
      for (var key in action.payload) {
        errors.push(action.payload[key]);
      }
      state.loaders.changePassword = false;
      state.errors = errors;
    });

    //getRefreshToken
    builder.addCase(getRefreshToken.pending, () => {});
    builder.addCase(getRefreshToken.fulfilled, (state, { payload }) => {
      const { access, edl_access_token } = payload;
      sessionStorage.setItem("accessTokenPC", access);
      if (edl_access_token)
        sessionStorage.setItem("edlTokenPC", edl_access_token);
      state.isAuthenticated = true;
    });
    builder.addCase(getRefreshToken.rejected, (state) => {
      state.isAuthenticated = false;
      sessionStorage.removeItem("refreshTokenPC");
      sessionStorage.removeItem("accessTokenPC");
    });

    //getVerifyToken
    builder.addCase(getVerifyToken.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getVerifyToken.fulfilled, (state) => {
      state.isAuthenticated = true;
      state.loaders.common = false;
    });
    builder.addCase(getVerifyToken.rejected, (state) => {
      state.isAuthenticated = false;
      sessionStorage.removeItem("refreshTokenPC");
      sessionStorage.removeItem("accessTokenPC");
    });

    //getOrgInfo
    builder.addCase(getOrgInfo.pending, (state) => {
      state.loaders.organisation = true;
    });
    builder.addCase(getOrgInfo.fulfilled, (state, { payload }) => {
      state.isAuthenticated = true;
      state.organisation = payload;
      state.loaders.organisation = false;
    });
    builder.addCase(getOrgInfo.rejected, (state, action) => {
      state.isAuthenticated = false;
      state.loaders.organisation = false;
      state.errors =
        action.payload?.message ||
        action.payload?.detail ||
        action.payload.error ||
        "Ошибка сервера";
    });
    //getUserInfo
    builder.addCase(getUserInfo.pending, (state) => {
      state.loaders.organisation = true;
    });
    builder.addCase(getUserInfo.fulfilled, (state, { payload }) => {
      state.isAuthenticated = true;
      state.user = payload;
      state.loaders.organisation = false;
    });
    builder.addCase(getUserInfo.rejected, (state, action) => {
      state.isAuthenticated = false;
      state.loaders.organisation = false;
      state.errors =
        action.payload?.message ||
        action.payload?.detail ||
        action.payload?.error ||
        "Ошибка сервера";
    });
  },
});
export const { logout, handleError } = authSlice.actions;
export default authSlice.reducer;
