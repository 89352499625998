import React from 'react'
import { useEffect, useState } from "react";
import { Button, Card, Select, Space, Table, Typography } from "antd";
import { IconReload } from "../../icons";
import StatusTag from "./StatusTag";
import { useDispatch, useSelector } from "react-redux";
import { getTaskList } from "../../store/edl";
import {
  getCountTaskList,
  getDateTaskList,
  getListLoaders,
} from "../../store/edl/selectors";

import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import ModalTaskList from "./ModalTaskList";

const { Title } = Typography;
function EdlTaskListNew() {
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(0);
  const dataTaskList = useSelector(getDateTaskList);
  const isLoadersList = useSelector(getListLoaders);
  const [statusFilter, setStatusFilter] = useState(null);
  const count = useSelector(getCountTaskList);
  const [modalInstanceListItem, setModalInstanceListItem] = useState(-1);

  const fetchData = () => {
    dispatch(getTaskList({ page: activePage, filter: statusFilter }));
  };

  useEffect(() => {
    fetchData();
    // const interval = setInterval(fetchData, 10000);
    // return () => clearInterval(interval);
  }, [activePage, statusFilter]);

  return (
    <Card>
      {modalInstanceListItem !== -1 && (
        <ModalTaskList
          itemId={modalInstanceListItem}
          close={() => setModalInstanceListItem(-1)}
        />
      )}
      <Space
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 20,
          position: "relative",
        }}
      >
        {isLoadersList && <PreloaderNew />}
        <Title level={4}>Очередь фоновых задач</Title>
        <Space size={12}>
          <Select
            defaultValue="any"
            options={[
              { value: "any", label: "Выбрать статус" },
              { value: "STARTED", label: "STARTED" },
              { value: "PENDING", label: "PENDING" },
              { value: "FAILURE", label: "FAILURE" },
              { value: "RETRY", label: "RETRY" },
              { value: "SUCCESS", label: "SUCCESS" },
            ]}
            onChange={(e) => {
              e === "any" ? setStatusFilter(null) : setStatusFilter(e);
            }}
            style={{ width: 180 }}
          />
          <Button onClick={() => fetchData()} icon={<IconReload />}>
            Обновить
          </Button>
        </Space>
      </Space>
      <Table
        dataSource={dataTaskList}
        columns={columnsBackgroundTasks}
        // showHeader={false}
        rowKey={(obj) => obj.id}
        size="small"
        pagination={{
          position: "bottomRight",
          defaultPageSize: 10,
          showSizeChanger: false,
          total: count,
          onChange: (page) => setActivePage(page),
        }}
        onRow={(record) => ({
          onClick: () => {
            setModalInstanceListItem(record.id);
          },
        })}
      />
    </Card>
  );
}

const columnsBackgroundTasks = [
  createColumn("Задача", "name", "70%", (text) => (
    <Space style={{ cursor: "pointer" }}>{text}</Space>
  )),
  createColumn("Дата завершения", "date_modified", "20%", (text, row) => (
    <Space style={{ cursor: "pointer" }}>
      <span>
        {text}
        <br />
        {row["created_by"]}
      </span>
    </Space>
  )),
  createColumn("Статус", "status", "10%", (status) => (
    <StatusTag status={status} />
  )),
];

function createColumn(title, key, width, render) {
  return { title, key, width, dataIndex: key, render };
}

export default EdlTaskListNew;
