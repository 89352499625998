import React from "react"
import Icon from '@ant-design/icons';

function IconSvg()
{
  return (
<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Group">
<path id="Vector" d="M1.5 21C1.5 18.4392 2.00438 15.9035 2.98435 13.5377C3.96432 11.1718 5.40067 9.02216 7.21142 7.21142C9.02216 5.40068 11.1718 3.96432 13.5377 2.98435C15.9035 2.00438 18.4392 1.5 21 1.5C23.5608 1.5 26.0965 2.00438 28.4623 2.98435C30.8282 3.96432 32.9778 5.40068 34.7886 7.21142C36.5993 9.02216 38.0357 11.1718 39.0157 13.5377C39.9956 15.9035 40.5 18.4392 40.5 21C40.5 26.1717 38.4455 31.1316 34.7886 34.7886C31.1316 38.4455 26.1717 40.5 21 40.5C15.8283 40.5 10.8684 38.4455 7.21142 34.7886C3.55446 31.1316 1.5 26.1717 1.5 21Z" fill="#F6F8FD" stroke="#327FEF"/>
<path id="Vector_2" d="M1 21C1 18.3736 1.51732 15.7728 2.52241 13.3463C3.5275 10.9198 5.00069 8.71504 6.85786 6.85786C8.71504 5.00069 10.9198 3.5275 13.3463 2.52241C15.7728 1.51732 18.3736 1 21 1C23.6264 1 26.2272 1.51732 28.6537 2.52241C31.0802 3.5275 33.285 5.00069 35.1421 6.85786C36.9993 8.71504 38.4725 10.9198 39.4776 13.3463C40.4827 15.7728 41 18.3736 41 21C41 26.3043 38.8929 31.3914 35.1421 35.1421C31.3914 38.8929 26.3043 41 21 41C15.6957 41 10.6086 38.8929 6.85786 35.1421C3.10714 31.3914 1 26.3043 1 21Z" fill="#F6F8FD" stroke="#327FEF" stroke-width="2" stroke-linecap="round"/>
<path id="Vector_3" d="M12.1113 20.9999L18.778 27.6665L29.8891 16.5554" stroke="#327FEF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>
  )
}

export function IconSuccess(props)
{
  return <Icon component={IconSvg} {...props} />
}

