import React from "react"
import Icon from '@ant-design/icons';

function IconSvg()
{
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" style={{ stroke: "currentcolor" }} xmlns="http://www.w3.org/2000/svg">
      <g id="interface-edit-write-1--edit-edition-form-pen-text-write" clipPath="url(#clip0_5868_6877)">
        <g id="Group">
          <path id="Vector" d="M0.799805 15.2H11.9998" strokeLinecap="round" strokeLinejoin="round" />
          <path id="Vector_2" d="M7.918 11.398L4.7998 12L5.3195 8.61077L12.3146 1.12983C12.4113 1.02534 12.5262 0.942394 12.6529 0.885792C12.7796 0.82919 12.9154 0.800049 13.0526 0.800049C13.1898 0.800049 13.3257 0.82919 13.4523 0.885792C13.579 0.942394 13.694 1.02534 13.7906 1.12983L14.8924 2.31162C14.9898 2.41526 15.0671 2.53857 15.1199 2.67443C15.1726 2.81029 15.1998 2.95602 15.1998 3.10319C15.1998 3.25037 15.1726 3.3961 15.1199 3.53196C15.0671 3.66782 14.9898 3.79113 14.8924 3.89477L7.918 11.398Z" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_5868_6877">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function IconEdit(props)
{
  return <Icon component={IconSvg} {...props} />
}


