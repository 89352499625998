import React from "react";

function StatusTag({ status })
{

	return <div style={{
		backgroundColor: {
			"STARTED": "",
			"PENDING": "#FFF9EC",
			"FAILURE": "#FFECEC",
			"RETRY": "#F6F8FD",
			"SUCCESS": "#E0F6EA",
		}[status],
		color: {
			"STARTED": "",
			"PENDING": "#E39800",
			"FAILURE": "#EB5757",
			"RETRY": "#327FEF",
			"SUCCESS": "#219653",
		}[status],
		display: "inline",
		borderRadius: 2,
		padding: "4px 8px",
		textTransform: "uppercase",
	}}>
		{status}
	</div>
}

export default StatusTag;