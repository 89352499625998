import React from "react"
import Icon from '@ant-design/icons';

function IconSvg()
{
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" style={{ stroke: "currentcolor" }} xmlns="http://www.w3.org/2000/svg">
      <path id="Vector" d="M1 1L13 13M1 13L13 1.35336" strokeLinecap="square" />
    </svg>
  )
}

export function IconClose(props)
{
  return <Icon component={IconSvg} {...props} />
}


