import React from "react";
import PreloaderNew from "./UI/PreloaderNew/PreloaderNew";

function BlancPage () {

    return (
<div style={{position: "fixed", width: '100vw', height: '100vh', top: 0, background: 'white', zIndex: 10000, display: "flex", justifyContent: "center", alignItems: "center"}}>

    <h2>LOADING</h2>
    <PreloaderNew/>
</div>
    )
}
export default BlancPage;