import React from "react"
import Icon from '@ant-design/icons';

function IconSvg()
{
  return (
    <svg width={"32"} height={"32"} viewBox="0 0 32 32" fill="none" style={{ stroke: "currentcolor" }} xmlns="http://www.w3.org/2000/svg">
      <g id="bell">
        <path id="Vector" d="M21 12.668C21 11.3419 20.4732 10.0701 19.5355 9.13243C18.5979 8.19475 17.3261 7.66797 16 7.66797C14.6739 7.66797 13.4021 8.19475 12.4645 9.13243C11.5268 10.0701 11 11.3419 11 12.668C11 18.5013 8.5 20.168 8.5 20.168H23.5C23.5 20.168 21 18.5013 21 12.668Z" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_2" d="M17.4419 23.5C17.2954 23.7526 17.0851 23.9622 16.8321 24.1079C16.5791 24.2537 16.2922 24.3304 16.0003 24.3304C15.7083 24.3304 15.4214 24.2537 15.1684 24.1079C14.9154 23.9622 14.7051 23.7526 14.5586 23.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  )
}

export function IconBell(props)
{
  return <Icon component={IconSvg} {...props} />
}
