import React from "react"
import Icon from '@ant-design/icons';

function IconSvg()
{
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="download">
    <g id="download_2">
    <path id="Vector 1" d="M3.33301 16.5944H17.083" stroke="#327FEF" strokeLinecap="round"/>
    <path id="Vector" d="M10.2089 3.39987L10.2089 12.1962M10.2089 12.1962L14.0283 8.93828M10.2089 12.1962L6.38943 8.93828" stroke="#327FEF" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    </g>
    </svg>
  )
}

export function IconDownload(props)
{
  return <Icon component={IconSvg} {...props} />
}


