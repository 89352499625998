import React from "react";
import { Button, Typography } from "antd";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/auth";
import { IconSuccess } from "../../../icons/IconSuccess";

const { Text } = Typography;

function SetPasswordSuccess() {
  const dispatch = useDispatch();

  return (
    <>
      <div
        style={{
          padding: "80px 64px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          position: "relative",
          alignItems: "center",
          alignSelf: "center",
          gap: "24px",
        }}
      >
        <IconSuccess />
        <Text
          style={{
            margin: 0,
            textAlign: "center",
            fontSize: "28px",
            fontWeight: "600",
            lineHeight: "42px",
            color: "#4B4F55",
          }}
        >
          Пароль успешно изменен!
        </Text>
        <Text
          style={{
            margin: 0,
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "24px",
            color: "#4B4F55",
          }}
        >
          Мы направим вас на страницу входа
        </Text>
        <Button
          type="primary"
          style={{
            width: "192px",
            borderRadius: 4,
            fontSize: 14,
            background: "#2E496F",
          }}
          onClick={() => dispatch(logout())}
        >
          Войти с новым паролем
        </Button>
      </div>
    </>
  );
}

export default SetPasswordSuccess;
