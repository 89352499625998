import React from "react";
import { useState, useEffect, useRef } from "react";
import styles from "./Distributions.module.css";
import { Table, Button, Tooltip, Badge, Divider, Alert, Progress } from "antd";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import { useDispatch, useSelector } from "react-redux";
import { bytesToSize } from "../../utils/converterBytes";
import { IconDownloadFile } from "../../icons";
import { IconInfoDetail } from "../../icons/IconInfoDetail";

import {
  getDistributionsServerDetails,
  getDistributionsServerList,
  serversDownload,
} from "../../store/distributions";

import {
  getServerLoaders,
  getDistributionsServerData,
  getServerNextPage,
  getServersDownloadData,
} from "../../store/distributions/selectors";
import { baseUrl, typeFileApp, viewExtension, getExtension} from "./utils";

export const DistributionsServerTable = ({
  createColumn,
  // downloadSoftFile,
  setIsModalViewOpenServer,
}) => {
  const dispatch = useDispatch();
  const isLoadingServer = useSelector(getServerLoaders);
  const serverData = useSelector(getDistributionsServerData);
  const lastItemServer = useRef();
  const observerServer = useRef();
  const [needToLoad, setNeedToLoad] = useState(false);
  const nextPage = useSelector(getServerNextPage);
  const [downloadError, setDownloadError] = useState(false)
  const serversData = useSelector(getServersDownloadData)

  const columnsServer = [
    createColumn("Версия ПО сервера", "major_version", (major, row) => (<div style={{display: "flex", flexDirection: "column"}}>
    <div style={{ display: "flex", gap: "12px", fontWeight: 600 }}>
      {major}
      <Tooltip
        title={<span style={{ color: "#333333" }}>Release notes</span>}
        color="#FFFFFF"
      >
        <Button
          size="small"
          type="text"
          style={{ background: "none" }}
          icon={<IconInfoDetail className={styles.icon} />}
          onClick={() => {
            dispatch(getDistributionsServerDetails(row?.major_version_id));
            setIsModalViewOpenServer(true);
          }}
        ></Button>
      </Tooltip>
    </div>
    <span>  {row['release_date']}</span>
    </div>), 100),
    createColumn(
      "Тип пакета",
      "releases",
      (releases, row) =>
        releases && releases.length > 0 ? (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "12px", alignItems: "flex-start" }}
          >
            {releases?.map((release, index) => (
              <div key={release.release_id + 'serverTable'} style={{ width: '100%' }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <p style={{ margin: '0 0 5px', fontSize: 14, fontWeight: 600 }}>
                   {" "}
                    {
                      <Badge
                        count={viewExtension(release?.file_name)}
                        color="#1677ff"
                      />
                    }   </p>
                  <div key={release.release_id + 'server'} style={{ display: "flex", justifyContent: "flex-end" }}>
                    {/* <Tooltip
                      title={
                        <span style={{ color: "#333333" }}>Release notes</span>
                      }
                      color="#FFFFFF"
                    >
                      <Button
                        size="small"
                        type="text"
                        style={{ background: "none" }}
                        icon={<IconInfoDetail className={styles.icon} />}
                        onClick={() => {
                          dispatch(
                            getDistributionsServerDetails(release?.release_id)
                          );
                          setIsModalViewOpenServer(true);
                        }}
                      ></Button>
                    </Tooltip> */}
                    <Tooltip
                      title={
                        <span style={{ color: "#333333" }}>Скачать файл</span>
                      }
                      color="#FFFFFF"
                    >
                    <Button
                      size="small"
                      type="text"
                      style={{ background: "none", padding: 0, margin: 0 }}
                      icon={<IconDownloadFile className={styles.icon} />}
                      onClick={() => {
                        handleClickDownload(row.major_version_id,release?.release_id, release?.file_name, release)

                      }}
                      title='Скачать файл'
                    ></Button>
                    </Tooltip>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <span style={{ color: "#7A7D86", marginRight: 7 }}>
                    {release.release_date}
                  </span> */}
                  <Badge
                    count={release.is_full ? 'FULL' : 'UPDATE'}
                    color={release.is_full ? "#52c41a" : '#13c2c2'}
                  />
                  <Badge
                    count={release.is_node ? 'NODE' : 'SERVER'}
                    color={release.is_node ? "#f759ab" : '#b37feb'}
                  />
                  <span style={{ color: "#7A7D86", marginLeft: "7px" }}>
                    ({bytesToSize(release?.release_size)})
                  </span>
                  {' '}


                </div>
                {index < releases.length - 1 && <Divider style={{ margin: '5px 0 0' }} />}
              </div>
            ))}
          </div>
        ) : (
          ""
        ),
      500

    ),
  ];

  const fetchData = (pagination = false) => {

    dispatch(
      getDistributionsServerList({
        page: pagination ? nextPage : 1,
        pagination: pagination,
      })
    );
  };

  useEffect(() => {
    if (isLoadingServer) return;
    if (observerServer?.current) observerServer?.current.disconnect();
    let callback = function (
      entries
      //observer
    ) {
      if (entries[0].isIntersecting && nextPage && serverData?.length > 0) {
        setNeedToLoad(true);
      }
    };

    observerServer.current = new IntersectionObserver(callback);
    observerServer.current.observe(lastItemServer?.current);
  }, [isLoadingServer]);

  useEffect(() => {
    (async () => {
      if (needToLoad && nextPage) {
        fetchData(true);
        setNeedToLoad(false);
      }
    })();
  }, [needToLoad]);

  useEffect(() => {
    const timerRefresh = setTimeout(fetchData, 500);
    return () => {
      clearTimeout(timerRefresh);
    };
  }, []);
  const handleClickDownload = (majorId,releaseId, fileName, release) => {
    dispatch(serversDownload({ openModal: true, release: release, progress: 0 }))
    const apiKey = sessionStorage.getItem('accessTokenPC')

    const protocol = baseUrl()

    fetch(`${protocol}/api/v1/pim/server-release/${majorId}/download/${releaseId}/`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${apiKey}`
      }
    }
    )

      .then(response => {
        if (!response.ok) {
          return response.text().then(text => { throw new Error(JSON.parse(text)?.detail || 'Ошибка сервера!') })

        }
        //   let stringType = fileUrl.slice(-4);
        // let index = stringType.lastIndexOf(".");
        const filename = fileName
        const contentLength = response.headers.get('Content-Length');
        // Получаем ReadableStream
        const reader = response.body.getReader();
        const chunks = [];
        let receivedLength = 0;
        // Функция для чтения данных
        function readStream() {

          return reader.read().then(({ done, value }) => {
            if (done) {
              // Преобразование собранных чанков в Blob
              const blob = new Blob(chunks, { type: typeFileApp(getExtension(filename)) },);
              const url = URL.createObjectURL(blob);

              // Создание ссылки для скачивания
              const a = document.createElement('a');
              a.style.display = 'none';
              a.href = url;
              a.download = filename; // Название скачиваемого файла
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
              return;
            }

            // Сохранение чанка данных
            chunks.push(value);
            receivedLength += value.length;
            // setProgressDownload(Math.floor((receivedLength / contentLength) * 100))
            dispatch(serversDownload({ progress: Math.floor((receivedLength / contentLength) * 100), openModal: true, release: release }));
            // Продолжаем чтение
            return readStream();
          });
        }

        return readStream();
      })
      .catch(error => {
        setDownloadError(error?.message || error?.Error)
      });
  }


  return (
    <div style={{ height: "400px", width: "100%", overflowY: "auto", background: 'white' }}>
      <div style={{ zIndex: 2, width: '100%', height: '400px', background: 'rgba(255, 255, 255, .9)', position: "absolute", padding: '10px', display: serversData?.openModal ? 'block' : "none" }}>
        <div style={{ display: "flex", gap: 10, flexDirection: 'column', background: '#F6F8FD', padding: '10px', borderRadius: 8, marginBottom: '20px' }}><span>Версия релиза: {serverData?.release?.release_version}</span>
          <span>Размер файла: {bytesToSize(serversData?.release?.release_size)}</span>
          <span>Файл:  <Badge
            count={viewExtension(serversData?.release?.file_name)}
            color="#1677ff"
          /></span>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: 20, flexDirection: "column" }}>
          {downloadError && <Alert message={downloadError} type="error" />}
          <Progress percent={serversData?.progress} steps={15} size={[25, 20]} />
          {serversData?.progress === 100 && <span style={{ fontSize: 20 }}>Файл успешно загружен!</span>}
          {(serversData?.progress === 100 || downloadError) && <Button type="primary"
            onClick={() => { dispatch(serversDownload({ progress: 0, openModal: false, release: null })); setDownloadError(false) }}
          >Закрыть</Button>}
        </div>
      </div>
      <div>

      </div>
      <Table
        loading={{
          indicator: <PreloaderNew />,
          spinning: isLoadingServer,
        }}
        columns={columnsServer}
        rowKey={(obj) => obj.major_version_id}
        size="small"
        style={{ opacity: serversData?.openModal ? 0 : 1 }}
        pagination={false}
        className={`${styles.table} ${styles.table_header}`}
        dataSource={serverData}
        locale={{
          triggerDesc: "сортировать по убыванию",
          triggerAsc: "сортировать по возрастанию",
          cancelSort: "сбросить",
        }}
      />
      <div ref={lastItemServer} style={{ height: 20 }} />
    </div>
  );
};
