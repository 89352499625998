import React from "react"
import Icon from '@ant-design/icons';

function IconSvg()
{
  return (
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 7.37007V12.6208C2 14.5501 2 14.5501 3.77778 15.7786L8.66667 18.6724C9.40444 19.1092 10.6044 19.1092 11.3333 18.6724L16.2222 15.7786C18 14.5501 18 14.5501 18 12.6299V7.37007C18 5.44995 18 5.44995 16.2222 4.22144L11.3333 1.3276C10.6044 0.890799 9.40444 0.890799 8.66667 1.3276L3.77778 4.22144C2 5.44995 2 5.44995 2 7.37007Z" stroke="#8090B8" strokeWidth="1.2" strokeLinejoin="round"/>
<path d="M10 9V14" stroke="#8090B8" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10 6V6.43359" stroke="#8090B8" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}

export function IconInfoDetail(props)
{
  return <Icon component={IconSvg} {...props} />
}



