import React from "react"
import Icon from '@ant-design/icons';

function IconSvg()
{
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" style={{ stroke: "currentcolor" }} xmlns="http://www.w3.org/2000/svg">
      <g id="log-in">
        <path id="Vector" d="M6.5 2.5H3.16667C2.72464 2.5 2.30072 2.67559 1.98816 2.98816C1.6756 3.30072 1.5 3.72464 1.5 4.16667V15.8333C1.5 16.2754 1.6756 16.6993 1.98816 17.0118C2.30072 17.3244 2.72464 17.5 3.16667 17.5H6.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_2" d="M10.6665 14.1667L6.49984 10L10.6665 5.83337" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector_3" d="M6.5 10H16.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  )
}

export function IconLogIn(props)
{
  return <Icon component={IconSvg} {...props} />
}

