import React from "react"
import Icon from '@ant-design/icons';

function IconSvg()
{
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" style={{ stroke: "currentcolor" }} xmlns="http://www.w3.org/2000/svg">
      <path d="M17 4.5L13 1M17 4.5L17 19L3 19L3 0.999998L13 1M17 4.5L13 4.5L13 1" strokeWidth="1.2" strokeLinejoin="round" />
      <path d="M5 16H15" strokeWidth="1.2" strokeLinecap="round" />
      <path d="M10 6L10 13M10 13L13 10.4074M10 13L7 10.4074" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export function IconDownloadFile(props)
{
  return <Icon component={IconSvg} {...props} />
}



