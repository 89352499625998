import React, { useEffect } from "react";
import styles from "./PasswordConfirmNew.module.css";
import background_image from "../../../images/loginBackground.jpg";
import { useSelector, useDispatch } from "react-redux";
import { resetPasswordConfirm } from "../../../store/auth";
import {
  getChangePassLoader,
  getError,
  getIsSuccessChangePassword,
} from "../../../store/auth/selectors";
import { Form, Input, Button, Spin, Alert, Typography } from "antd";
import { getUrlLoaders } from "../../../store/urlfilter/selectors";
import PreloaderNew from "../PreloaderNew/PreloaderNew";
import { useNavigate, useParams } from "react-router-dom";

const { Title } = Typography;

function PasswordConfirmNew() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const errors = useSelector(getError);
  const isLoader = useSelector(getUrlLoaders);
  const isSuccess = useSelector(getIsSuccessChangePassword);
  const isLoading = useSelector(getChangePassLoader);

  useEffect(() => {
    if (isSuccess === true)
      setTimeout(() => {
        navigate("/login");
      }, 3000);
  }, [isSuccess]);

  return (
    <Spin size="large" spinning={isLoader}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto 490px",
          height: "100vh",
        }}
      >
        <img
          src={background_image}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          alt="background"
        />
        <div
          style={{
            padding: "80px 64px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Title level={2} style={{ marginBottom: 24 }}>
            Изменить пароль
          </Title>

          {errors &&
            errors?.map((error) => (
              <Alert
                key={error + "r"}
                message="Authorization Error"
                description={error}
                type="error"
                showIcon
              />
            ))}
          <Form
            size="large"
            name="auth"
            layout="vertical"
            requiredMark={false}
            className={styles.form}
            style={{ width: "100%", position: "relative" }}
            onFinish={({ passwordNew, passwordNewConfirm }) => {
              dispatch(
                resetPasswordConfirm({
                  uid: params.uid,
                  token: params.token,
                  new_password: passwordNew,
                  re_new_password: passwordNewConfirm,
                })
              );
            }}
          >
            {isLoading && <PreloaderNew />}
            <Form.Item
              label="Новый Пароль"
              name="passwordNew"
              hasFeedback
              rules={[
                { required: true, message: "Пожалуйста, введите пароль" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Повторите Пароль"
              name="passwordNewConfirm"
              dependencies={["passwordNew"]}
              hasFeedback
              rules={[
                { required: true, message: "Пожалуйста, повторите пароль!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("passwordNew") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Пароли не совпадают!"));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: "flex",
                }}
              >
                <div style={{ flexGrow: 1 }} />
                <Button
                  type="link"
                  size="small"
                  style={{ padding: 0, fontSize: 14 }}
                  href="/login"
                >
                  Вход на портал
                </Button>
              </div>
            </Form.Item>

            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={isLoading ? true : false}
                style={{
                  width: "100%",
                  borderRadius: 4,
                  fontSize: 14,
                  background: "#2E496F",
                }}
              >
                Изменить
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
}

export default PasswordConfirmNew;
