import React from "react"

function Dot({ color = "lime", size = "0.5em", margin = "0.25em" })
{
    return (
        <>
            <div style={{
                display: "inline-block",
                marginInline: margin,
                backgroundColor: color,
                width: size,
                height: size,
                borderRadius: "50%"
            }}></div>
        </>
    );
}

export default Dot;