import React from "react"
import Icon from '@ant-design/icons';

function IconSvg()
{
  return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
<g id="interface-edit-pin-3--pin-push-thumbtack" clipPath="url(#clip0_5374_39974)">
<g id="Group">
<path id="Vector" d="M15.4291 16.2857C19.6896 16.2857 23.1434 12.8319 23.1434 8.57146C23.1434 4.31098 19.6896 0.857178 15.4291 0.857178C11.1686 0.857178 7.71484 4.31098 7.71484 8.57146C7.71484 12.8319 11.1686 16.2857 15.4291 16.2857Z" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_2" d="M17.5713 6.29147C17.3605 5.97438 17.0819 5.70807 16.7557 5.51176C16.4294 5.31545 16.0636 5.19405 15.6847 5.15633C15.3058 5.1186 14.9233 5.16549 14.5647 5.29361C14.2062 5.42173 13.8805 5.62789 13.6113 5.89718"stroke="#000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_3" d="M0.857422 23.1428L10.4231 14.4514" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</g>
<defs>
<clipPath id="clip0_5374_39974">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

  )
}

export function IconLoop({ direction, style, ...props })
{
  return <Icon component={IconSvg} {...props} style={{
    ...style, transform: {
    "top": "rotate(90deg)",
    "right": "rotate(180deg)",
    "left": "",
    "bottom": "rotate(-90deg)",
  }[direction] }} />
}
