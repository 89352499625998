import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	collapsed: false,
	currentPage: null
};

const sidebarSlice = createSlice({
	name: 'sidebar',
	initialState,
	reducers: {
		setCollapsed: (state, { payload }) =>
		{
			state.collapsed = payload;
		}, 
		setPageId: (state, { payload }) =>
		{
			state.currentPage = payload;
		}
	},
});

export const { setCollapsed, setPageId } = sidebarSlice.actions;
export default sidebarSlice.reducer;
