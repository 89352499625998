import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../utils/axios';
import { logout } from '../auth';

const typeFileApp = (value) => {
  let type = ''
  switch (value) {
      case 'txt': type = ''; break;
      case 'zip': type = 'application/zip'; break;
      case 'xlsx': type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'; break;
      case 'xls': type = 'application/vnd.ms-excel'; break;
      case 'doc': type = 'application/msword'; break;
      case 'docx': type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; break;
      default: type = `application/${value}`;
  }
  return type
}
export const getDocumentsList = createAsyncThunk(
  'getDocumentsList',
  async (filter, { rejectWithValue, dispatch },) => {
    try {

      const { data } = await axiosInstance.get(`/api/v1/docs/`, { params: filter });
      return data;
    }
    catch (err) {

      if (err.response.status === 401) dispatch(logout())
      else
        return rejectWithValue(err.response.data)
    }
  }

);
export const getDocFile = createAsyncThunk(
  'getDocFile',
  async ({ url, name,typeFile }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.get(`/api/v1/docs/files/`, { params: { url: url },  responseType: 'blob',
      });
      return { data, name,  typeFile };
    } catch (err) {
      if (err.response.status === 401) dispatch(logout())
      else
        return rejectWithValue(err.response.data)
    }
  }
);

const initialState = {
  documentsData: null,
  errors: null,
  loaders: {
    common: false,
  },
};

const documentationSlice = createSlice({
  name: 'documentation',
  initialState,
  reducers: {
    resetData: (state) => {
      state.documentsData = null;
    },

  },

  extraReducers: (builder) => {

    //getDocumentsList
    builder.addCase(getDocumentsList.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getDocumentsList.fulfilled, (state, { payload }) => {
      state.documentsData = payload;
      state.loaders.common = false;
      state.errors = null;
    });
    builder.addCase(getDocumentsList.rejected, (state, action) => {

      state.loaders.common = false;
      state.errors = action.payload?.detail ? action.payload?.detail : action.payload?.error;

    });
    //getDocFile
    builder.addCase(getDocFile.pending, (state) => {
      // state.loaders.common = true;
      // state.loaders.external = true;
      // state.errors.getError = null;
    });
    builder.addCase(getDocFile.fulfilled, (state, { payload }) => {
      const blob = new Blob([payload.data], { type: typeFileApp(payload.typeFile) });
      // const blob = new Blob([payload.data], { type: `application/${payload.typeFile}` });
      const fileUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
		link.setAttribute('href', fileUrl)
		link.setAttribute('download', `${payload.name}.${payload.typeFile}`)
		link.style.display = 'none'

		document.body.appendChild(link)

		link.click()

		document.body.removeChild(link)
    });
    builder.addCase(getDocFile.rejected, (state, action) => {
      // state.loaders.common = false;
      state.errors= action.payload.detail || action.payload.message || 'Ошибка сервера';
      // state.loaders.external = false;
    });

  }

});
export const { resetData } = documentationSlice.actions;
export default documentationSlice.reducer;