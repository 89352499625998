import React from "react"
import Icon from '@ant-design/icons';

function IconSvg() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="18" height="18" rx="2" fill="#FFECEC" />
            <path d="M5 5L13 13M5 13L13 5" stroke="#EB5757" strokeLinecap="round" />
        </svg>
    )
}

export function IconErrorHash(props) {
    return <Icon component={IconSvg} {...props} />
}