import axios from 'axios';

let baseUrlClear='/';
if (process.env.REACT_APP_API_SERVER==='localhost:8000') {baseUrlClear=`http://${process.env.REACT_APP_API_SERVER}`} 
else {baseUrlClear = `https://${process.env.REACT_APP_API_SERVER}` }

const axiosInstanceEdl = axios.create({
  baseURL: baseUrlClear,
});

axiosInstanceEdl.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('edlTokenPC');
    if (token !== null) {
      config.headers.Authorization = `Token ${token}`;
    }
    return config;
  },
  (err) => Promise.reject(err)
);

export { axiosInstanceEdl };
