import React, { useEffect, useState } from 'react'
import { Row, Col, Collapse, Input, Button, Typography, Form } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import styles from './Sandbox.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { getSandboxHashDetailsData } from '../../store/sandbox';
import { getHashDetails, getSandboxHashLoader, getHashErrors, getSandboxFileErrors } from '../../store/sandbox/selectors';
import { resetHashCheckerData } from '../../store/sandbox';
import { getFileHash, getFileUploadingErrors, getFileUploadingProgress, getIsFileUploadingInProgress } from '../../store/sandbox/selectors';
import { getOrganisation, getUser } from '../../store/auth/selectors';
import { sendFileToCheck } from '../../store/sandbox';
import { useRef } from 'react';
import { IconErrorHash } from '../../icons/IconErrorHash';
import FileCheckerResult from './FileCheckerResult';

import PreloaderNew from '../UI/PreloaderNew/PreloaderNew';
import FileInfo from './FileInfo';
const { Text } = Typography;

export default function HashFileChecker() {

  const dispatch = useDispatch();
  const isLoader = useSelector(getSandboxHashLoader)
  const fileErrors = useSelector(getSandboxFileErrors)
  const [hash, setHash] = useState(null);
  let detailsData = {};
  let tableData = {};
  const allHashDetailsData = useSelector(getHashDetails)
  const hashErrors = useSelector(getHashErrors);
  const [file, setFile] = useState(null);
  const [fileSize, setFileSize] = useState(null);
  const fileInputRef = useRef(null);
  const orgInfo = useSelector(getOrganisation)
  const currentUser = useSelector(getUser)
  const client_id = `${orgInfo?.id}_${currentUser?.id}`
  const progress = useSelector(getFileUploadingProgress)
  const errors = useSelector(getFileUploadingErrors)
  // const isFinished = useSelector(getIsFinished)
  const isLoading = useSelector(getIsFileUploadingInProgress)
  const pinnedFileHash = useSelector(getFileHash)
  const [allowSendFileToCheck, setAllowSendFileToCheck] = useState(null)



  const handleButtonClick = () => {

    fileInputRef.current.click();
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0';
    } else {
      var k = 1024;
      var dm = decimals < 0 ? 0 : decimals;
      var sizes = ['байт', 'КБ', 'МБ', 'ГБ', 'ТБ'];
      var i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
  }

  const handleFileChange = (event) => {
    if (event?.target?.files[0] && event?.target?.files[0]?.size <= 50 * 1024 * 1024) {
      setAllowSendFileToCheck(true);
      setFile(event?.target?.files[0])
      setFileSize(formatBytes(event?.target?.files[0]?.size, 1))
      dispatch(sendFileToCheck({ file: event?.target?.files[0], orgId_userId: client_id }))
    }
    else{
      setAllowSendFileToCheck(false);
    }
  };
  useEffect(() => {
    if (!hash) {
      dispatch(resetHashCheckerData());
    }
  }, [hash])
  if (allHashDetailsData) {
    const { uid, sha256, verdict, first_seen, last_seen, hits, file_size, file_type, file_name, file_url, source_ip, ...rest } = allHashDetailsData;
    detailsData = { uid, sha256, verdict, first_seen, last_seen, hits, file_size, file_type, file_name, file_url, source_ip };
    tableData = { ...rest }
  }


  const onSubmitForm = () => {
    if (hash) {
      setFile(null);
      dispatch(getSandboxHashDetailsData({ hash }))
    }

  }
  const [form] = Form.useForm();
  return (
    <Row justify={"center"} gutter={8}>
      <Col span={24}>
        <Collapse
          size="small"
          expandIcon={({ isActive }) => <DownOutlined style={{ fontSize: 14, paddingTop: 8 }} rotate={isActive ? 180 : 0} />}
          style={{
            backgroundColor: 'white',
            padding: '16px 24px 16px 24px',
            alignContent: 'center',
            borderRadius: '8px',
            fontSize: 18,
            fontWeight: 600,
            minHeight: '60px',
          }}
          ghost
          defaultActiveKey={[1]}
          items={[
            {
              key: 1,
              label: (
                <span>Проверить хеш или файл</span>
              ),
              children:
                <div style={{ opacity: isLoader ? 0.2 : 1 }}>

                  <Row span={24}>



                    <Col span={19}>
                      <Form form={form} onFinish={onSubmitForm}>
                        <Form.Item
                          name='hash'
                        >
                          <Input style={{
                            width: '99%',
                            height: '44px',
                            borderRadius: '4px',
                            paddingRight:2
                            

                          }} suffix={<Button htmlType='submit' data-testid='hashSend' className={styles.sandboxBtn}><RightOutlined style={{ fontSize: 14 }} /></Button>}
                           allowClear={true} onChange={(e) => setHash(e.target.value)} onClear={() => { setHash(null) }} placeholder="Введите хеш"></Input>
                        </Form.Item>
                      </Form>
                    </Col>


                    <Col span={5}>
                      <input
                        type="file"
                        data-testid = 'fileInput'
                        // accept=".pe, .pdf, .flash, .jar, .doc, .docx, .xls, .xlsx, .zip, .tar, .gz, .apk, .sh, .linux"
                        onChange={handleFileChange}
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                      />
                      <Button onClick={handleButtonClick} className={styles.sandboxSubmitBtn}>Выберите файл</Button>
                    </Col>
                  </Row>


                  {isLoader && !file && <PreloaderNew />}
                  {detailsData?.sha256 &&
                    <FileInfo detailsData={detailsData} tableData={tableData} />
                  }
                  {hashErrors && !file && <div style={{ display: 'flex', alignItems: 'center', gap: 8, marginTop: 12 }}>
                    <IconErrorHash /><Text style={{ fontWeight: 400, color: '#EB5757' }}>{hashErrors}</Text>
                  </div>}
                  {fileErrors && !file && <div style={{ display: 'flex', alignItems: 'center', gap: 8, marginTop: 12 }}>
                    <IconErrorHash /><Text style={{ fontWeight: 400, color: '#EB5757' }}>{fileErrors}</Text>
                  </div>}

                  {file && !hashErrors && !fileErrors && !detailsData?.sha256 && !isLoader && <FileCheckerResult file={file}
                    fileSize={fileSize}
                    progress={progress}
                    errors={errors}
                    pinnedFileHash={pinnedFileHash}
                    isLoading={isLoading}
                    allowSendFileToCheck={allowSendFileToCheck}
                  />}

                </div>

            },
          ]} />
      </Col>
    </Row >
  )
}