import React from 'react'
import { useEffect, useState } from "react";
import styles from "./EdlStyles.module.css";
import { Alert, Button, Space, Table, Typography } from "antd";
import { IconError } from "../../icons";
import { useDispatch, useSelector } from "react-redux";
import {
  addExternalList,
  clearEdlErrors,
  getExternalList,
} from "../../store/edl";
import {
  getEdlCheckErrors,
  getEdlExternalSuccessAdd,
  getExternalDataLists,
  getExternalLoaders,
} from "../../store/edl/selectors";

import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import { IconPlus } from "../../icons/IconPlus";
import ModalListDetailExternal from "./ModalListDetailExternal";

const { Text, Link } = Typography;
function EdlExternalNew({ permissions }) {
  const dispatch = useDispatch();
  const errors = useSelector(getEdlCheckErrors);
  const isSuccessExternalAdd = useSelector(getEdlExternalSuccessAdd);
  const [modalDetailOpen, setModalDetailOpen] = useState({
    open: false,
    data: false,
  });
  const [activePage, setActivePage] = useState(1);
  const isLoadersList = useSelector(getExternalLoaders);

  const externalData = useSelector(getExternalDataLists);
  useEffect(() => {
    dispatch(getExternalList(activePage));
  }, [isSuccessExternalAdd, activePage]);
  const columnsExternal = [
    createColumn("Название", "name", (name, row) => {
      return (
        <Space>
          <Link
            onClick={() => {
              dispatch(clearEdlErrors());
              setModalDetailOpen({ open: true, data: row });
            }}
            style={{ fontSize: "12px" }}
          >
            {name}
          </Link>
          {row["version"] === "demo" && (
            <div
              style={{
                fontSize: "8px",
                background: `rgb(0, 0, 128)`,
                borderRadius: "4px",
                padding: "2px",
                textAlign: "center",
                color: "white",
              }}
            >
              {"DEMO"}
            </div>
          )}
        </Space>
      );
    }),
    createColumn("Тип", "list_type"),
    createColumn("Кол-во Записей", "instance_count"),
    createColumn("Ссылка на подключение", "file", (url, row) => (
      <Space title={url}>
        {row["can_be_connected"] ? (
          row["is_connected"] === false ? (
            <Button
              className={styles.addButton}
              disabled={!permissions.full_access}
              style={{
                backgroundColor: "transparent",
                // display: !true ? "none" : "",
                height: "20px",
                width: "120px",
                padding: "8px",
                fontSize: "10px",
              }}
              onClick={() => dispatch(addExternalList(row["id"]))}
            >
              <IconPlus />
              Подключить
            </Button>
          ) : (
            <Text style={{ color: "#219653", fontSize: "10px" }}>
              Подключено
            </Text>
          )
        ) : (
          <Text style={{ color: "black", fontSize: "10px", fontWeight: 800 }}>
            Оформить подписку
          </Text>
        )}
      </Space>
    )),
    createColumn("Источник", "vendor"),
    {
      title: "Дата получения",
      key: `date_receipted`,
      dataIndex: "date_receipted",
      render: (text) => {
        return (
          <Space>
            {text}
            {/* <Button size="small" type="text" onClick={() => dispatch(getFile({ url: row['file'], name: row['name'] }))} icon={<IconDownloadFile style={{ color: "#8090B8" }} />} /> */}
          </Space>
        );
      },
    },
  ];
  return (
    <div style={{ position: "relative" }}>
      {isLoadersList && <PreloaderNew />}
      {modalDetailOpen?.open && (
        <ModalListDetailExternal
          permissions={permissions}
          open={modalDetailOpen?.open}
          close={() => setModalDetailOpen({ open: false, data: false })}
          data={modalDetailOpen?.data}
        />
      )}
      {errors && (
        <Alert
          Alert
          message={errors}
          type="error"
          key={+"error"}
          showIcon
          icon={<IconError />}
          closable
        ></Alert>
      )}
      <Table
        //dataSource={externalData?.results.filter((item)=> item.version==='demo' || item.vendor!=='Kaspersky'  )}
        dataSource={externalData?.results}
        columns={columnsExternal}
        rowKey={(obj) => obj.id}
        className={styles.table}
        rowClassName={(v) => (v.hightlight ? styles.rowHighlight : "")}
        size="small"
        pagination={{
          showSizeChanger: false,
          position: "bottomRight",
          defaultPageSize: 10,
          total: externalData?.count,
          onChange: (page) => setActivePage(page),
        }}
      />
    </div>
  );
}

function createColumn(title, key, render) {
  return { title, key, dataIndex: key, render };
}
export default EdlExternalNew;
