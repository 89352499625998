import React from "react";
import styles from "./EdlStyles.module.css";
import {
  Alert,
  Button,
  Col,
  ConfigProvider,
  Drawer,
  Row,
  Space,
  Switch,
} from "antd";
import { IconClose } from "../../icons";
import {Typography} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getEdlCheckErrors,
  getExternalLoaders,
} from "../../store/edl/selectors";
import { addExternalList } from "../../store/edl";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import { IconPlus } from "../../icons/IconPlus";

function ModalListDetailExternal({ open, close, data, permissions }) {
  const { Text } = Typography;
  const dispatch = useDispatch();
  const isLoadersList = useSelector(getExternalLoaders);
  const errors = useSelector(getEdlCheckErrors);

  return (
    <Drawer
      open={open}
      onClose={close}
      width={700}
      styles={{ header: { display: "none" } }}
    >
      <Space
        style={{
          display: "flex",
          justifyContent: "end",
          paddingInline: 16,
          height: 56,
        }}
      >
        <Button
          onClick={close}
          icon={<IconClose style={{ width: 12, height: 12 }} />}
          type="ghost"
          style={{
            width: 32,
            height: 32,
            backgroundColor: "#F6F8FD",
            padding: 0,
          }}
        />
      </Space>
      <Space
        direction="vertical"
        size={24}
        style={{
          paddingInline: 48,
          paddingBottom: 32,
          display: "flex",
          position: "relative",
        }}
      >
        {isLoadersList && <PreloaderNew />}
        <Typography.Title level={2} style={{ marginBottom: 0 }}>
          {data?.name}{" "}
          {data?.version === "demo" && (
            <span
              style={{
                fontSize: "30px",
                background: `rgb(0, 0, 128)`,
                borderRadius: "4px",
                padding: "2px",
                textAlign: "center",
                color: "white",
                width: "100px",
              }}
            >
              {"DEMO"}
            </span>
          )}
        </Typography.Title>
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                itemColor: "#8090B8",
                itemHoverColor: "#333333",
                itemSelectedColor: "#333333",
                inkBarColor: "#333333",
              },
            },
          }}
        ></ConfigProvider>
        <Col span={8}>
          <Switch
            checked={true}
            style={{ width: "44px" }}
            checkedChildren=""
            unCheckedChildren=""
            disabled={true}
          ></Switch>
          <Text
            style={{
              marginLeft: "12px",
              color: "#7A7D86",
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            {/* {true ? "Активен" : "Неактивен"} */}
          </Text>
        </Col>
        {errors && <Alert message={errors} type="error" showIcon></Alert>}
        <div
          className={styles.detailWrapper}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Row>
            <Col span={10}>
              <span className={styles.detailTitle}>Тип списка</span>
            </Col>
            <Col span={14}>
              <span className={styles.detailText}>{data?.list_type}</span>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <span className={styles.detailTitle}>Дата получения</span>
            </Col>
            <Col span={14}>
              <span className={styles.detailText}>{data?.date_receipted}</span>
              <span className={styles.detailTitle}> {data?.updated_by}</span>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <span className={styles.detailTitle}>Записей</span>
            </Col>
            <Col span={14}>
              <span className={styles.detailText}>{data?.instance_count} </span>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <span className={styles.detailTitle}>Источник</span>
            </Col>
            <Col span={14}>
              <span className={styles.detailText}>{data?.vendor}</span>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <span className={styles.detailTitle}>Latest</span>
            </Col>
            <Col span={14}>
              <span
                className={styles.detailText}
                style={
                  data?.latest === true ? { color: "green" } : { color: "red" }
                }
              >
                {data?.latest === true ? "Да" : "Нет"}
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <span className={styles.detailTitle}>Ссылка на подключение</span>
            </Col>
            <Col span={14}>
              {data?.can_be_connected ? (
                data?.is_connected === false ? (
                  <Button
                    className={styles.addButton}
                    disabled={!permissions?.full_access}
                    style={{
                      backgroundColor: "transparent",
                      //   display: !true ? "none" : "",
                      height: "20px",
                      width: "120px",
                      padding: "8px",
                      fontSize: "10px",
                    }}
                    onClick={() => dispatch(addExternalList(data?.id))}
                  >
                    <IconPlus />
                    Подключить
                  </Button>
                ) : (
                  <span
                    className={styles.detailText}
                    style={{ color: "green" }}
                  >
                    {"Подключен"}
                  </span>
                )
              ) : (
                <span
                  className={styles.detailText}
                  style={{ color: "black", fontWeight: 800 }}
                >
                  {"Оформить подписку"}
                </span>
              )}
            </Col>
          </Row>
          {/* <Row><Col span={10}><span className={styles.detailTitle}>Скачать</span></Col><Col span={14}><Button size="small" type="text" onClick={() => dispatch(getFile({ url: data?.file, name: data?.name }))}icon={<IconDownloadFile style={{ color: "#8090B8" }} />}   /></Col></Row> */}
          <Row>
            <Col span={10}>
              <span className={styles.detailTitle}>Описание</span>
            </Col>
            <Col span={14}>
              <span className={styles.detailText}>{data?.description}</span>
            </Col>
          </Row>
        </div>
      </Space>
    </Drawer>
  );
}

export default ModalListDetailExternal;
