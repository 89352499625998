import React from 'react'
import { Row, Col, Button, Select, Radio, Divider } from 'antd';
// import { CalendarOutlined} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { getSandboxMyAssetsDrop } from '../../store/sandbox/selectors';
import styles from './Sandbox.module.css'
// import dayjs from "dayjs";
export default function StatsFiltersArea({
    statsDateAfterFilter,
    // setStatsDateAfterFilter,
    // setStatsDateBeforeFilter,
    statsDateBeforeFilter,
    setStatsValue,
    statsPoint,
    setStatsPoint,
    setStatsSourceIn,
    statsValue,
    org,
    clearFilters,
    fetchStatsData


}) {
    // const [viewDataFilter, setViewDataFilter] = useState('Выберите интервал');
    // const [openDate, setOpenDate] = useState(false);
    const myAssetsDrop = useSelector(getSandboxMyAssetsDrop);
    // const handleOpenChangeDate = () => setOpenDate(!openDate);
    // const frequentlyUsedFilters = [];
    // const dateFormat = "YYYY-MM-DD hh:mm:ss";

    // useEffect(() => {
    //     if (statsDateBeforeFilter === null && statsDateAfterFilter === null) {
    //         setViewDataFilter('Выберите интервал')
    //     }
    // }, [statsDateBeforeFilter, statsDateAfterFilter])


    // const resetDateFilter = (value) => {
    //     const date =
    //         value.replaceAll('T', ' ')
    //     return dayjs(date, dateFormat);
    // };


    // const changeDateToUTCFormatString = (date) =>{
    //     return date?.$d.toLocaleString("ru-RU").replace(",", "").split(' ')[0].split('.').reverse().join('-') + 'T' + date?.$d.toLocaleString("ru-RU").replace(",", "").split(' ')[1]
    // }

    // const handleChangeDateAfter = (e) => {
    //     if (e?.$d) {
    //         setStatsDateAfterFilter(changeDateToUTCFormatString(e))
    //         if (statsDateBeforeFilter) {
    //             setViewDataFilter(
    //                 e?.$d.toLocaleString("ru-RU").replace(",", "") +
    //                 " - " +
    //                 statsDateBeforeFilter.toLocaleString('ru-RU').split('T')[0].split('-').reverse().join('.') + ' ' + statsDateBeforeFilter.toLocaleString('ru-RU').split('T')[1]
    //             );
    //         }
    //         else
    //             setViewDataFilter(
    //                 e?.$d.toLocaleString("ru-RU").replace(",", "") + " - "
    //             );
    //     } else setStatsDateAfterFilter(null);
    // };
    // const handleChangeDateBefore = (e) => {
    //     if (e?.$d) {
    //         setStatsDateBeforeFilter(changeDateToUTCFormatString(e))
    //         if (statsDateAfterFilter) {
    //             setViewDataFilter(
    //                 statsDateAfterFilter.toLocaleString('ru-RU').split('T')[0].split('-').reverse().join('.') + ' ' + statsDateAfterFilter.toLocaleString('ru-RU').split('T')[1] +
    //                 " - " +
    //                 e?.$d.toLocaleString("ru-RU").replace(",", "")
    //             );
    //         } else
    //             setViewDataFilter(
    //                 " - " + e?.$d.toLocaleString("ru-RU").replace(",", "")
    //             );
    //     } else setStatsDateBeforeFilter(null);
    // };
    // const calculateMonth = () => {
    //     let date = new Date();
    //     date.setDate(date.getDate() - 30);
    //     return date;
    // };
    // const content = (
    //     <List
    //         size="small"
    //         header={false}
    //         footer={
    //             <div>
    //                 <hr />
    //                 <Space direction="vertical">
    //                     <span>Начало (UTC)</span>
    //                     <DatePicker
    //                         renderExtraFooter={() => null}
    //                         style={{width: '200px'}}
    //                         footer={null}
    //                         format="DD-MM-YYYY hh:mm:ss"
    //                         onChange={(e) => {
    //                             handleChangeDateAfter(e);
    //                         }}
    //                         showNow={false}
    //                         showTime
    //                         showToday={false}
    //                         placeholder="Выберите дату начала"
    //                         disabledDate={(d) => {
    //                             !d || d.isBefore(calculateMonth()) || d.isAfter(new Date())
    //                         }}
    //                         value={
    //                             statsDateAfterFilter ? resetDateFilter(statsDateAfterFilter) : null
    //                         }
    //                     />
    //                     <span>Окончание (UTC)</span>
    //                     <DatePicker
    //                         showNow={false}
    //                         style={{width: '200px'}}
    //                         showToday={false}
    //                         renderExtraFooter={() => null}
    //                         showTime
    //                         format="DD-MM-YYYY HH:mm:ss"
    //                         disabledDate={(d) => {
    //                             !d || d.isBefore(calculateMonth()) || d.isAfter(new Date())
    //                         }}
    //                         onChange={(e) => {
    //                             handleChangeDateBefore(e);
    //                         }}
    //                         placeholder="Выберите дату окончания"
    //                         value={
    //                             statsDateBeforeFilter
    //                                 ? resetDateFilter(statsDateBeforeFilter)
    //                                 : null
    //                         }
    //                     />
    //                 </Space>
    //             </div>
    //         }
    //         dataSource={frequentlyUsedFilters}
    //         renderItem={(item) => (
    //             <List.Item
    //                 style={{
    //                     cursor: "pointer",
    //                     border: "none",
    //                 }}
    //             >
    //                 {item}
    //             </List.Item>
    //         )}
    //     />
    // );


    const selectDropdown = (menu, setStatsPoint, statsPoint, setStatsValue) => {
        return (
            <>
                <Radio.Group
                    onChange={(e) => {
                        if (e.target.value === 'api') {
                            setStatsPoint(e.target.value)
                            setStatsSourceIn('01')
                            setStatsValue('API')
                        }
                        else {
                            setStatsPoint(e.target.value);
                            e.target.value === 'customer' ? setStatsSourceIn('02') : setStatsSourceIn('00')
                            setStatsValue(null)
                        }

                    }}
                    value={statsPoint}
                >
                    <div
                        style={{
                            padding: "0 10px",
                            fontSize: 20,
                            display: "flex",
                            flexDirection: "column",
                            gap: 10,

                        }}
                    >
                        <Radio style={{ fontWeight: '400' }} value={'firewall'}>Устройство</Radio>
                        <Radio style={{ fontWeight: '400' }} value={'customer'}>Пользователь</Radio>
                        <Radio style={{ fontWeight: '400' }} value={'api'}>API</Radio>

                    </div>
                </Radio.Group>
                {statsPoint !== 'api' && <Divider style={{ margin: "8px 0" }} />}
                {(statsPoint === 'customer' || statsPoint === 'firewall') && menu}
            </>
        );
    };
    const getSelectArray = (key) => {
        if (key === 'firewall') { return myAssetsDrop }
        if (key === 'customer') { return org.users }
    }








    // const frequency = {

    //     lastHour: {
    //         numberDate: new Date() - 3600000,
    //         title: "За последний час",
    //     },
    //     last24Hours: {
    //         numberDate: new Date() - 3600000 * 24,
    //         title: "За последние 24 часа",
    //     },
    //     last7days: {
    //         numberDate: new Date() - 3600000 * 24 * 7,
    //         title: "За последние 7 дней",
    //     },
    //     thisMonth: {
    //         numberDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    //         title: "За текущий месяц",
    //     },
    //     last3Months: {
    //         numberDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
    //         title: "За последние 3 месяца",
    //     },
    //     lastYear: {
    //         numberDate: new Date(new Date().setYear(new Date().getFullYear() - 1)),
    //         title: "За год",
    //     },

    // };






    // const handleSetDateFilters = (value) => {
    //     if (frequency[value]) {
    //         setStatsDateBeforeFilter(null)
    //         //   setDateFrequentlyFilter(value);
    //         setViewDataFilter(frequency[value].title);
    //         const actualDate = new Date(frequency[value].numberDate);
    //         setStatsDateAfterFilter(actualDate.toISOString());
    //     }

    //     setOpenDate(false);
    // };

    // Object.keys(frequency).forEach((key, i) => {
    //     frequentlyUsedFilters.push(
    //         <Button
    //             style={{ background: "transparent", border: "none" }}
    //             key={i}
    //             onClick={() => {
    //                 handleSetDateFilters(key);
    //             }}
    //         >
    //             {frequency[key].title}
    //         </Button>
    //     );
    // });
    return (
        <Row gutter={10} style={{ display:'flex', justifyContent: 'space-between' }}>
            {/* <Col span={6}>

                <Space.Compact
                    title="Выберите интервал"
                    style={{ width: '100%' }}
                >
                    <Popover
                        placement="bottomLeft"
                        open={openDate}
                        onOpenChange={handleOpenChangeDate}
                        title={false}
                        content={content}
                        trigger="click"
                    >
                        <Button
                            style={{
                                display: "flex",
                                alignItems: "center",
                                width: '100%',
                                textAlign: 'center',
                                height: '44px',
                                color: viewDataFilter !== 'Выберите интервал' ? 'black' : '#C1C2C9'
                            }}
                        >
                            <CalendarOutlined
                                style={{

                                    padding: "5px 0",
                                    color: "#6679A9",
                                }}
                            />
                            <span style={{ fontSize: 14, padding: "0 5px", marginTop: 2 }}>
                                {viewDataFilter}
                            </span>
                        </Button>
                    </Popover>
                </Space.Compact>
            </Col> */}
            <Col span={6}>
                <Select
                    data-testid='statsSelect'
                    showSearch
                    maxTagCount={1}
                    mode={(statsPoint === 'customer' || statsPoint === 'firewall') ? "multiple" : 'single'}
                    allowClear={true}
                    placeholder="Отправитель"
                    //maxTagCount="responsive"
                    style={{ width: "100%", textAlign: "left", height: 44 }}
                    optionFilterProp={statsPoint === 'customer' ? 'label' : 'value'}
                    dropdownRender={(menu) =>
                        selectDropdown(menu, setStatsPoint, statsPoint, setStatsValue)
                    }
                    value={statsValue || undefined}
                    onChange={(e) => { if (e) { setStatsValue(e); } else { setStatsValue(null); setStatsPoint(null) } }}
                    // onDeselect{()=> }
                    onClear={() => { setStatsPoint(null), setStatsValue(null), setStatsSourceIn('00,01,02') }}
                    options={
                        getSelectArray(statsPoint)?.map((a) => ({
                            value: a.id ? a.id : a.firewall_id,
                            label: a.firewall_id ? a.firewall_id : a.first_name + ' ' + a.last_name,
                        }))
                    }
                />

            </Col>
            <Col span={7} style={{ display:'flex', justifyContent: 'space-between' }}>

                    <Button
                        style={{
                            backgroundColor: (statsPoint || statsDateAfterFilter ||
                                statsDateBeforeFilter || (Array.isArray(statsValue) && statsValue?.length !== 0)) ? '#2E496F' : "transparent",
                            color: (statsPoint || statsDateAfterFilter ||
                                statsDateBeforeFilter || (Array.isArray(statsValue) && statsValue?.length !== 0)) ? 'white' : '#2E496F',
                            borderRadius: '4px',
                            height: '100%',
                            width: '48%'
                            
                        }}
                        disabled={!statsPoint || statsValue?.length === 0}
                        onClick={() => clearFilters()}
                    >
                        Сбросить
                    </Button>


                    <Button
                        className={styles.sandboxSubmitBtn}
                        onClick={() => fetchStatsData()}
                        style={{
                            width: '48%'
                        }}
                    >
                        Обновить
                    </Button>
            </Col>

        </Row>
    )
}