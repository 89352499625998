import { Descriptions } from "antd";
import React from "react";

function ThreatVaultCardNew({ data })
{
    return (
        <Descriptions title="Детальная информация" bordered size="small" column={1}>
            {GetItem("Name", data?.name)}
            {GetItem("Unique Threat ID", data?.id)}
            {GetItem("Description", data?.description)}
            {GetItem("Category", data?.category)}
            {GetItem("Minimum Version", data?.min_version)}
            {GetItem("Maximum Version", data?.max_version)}
            {GetItem("Severity", data?.severity)}
            {GetItem("Action", data?.default_action)}
            {GetItem("CVE", renderArray(data?.cve))}
            {GetItem("related sha256 hashes", renderArray(data?.related_sha256_hashes))}
            {GetItem("Subtype", data?.subtype)}
            {GetItem("Vendor ID", renderArray(data?.vendor))}
            {GetItem("First Release", renderVersion(data?.ori_release_version, data?.ori_release_time))}
            {GetItem("Last Update", renderVersion(data?.latest_release_version, data?.latest_release_time))}
            {GetItem("Reference", renderArray(data?.reference))}
            {GetItem("Status", data?.status)}
        </Descriptions>
    )
}

function GetItem(label, data)
{
    return <Descriptions.Item
        label={label}
        labelStyle={{
            fontWeight: "bold",
            width: "250px",
            color: "black"
        }}
    >
        {data || "Нет данных"}
    </Descriptions.Item>
}

function renderArray(array)
{
    return array?.map((item, i) => <div key={item + i}>{item}</div>);
}

function renderVersion(version, time)
{
    return version ? `${version} (${time})` : "";
}

export default ThreatVaultCardNew;