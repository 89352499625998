import React from "react";
import styles from "./HeaderNew.module.css";
import {
  Layout,
  Typography,
  Dropdown,
  Space,
  Divider,
  Badge,
  Button,
  Avatar,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getOrganisation, getUser } from "../../../store/auth/selectors";
import { logout } from "../../../store/auth";
import { IconArrow, IconBell, IconLogIn, IconShield } from "../../../icons";
import { useNavigate } from "react-router-dom";
import { resetOrg } from "../../../store/organisation";

const { Title, Text } = Typography;

function HeaderNew({ setModalItem }) {
  const orgDetails = useSelector(getOrganisation);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(getUser);

  const formatUserName = (firstName, lastName, email) => {
    let title = "Аноним";
    if (lastName) title = lastName + " " + firstName.slice(0, 1);
    else if (firstName) title = firstName;
    else title = email || "Аноним";
    return title;
  };
  return (
    <>
      <Layout.Header
        style={{
          height: 52,
          lineHeight: "1",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "white",
          padding: "10px 40px",
        }}
      >
        <Title level={5} style={{ fontWeight: "bold", margin: "0" }}>
          {orgDetails?.name || "Нет названия"}
        </Title>
        <Space split={<Divider type="vertical" style={{ fontSize: 28 }} />}>
          <Button
            type="ghost"
            style={{ paddingInline: 8 }}
            disabled={true}
            onClick={() => navigate("/path")}
          >
            <Badge
              // count={12}
              color="blue"
              offset={[-5, 5]}
              size="small"
              style={{ padding: "0 4px" }}
            >
              <IconBell style={{ color: "#6679A9" }} />
            </Badge>
          </Button>
          <Dropdown
            menu={{
              items,
              onClick: getDropdownOnClick(dispatch, setModalItem),
            }}
            trigger={["click"]}
            placement="bottom"
            overlayClassName={styles.menu}
          >
            <Button type="ghost" style={{ padding: 0 }}>
              <Text>
                <Space>
                  <Avatar style={{ backgroundColor: "black" }}>А</Avatar>
                  <span>
                    {formatUserName(
                      userData?.first_name,
                      userData?.last_name,
                      userData?.email
                    )}
                  </span>
                  <IconArrow direction="bottom" />
                </Space>
              </Text>
            </Button>
          </Dropdown>
        </Space>
      </Layout.Header>
    </>
  );
}

function getDropdownOnClick(dispatch, setModalItem) {
  return function onClick({ key }) {
    if (key === "exit") {
      dispatch(resetOrg());
      dispatch(logout());
    } else if (key === "pass") setModalItem(true);

    // else {
    //   const url = items.find(el => el.key === key)?.url;
    //   if (url) {
    //      navigate(url);
    //   }
    // }
  };
}

const items = [
  createMenuItem({ id: "pass" }, "Сменить пароль", <IconShield />),
  // createMenuItem(pages.profile, "Профиль", <IconUser />),
  // createMenuItem(pages.security, "Безопасность", <IconShield />),
  // createMenuItem(pages.settings, "Настройки", <IconSettings />),
  createMenuItem({ id: "exit" }, "Выйти", <IconLogIn />),
];

function createMenuItem(page, label, icon) {
  return {
    key: page.id,
    url: page.path,
    label: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1em",
        }}
      >
        {icon}
        {label}
      </div>
    ),
  };
}

export default HeaderNew;
