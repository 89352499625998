import { Alert, Badge, Button, Col, Drawer, Rate, Row, Space, Tooltip, Typography } from "antd";
import React, { } from "react";
import { IconClose } from "../../icons";
import {  useSelector } from "react-redux";
//import { getUrlKfeedDetail } from "../../store/urlfilter";
import {
  getUrlCheckErrors,
  //getUrlKfeedDataDetail,
} from "../../store/urlfilter/selectors";
import { QuestionCircleOutlined } from "@ant-design/icons";
const { Title, Text } = Typography;
const grayText = { color: "#7A7D86" };

function UrlItemModal({ open, close, item, mask, url, data }) {
  //const dispatch = useDispatch();
  //const data = useSelector(getUrlKfeedDataDetail);
  const errors = useSelector(getUrlCheckErrors);

  // useEffect(() => {
  //   if (mask && !mask?.includes("*")) {
  //     dispatch(
  //       getUrlKfeedDetail({ param: "mask", value: mask, urlKfeed: item?.url })
  //     );
  //   } else if (url && !url?.includes("*")) {
  //     dispatch(getUrlKfeedDetail({ param: "url", value: url }));
  //   } else
  //     dispatch(getUrlKfeedDetail({ param: "stats_id", value: item.stats_id }));
  // }, [item, mask]);

  return (
  <Drawer
  open={open}
  onClose={close}
  width={800}
  styles={{ header: { display: "none" } }}
>
  <Space
    style={{
      display: "flex",
      justifyContent: "space-between",
      paddingInline: 24,
      height: 64,
      zIndex: 10,
    }}
  >


    <Title
      level={3}
      style={{
        fontSize: '24px',
        fontWeight: 600,
      }}
    >
      Детальная информация
    </Title>
    <Button
      onClick={close}
      icon={<IconClose style={{ width: 12, height: 12 }} />}
      type="ghost"
      style={{
        width: 32,
        height: 32,
        backgroundColor: "#F6F8FD",
        padding: 0,
        marginRight: "-32px",
      }}
    />

  </Space>
  <Title
    level={5}
    style={{
      margin: 0,
      paddingInline: 24,
      fontSize: '16px',
      fontWeight: 600,
      color: '#327FEF'
    }}
  >
    {data?.mask || url || mask || item?.url || ""}

  </Title>

  {errors && (
    <Alert
      message={"В текущей базе угроз Kaspersky запись не найдена"}
      style={{ fontSize: "20px" }}
      type="error"
      showIcon
    />
  )}

  {!errors && (<div style={{ display: 'flex', flexDirection: 'column', gap: 20, marginTop: 24, marginBottom: 24, paddingLeft: 24 }}>
    {data?.receipted_at && (<Row>
      <Col span={8} >
        <Text style={grayText}>По данным kaspersky от: </Text>
      </Col>
      <Col span={16}>
        <Text>{data?.receipted_at}</Text>
      </Col>
    </Row>)}

    {data?.attack_type && (<Row>
      <Col span={8} >
        <Text style={grayText}>Attack type</Text>
      </Col>
      <Col span={16}>
        <Text>{data?.attack_type}</Text>
      </Col>
    </Row>)}

    <Row>
      <Col span={8} >
        {data && <Text style={grayText}>Categories</Text>}
      </Col>
      <Col span={16}>
        {data ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 24,
            }}>

            {data?.categories?.map((item, index) => (
              <React.Fragment key={item.cat_id + "cat"}>
                <Badge
                  count={item.name}
                  style={{
                    backgroundColor: 'rgba(235, 87, 87, 0.1)',
                    color: '#EB5757',
                    borderRadius: 8,
                    fontSize: 14,
                    fontWeight: 600
                  }}
                />
                {index < data.categories.length - 1 && " "}
              </React.Fragment>
            ))}
          </div>
        ) : (
          ""
        )}
      </Col>
    </Row>

    {data?.pa_categories?.length > 0 && (
      <>
        <Row>
          <Col span={8} >
            <Text style={grayText}>PA Categories</Text>
          </Col>
          <Col span={16}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: 24
              }}
            >
              {data?.pa_categories?.map((item, index) => (
                <React.Fragment key={item + index}>
                  <Badge
                    count={item}
                    style={{
                      backgroundColor: 'rgba(33, 150, 83, 0.1)',
                      color: 'rgba(33, 150, 83, 1)',
                      borderRadius: 8,
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  />
                  {index < data.pa_categories.length - 1 && " "}
                </React.Fragment>
              ))}
              {/* {data?.pa_categories?.map((item, index) => (
                
                <span key={item + index}>
                  {item}
                  {index < data?.pa_categories?.length - 1 ? ", " : ""}
                  &nbsp;
                </span>
              ))} */}
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={8} >
          </Col>
          <Col span={16}>
            <Text style={{ color: '#C1C2C9' }}>Обновлено: {data.updated_at || "Нет данных"}</Text>
          </Col>
        </Row>
      </>)}

    {data?.last_seen && (<Row>
      <Col span={8} >
        <Text style={grayText}>Last seen</Text>
      </Col>
      <Col span={16}>
        <Text>{data?.last_seen}</Text>
      </Col>
    </Row>)}

    {data?.first_seen && (<Row>
      <Col span={8}>
        <Text style={grayText}>First seen</Text>
      </Col>
      <Col span={16}>
        <Text>{data?.first_seen}</Text>
      </Col>
    </Row>)}

    {data?.geo?.length > 0 && (<Row>
      <Col span={8}>
        <Text style={grayText}>Geo</Text>
      </Col>
      <Col span={16}>
        <div>
          {" "}
          {data?.geo?.map((item) => (
            <Badge
            key={item}
              count={item}
              style={{
                borderRadius: 6,
                borderColor: "lightgrey",
                backgroundColor: 'white',
                color: 'currentcolor',
                marginRight: 5
              }}
            />
            // <span key={item} style={{ fontSize: 14 }}>
            //   {item}
            //   <br />
            // </span>
          ))}
        </div>
      </Col>
    </Row>)}

    <Row>
      <Col span={8}>
        {data?.popularity && (
          <div style={{ display: "flex", gap: 5 }}>
            <Text style={grayText}>Popularity</Text>
            <Tooltip
              color="#FFFFFF"
              title={
                <span style={{ color: "#4B4F55" }}>
                  Популярность — индекс, определяющий популярность записи
                  (на сколько пользователей повлияла эта запись). Самый
                  популярный индекс&nbsp;5, а наименее популярный&nbsp;1.
                </span>
              }
            >
              <QuestionCircleOutlined style={{fontSize: 14, color: '#7A7D86', cursor: "pointer"}}/>
              {/* <div
                // title={'популярность — индекс, определяющий популярность записи (на сколько пользователей повлияла эта запись). Самый популярный индекс 5, а наименее популярный  1. Записи в фидах сортируются в порядке убывания значений этого поля.'
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 14,
                  height: 14,
                  borderRadius: "50%",
                  border: "1px solid #7A7D86",
                  color: "#7A7D86",
                  cursor: "pointer",
                }}
              >
                ?
              </div> */}
            </Tooltip>
          </div>
        )}

      </Col>
      <Col span={16}>
        {data ? (
          data.popularity === "0" ? (
            <Text>'Нет данных'</Text>
          ) : (
            <Rate disabled value={data?.popularity} />
          )
        ) : (
          // <div style={{display: "flex", alignItems: "flex-end", height: '40px', width: '100%'}}>

          // <div className={styles.gradient}>
          //     <div style={{position: "absolute", top: '-38px', left: `${((data?.popularity -1) * 25)-2}%`, display: "flex", flexDirection: "column", alignItems: "center", color: getColor(data?.popularity) }}>
          //         <span>{data?.popularity}</span><div className={styles.triangle}></div></div></div>
          // </div>
          ""
        )}
      </Col>
    </Row>

    {data?.targeted_organization && (<Row>
      <Col span={8}>
        <Text style={grayText}>Targeted organization</Text>
      </Col>
      <Col span={16}>
        <Badge
          count={data?.targeted_organization}
          style={{
            borderRadius: 6,
            borderColor: "lightgrey",
            backgroundColor: 'white',
            color: 'currentcolor',
            marginRight: 5
          }}
        />
        {/* <Text>{data?.targeted_organization}</Text> */}
      </Col>
    </Row>)}

    {data?.stolen_data_type?.length > 0 && (<Row>
      <Col span={8}>
        <Text style={grayText}>Stolen Data type</Text>
      </Col>
      <Col span={16}>
        <div style={{ fontSize: 14 }}>
          {" "}
          {data?.stolen_data_type?.map((item) => (
            <Badge
              count={item}
              style={{
                borderRadius: 6,
                borderColor: "lightgrey",
                backgroundColor: 'white',
                color: 'currentcolor',
                marginRight: 5
              }}
            />
            // <div key={item + index}>
            //   <span>{item}</span>
            // </div>
          ))}
        </div>
      </Col>
    </Row>)}

    {data?.ip?.length > 0 && (<Row>
      <Col span={8}>
        <Text style={grayText}>IP</Text>
      </Col>
      <Col span={16}>
        <div style={{ fontSize: 14 }}>
          {" "}
          {data?.ip?.map((item) => (
            <Badge
              count={item}
              style={{
                borderRadius: 6,
                borderColor: "lightgrey",
                backgroundColor: 'white',
                color: 'currentcolor',
                marginRight: 5
              }}
            />
            // <span key={item}>
            //   {item}
            //   <br />
            // </span>
          ))}
        </div>
      </Col>
    </Row>)}

    {data?.industry?.length > 0 && (<Row>
      <Col span={8}>
        <Text style={grayText}>Industry</Text>
      </Col>
      <Col span={16}>
        <div style={{ fontSize: 14 }}>
          {" "}
          {data?.industry?.map((item) => (
            <Badge
              count={item}
              style={{
                borderRadius: 6,
                borderColor: "lightgrey",
                backgroundColor: 'white',
                color: 'currentcolor',
                marginRight: 5
              }}
            />
            // <span key={item}>
            //   {item}
            //   <br />
            // </span>
          ))}
        </div>
      </Col>
    </Row>)}

    {data && data?.phishing_kit !== "[]" && (<Row>
      <Col span={8}>

        <Text style={grayText}>Phishing Kit</Text>
      </Col>
      <Col span={16}>
      
        <Badge 
        count={data?.phishing_kit}
        style={{
          borderRadius:6,
          borderColor: "lightgrey",
          backgroundColor: 'white',
          color: 'currentcolor',
          marginRight: 5
        }}
        />
      
      
        {/* <Text>{data?.phishing_kit}</Text> */}
      </Col>
    </Row>)}

    {data?.files?.length > 0 && (<Row>
          <Col span={8}>
            <Text style={grayText}>Files</Text>
          </Col>
          <Col span={16}>
            <div style={{ fontSize: 14 }}>
              {" "}
              {data?.files?.map((item, index) => (
                <div key={index + "files"}>
                  {" "}
                  <span>
                    MD5: <span style={{fontSize: 10}}>{item.MD5}</span>
                    <br />
                  </span>
                  <span>
                    SHA1: <span style={{fontSize: 10}}>{item.SHA1}</span>
                    <br />
                  </span>
                  <span>
                    SHA256: <span style={{fontSize: 10}}>{item.SHA256}</span>
                    <br />
                  </span>
                  <span>
                    Threat: <span style={{fontSize: 10}}>{item.threat}</span>
                    <br />
                  </span>
                </div>
              ))}
            </div>
          </Col>
        </Row>)}

  </div>)
  }
</Drawer >
);
}

export default UrlItemModal;
