import React from "react";
import { Button, Modal, Typography } from "antd";
import { deleteEdlInstance, switchDeleteSuccess } from "../../store/edl";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../store/auth/selectors";
import { getEdlSuccessDelete } from "../../store/edl/selectors";

function ModalConfirmInctance({ open, close, item, list }) {
  const dispatch = useDispatch();
  const isSuccessDelete = useSelector(getEdlSuccessDelete);
  const user = useSelector(getUser);

  const handleActivateDelete = () => {
    dispatch(
      deleteEdlInstance({
        idList: list,
        idInstance: item.id,
        userUpdated: user,
      })
    );
  };
  const handleClose = () => {
    dispatch(switchDeleteSuccess());
    close();
  };

  return (
    <Modal open={open} closable={false} footer={null} centered>
      {/* <Button
				onClick={close}
				icon={<IconClose style={{ width: 12, height: 12 }} />}
				type="ghost"
				style={{
					position: "absolute",
					right: 16,
					top: 16,
					width: 32,
					height: 32,
					backgroundColor: "#F6F8FD",
					padding: 0,
				}}
			/> */}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          flexDirection: "column",
          gap: 24,
        }}
      >
        {!isSuccessDelete && (
          <Typography.Title level={3}>
            {"Вы уверены, что хотите удалить запись?"}
          </Typography.Title>
        )}
        {isSuccessDelete && (
          <Typography.Title level={3}>Запись успешно удалена!</Typography.Title>
        )}
        {!isSuccessDelete && (
          <div
            style={{
              display: "flex",
              gap: 24,
            }}
          >
            <Button onClick={close} style={{ flexGrow: 1 }} htmlType="reset">
              Отмена
            </Button>
            <Button
              // onClick={() => {
              // 	close();
              // 	setModalSuccessOpen(true);
              // }}
              style={{ flexGrow: 1, background: "#2E496F" }}
              type="primary"
              onClick={() => handleActivateDelete()}
            >
              {"Удалить"}
            </Button>
          </div>
        )}
        {isSuccessDelete && (
          <Button
            // onClick={() => {
            // 	close();
            // 	setModalSuccessOpen(true);
            // }}
            style={{ flexGrow: 1, background: "#2E496F" }}
            type="primary"
            onClick={() => {
              handleClose();
            }}
          >
            {"Закрыть"}
          </Button>
        )}
      </div>
    </Modal>
  );
}

export default ModalConfirmInctance;
