import React, { useEffect } from "react";
import { Button, Card, Modal, Space, Typography } from "antd";
import { IconClose } from "../../icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getValidatorApplySuccess,
  getValidatorData,
} from "../../store/edl/selectors";
import {
  applyEdlListValidators,
  switchValidateApplySuccess,
} from "../../store/edl";
import { getUser } from "../../store/auth/selectors";

function ModalCheckValidate({ open, close, name, validators, idList }) {
  const data = useSelector(getValidatorData);
  const userData = useSelector(getUser);
  const dispatch = useDispatch();
  const successValidate = useSelector(getValidatorApplySuccess);

  useEffect(() => {
    if (successValidate === true) {
      dispatch(switchValidateApplySuccess());
      close();
    }
  }, [successValidate]);
  const applyValidateChanges = () => {
    let body = {};
    if (userData?.first_name || userData?.last_name)
      body.updated_by = `${userData?.first_name} ${userData?.last_name}`;
    else body.updated_by = userData?.email;
    body.values_to_delete = data?.values_to_delete || [];
    body.validators = validators;
    dispatch(applyEdlListValidators({ idList, body }));
  };

  const changeMessage = () => {
    const arr = data.message.split("\n");
    return arr?.map((item) => <div key={item}>{item}</div>);
  };
  return (
    <Modal open={open} closable={false} footer={null} centered>
      <Button
        onClick={close}
        icon={<IconClose style={{ width: 12, height: 12 }} />}
        type="ghost"
        style={{
          position: "absolute",
          right: 16,
          top: 16,
          width: 32,
          height: 32,
          backgroundColor: "#F6F8FD",
          padding: 0,
        }}
      />
      <Typography.Title level={2} style={{ marginBottom: "10px" }}>
        {name}
      </Typography.Title>
      <Card style={{ border: "1px solid #91caff", background: "#e6f4ff" }}>
        <Typography.Title level={5} style={{ marginBottom: "10px" }}>
          Проверка успешно завершена!
        </Typography.Title>
        {changeMessage()}
      </Card>
      <Space style={{ marginTop: "20px" }}>
        <Button
          type="primary"
          onClick={close}
          style={{
            width: "168px",
            borderRadius: 4,
            fontSize: 14,
            border: "1px solid #CED9EE",
            background: "transparent",
            color: " #2E496F",
          }}
        >
          Отменить
        </Button>
        <Button
          type="primary"
          onClick={applyValidateChanges}
          //disabled={isLoading || fileError ? true : false}
          style={{
            width: "168px",
            borderRadius: 4,
            fontSize: 14,
            background: "#2E496F",
          }}
        >
          Применить
        </Button>
      </Space>
    </Modal>
  );
}

export default ModalCheckValidate;
