import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";
import { logout } from "../auth";

const checkFilters = (filters, page) => {
    if (!filters) filters = {};
    filters.page = page;
    return filters;
};
export const getOrgAssets = createAsyncThunk(
    "getOrgAssets",
    async ({ page, filters }, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await axiosInstance.get(`api/v1/assets/`, {
                params: checkFilters(filters, page),
            });
            return data;
        } catch (err) {
            if (err?.response.status === 401) dispatch(logout());
            else return rejectWithValue(err.response.data);
        }
    }
);

export const getAssetDetail = createAsyncThunk("getAssetDetail", async (id) => {
    const { data } = await axiosInstance.get(`api/v1/assets/${id}/`);
    return data;
});
export const getLastUpdateValue = createAsyncThunk(
    "getLastUpdateValue",
    async (data, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await axiosInstance.get(
                `/api/v1/celery-tracker/?name=update_assets_device_info`,
                {}
            );
            return data;
        } catch (err) {
            if (err?.response.status === 401) dispatch(logout());
            else return rejectWithValue(err.response.data);
        }
    }
);

export const getCities = createAsyncThunk(
    "getCities",
    async (_, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await axiosInstance.get(
                `api/v1/assets/cities/`,
            );
            return data;
        } catch (err) {
            if (err?.response.status === 401) dispatch(logout());
            else return rejectWithValue(err.response.data);
        }
    }
);
export const getPlatforms = createAsyncThunk(
    "getPlatforms",
    async (_, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await axiosInstance.get(
                `api/v1/assets/platforms/`,
            );
            return data;
        } catch (err) {
            if (err?.response.status === 401) dispatch(logout());
            else return rejectWithValue(err.response.data);
        }
    }
);
export const getOrganisations = createAsyncThunk(
    "getOrganisations",
    async (_, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await axiosInstance.get(
                `api/v1/assets/organisations/`,
            );
            return data;
        } catch (err) {
            if (err?.response.status === 401) dispatch(logout());
            else return rejectWithValue(err.response.data);
        }
    }
);
const initialState = {
    user: null,
    cities: null,
    platforms: null,
    organisations: null,
    assets: null,
    lastUpdate: null,
    assetsCount: 0,
    assetsNextPage: null,
    assetDetail: null,
    errors: null,
    loaders: {
        common: true,
        organisation: false,
    },
};

const organisationSlice = createSlice({
    name: "organisation",
    initialState,
    reducers: {
        timeout: (state) => {},
        resetOrg: (state)=> {state.assets = null; state.user = null; }
    },
    extraReducers: (builder) => {
        //getOrgAssets
        builder.addCase(getOrgAssets.pending, (state) => {
            state.loaders.common = true;
        });
        builder.addCase(
            getOrgAssets.fulfilled,
            (state, { payload, dispatch }) => {
                state.assets = payload;
                state.loaders.common = false;
            }
        );
        builder.addCase(getOrgAssets.rejected, (state, action) => {
            state.loaders.common = false;
            state.errors = action.payload;
        });
        //getAssetDetail
        builder.addCase(getAssetDetail.pending, (state) => {
            state.loaders.common = true;
        });
        builder.addCase(getAssetDetail.fulfilled, (state, { payload }) => {
            state.assetDetail = payload;
            state.loaders.common = false;
        });
        builder.addCase(getAssetDetail.rejected, (state, action) => {
            state.errors = action.payload;
            state.loaders.common = false;
        });
        //getLastUpdateValue
        builder.addCase(getLastUpdateValue.pending, (state) => {
            state.loaders.common = true;
        });
        builder.addCase(getLastUpdateValue.fulfilled, (state, { payload }) => {
            state.lastUpdate = payload?.results[0].executed_at;

            state.loaders.common = false;
        });
        builder.addCase(getLastUpdateValue.rejected, (state, action) => {
            state.errors = action.payload;
            state.loaders.common = false;
        });
        //getCities
        builder.addCase(getCities.pending, (state) => {
            state.loaders.common = true;
        });
        builder.addCase(getCities.fulfilled, (state, { payload, dispatch }) => {
            state.cities = payload;
            state.loaders.common = false;
        });
        builder.addCase(getCities.rejected, (state, action) => {
            state.loaders.common = false;
            state.errors = action.payload;
        });
        //getPlatforms
        builder.addCase(getPlatforms.pending, (state) => {
            state.loaders.common = true;
        });
        builder.addCase(
            getPlatforms.fulfilled,
            (state, { payload, dispatch }) => {
                state.platforms = payload;
                state.loaders.common = false;
            }
        );
        builder.addCase(getPlatforms.rejected, (state, action) => {
            state.loaders.common = false;
            state.errors = action.payload;
        });
        //getOrganisations
        builder.addCase(getOrganisations.pending, (state) => {
            state.loaders.common = true;
        });
        builder.addCase(
            getOrganisations.fulfilled,
            (state, { payload, dispatch }) => {
                state.organisations = payload;
                state.loaders.common = false;
            }
        );
        builder.addCase(getOrganisations.rejected, (state, action) => {
            state.loaders.common = false;
            state.errors = action.payload;
        });
    },
});
export const { timeout, resetOrg } = organisationSlice.actions;
export default organisationSlice.reducer;
