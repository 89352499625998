import React from 'react'
import { Typography } from 'antd'
import './eula.css'
const { Title } = Typography;


export default function Eula() {
    return (
        <>
            <Title level={3} style={{ marginBottom: 30 }}>
                Лицензионное соглашение
            </Title>
            <div>
                <Title level={5}>Термины: </Title>
                <ul className='eula-list'>
                    <li><strong>«Правообладатель»</strong> — ООО «СТАЙЛ ТЕЛЕКОМ» ИНН 7722800217; ОГРН: 1137746125065; Юридический адрес: 111033, г. Москва, ул. Самокатная дом 4А, строение 5, этаж 2, пом. III, ком. 6</li>
                    <li><strong>«Конечный пользователь»</strong> — юридическое лицо, которое приобрело право использования Программного обеспечения и осуществляющее его использование.</li>
                    <li><strong>«Программное обеспечение/ПО»</strong> — «Автоматизированная информационная система PARUS SECURITY CLOUD» реестровая запись №2024612718 от «05» февраля 2024 г, исключительные права на которое принадлежат Правообладателю.</li>
                </ul>

                <Title level={5}>1. Общие положения</Title>
                <ul className='eula-list'>
                    <li>1.1. Настоящее лицензионное соглашение с конечным пользователем (далее — «Соглашение») является юридическим документом, заключаемым между Конечным
                        пользователем и Правообладателем в
                        отношении порядка использования Программного обеспечения.</li>
                    <li>1.2. Условия настоящего Соглашения распространяются на Программное обеспечение, указанное в настоящем Соглашении.</li>
                    <li>1.3. Если Конечный пользователь не согласен принять на себя условия настоящего Соглашения, Конечный пользователь не имеет права использовать ПО.</li>
                    <li>1.4. Осуществляя действия по использованию ПО, Конечный пользователь подтверждает свое полное и безоговорочное согласие со всеми условиями настоящего Соглашения и
                        обязуется их соблюдать в соответствии с условиями и требованиям ст. 428 Гражданского кодекса РФ.</li>
                    <li>1.5. Лицо, осуществляющее акцепт настоящего Соглашения, подтверждает Правообладателю, что обладает необходимыми полномочиями по акцептованию положений
                        настоящего Соглашения.</li>
                </ul>

                <Title level={5}>2. Права использования</Title>
                <ul className='eula-list'>
                    <li>2.1 Конечный пользователь имеет право использовать ПО на правах простой (неисключительной) лицензии с момента получения доступа к ПО, приобретенного у
                        Правообладателя
                        или у официального партнера, дистрибьютора в течение срока действия приобретенной лицензии.</li>
                    <li>2.2. По настоящему Соглашению права на новые версии ПО не предоставляются Конечному пользователю в рамках приобретённой лицензии, кроме случаев если обновления и
                        изменения ПО
                        осуществляются по инициативе Правообладателя в целях устранения ошибок функционирования приобретенного ПО.</li>
                    <li>2.3. Односторонний отказ Конечного пользователя, которому были переданы права, от приобретенного права использования Программного обеспечения законом не предусмотрен,
                        в связи с чем
                        не подлежат возврату приобретенные лицензии, возврат денежных средств за них не предусмотрен.</li>
                    <li>2.4. Правообладатель предоставляет гарантийную поддержку программного обеспечения на срок 365 календарных дней с даты передачи прав на ПО.</li>
                </ul>

                <Title level={5}>3. Надлежащее использование</Title>
                <ul className='eula-list'>
                    <li>3.1. Настоящее Соглашение предоставляет Конечному пользователю право доступа к ПО, в рамках функциональных возможностей, в
                        соответствии с назначением и правилами надлежащего использования ПО, указанными в настоящем Соглашении.</li>
                    <li>3.2. Конечный пользователь обязуется не модифицировать, адаптировать, выполнять обратное проектирование, декомпилировать, дизассемблировать, расшифровывать,
                        копировать, создавать
                        производные продукты, транслировать ПО или документацию, или в ином порядке пытаться извлечь исходный код ПО, не вносить какие-либо изменения в объектный код ПО, в
                        том числе не
                        поручать иным лицам осуществлять указанные действия без согласия Правообладателя.</li>
                    <li>3.3. Конечный пользователь не имеет права использовать Программное обеспечение для предоставления услуг третьим лицам (включая консультации), в том числе на
                        безвозмездной основе,
                        сдавать в аренду, передавать третьим лицам, тиражировать Программное обеспечение, а также использовать его в мошеннических или иных противозаконных целях.</li>
                    <li>3.4. При нарушении Конечным пользователем условий Соглашения, порядка надлежащего использования ПО, Правообладатель вправе прекратить и/или отказать в
                        предоставлении прав на
                        использование ПО без возмещения стоимости приобретенного права использования ПО и каких-либо убытков, денежных компенсаций.</li>
                </ul>

                <Title level={5}>4. Авторское право</Title>
                <ul className='eula-list'>
                    <li>4.1. Исключительные права на Программное обеспечение принадлежат Правообладателю и защищены действующим законодательством Российской Федерации.</li>
                    <li>4.2. Конечный пользователь обязуется не совершать действия, нарушающие интеллектуальные права Правообладателя.</li>
                    <li>4.3. Пользователь не вправе удалять или изменять имеющиеся в Программном обеспечении сведения об авторских правах Правообладателя.</li>
                </ul>

                <Title level={5}>5. Ограниченная гарантия</Title>
                <ul className='eula-list'>
                    <li>5.1. Программное обеспечение предоставляется на условиях «как есть». Правообладатель не предоставляет никаких гарантий в отношении безошибочной и бесперебойной
                        работы Программного
                        обеспечения, а также соответствия Программного обеспечения конкретным целям и ожиданиям Конечного пользователя.</li>
                    <li>5.2. Правообладатель ни при каких обстоятельствах не несет ответственности перед Конечными пользователями за фактические, косвенные и второстепенные убытки, включая в
                        числе прочего
                        убытки в результате: потери прибыли, потери доходов, потери и получения доступа к конфиденциальной информации, персональных данных и иной информации, прерывания
                        деятельности, в
                        результате любых других материальных или иных убытков, возникающих в связи с использованием или невозможностью использования ПО или документации, а также при
                        использовании стороннего
                        программного обеспечения, предоставляемого третьими лицами.</li>
                    <li>5.3. Правообладатель не несет ответственность за функционирование операционных систем, браузеров и сторонних системных компонентов, используемых для доступа к
                        программному обеспечению.
                        Конечный пользователь самостоятельно осуществляет поддержку функционирования операционных систем, браузеров и сторонних системных компонентов, обладая
                        соответствующими
                        правами на их использование, выполняя организацию обновления и обеспечивая мероприятия безопасности, а также используя иные необходимые для этого способы.</li>
                    <li>5.4. Конечный пользователь гарантирует, что является владельцем любых документов, копий документов и информации, обрабатываемых при использовании ПО или имеет право
                        на такое
                        использование, и несет полную ответственность за их обработку, использование и хранение.</li>
                </ul>

                <Title level={5}>6. Ответственность</Title>
                <ul className='eula-list'>
                    <li>6.1. Пользователь несет ответственность за нарушение условий Соглашения, а также за неправомерное использование Программного обеспечения в соответствии с действующим
                        законодательством Российской Федерации.</li>
                    <li>6.2. Правообладатель оставляет за собой право незамедлительно в одностороннем порядке без уведомления прекратить действие лицензии Пользователя в случае нарушения
                        условий Соглашения,
                        а также в случае выявления неправомерного использования Программного обеспечения.</li>
                </ul>

                <Title level={5}>7. Иные положения</Title>
                <ul className='eula-list'>
                    <li>7.1. Правообладатель оставляет за собой право изменять условия Соглашения, уведомляя при этом Пользователя. Для целей настоящего пункта надлежащим уведомлением
                        является размещение
                        изменённых условий Соглашения на сайте Правообладателя. Указанные изменения в условиях Соглашения вступают в силу с даты их публикации на сайте и распространяются
                        на всех Конечных
                        пользователей.</li>
                    <li>7.2. Принимая настоящее Соглашение, Конечный пользователь подтверждает свое согласие на то, что в ходе своей работы ПО может отправлять Правообладателю информацию
                        (в том числе, но не
                        ограничиваясь) об ошибках, конфликтах, а также включает в себя возможность сбора статистической информации, осуществляемого Правообладателем с целью анализа
                        использования, а также
                        совершенствования ПО.</li>
                    <li>7.3. Принимая настоящее Соглашение, Конечный пользователь подтверждает свое согласие на то, что Правообладатель вправе публиковать и использовать информацию о
                        Конечном пользователе в
                        маркетинговых целях, которая включает в себя информацию о факте использования программного обеспечения Правообладателя, наименование лица и логотип (товарный знак)
                        на безвозмездной
                        основе.</li>
                    <li>7.4. В случае возникновения споров и разногласии между Правообладателем и Конечным пользователем, применяется обязательный претензионный порядок урегулирования
                        спора. Срок ответа на
                        претензию составляет 14 (четырнадцать) рабочих дней с момента, когда претензия была получена. В случае полного или частичного отказа в удовлетворении претензии или
                        неполучения
                        направившей претензию Стороной в срок ответа на претензию, претензионный порядок урегулирования споров считается соблюденным. Споры и разногласия подлежат
                        рассмотрению Арбитражным
                        судом г. Москвы.</li>
                    <li>7.5. Настоящее Соглашение распространяет свое действие на весь период использования ПО, предусмотренный в Договоре.</li>
                </ul>
                <ul className='company-info'>
                    <li> ООО «Стайл Телеком»</li>
                    <li>Юридический адрес: 111033, г. Москва, ул. Самокатная дом 4А, строение 5, этаж 2, пом. III, ком. 6</li>
                    <li>Фактический адрес: 111033, г. Москва, ул. Самокатная дом 4А, строение 5, этаж 2, пом. III, ком. 6</li>
                    <li>ИНН 7722800217 / КПП 772201001</li>
                    <li>ОГРН: 1137746125065</li>
                </ul>
            </div>
        </>

    )
}

