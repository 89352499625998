import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth";
import urlFilterSlice from "./urlfilter";
import threatVaultSlice from "./threatvault";
import edlSlice from "./edl";
import organisationSlice from "./organisation";
import sidebarSlice from "./sidebar";
import sandboxSlice from "./sandbox";
import documentationSlice from "./documentation";
import distributionsSlice from "./distributions";
const rootReducer = combineReducers({
  auth: authSlice,
  urlfilter: urlFilterSlice,
  threatvault: threatVaultSlice,
  edl: edlSlice,
  organisation: organisationSlice,
  sidebar: sidebarSlice,
  documentation: documentationSlice,
  sandbox: sandboxSlice,
  distributions: distributionsSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
