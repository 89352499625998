import React from "react";
import PasswordRecoverNew from "../components/UI/PasswordRecover/PasswordRecoverNew";

function PasswordRecoverPage() {
  return (

  // <PasswordRecover/>
  <PasswordRecoverNew/>
  );
}

export default PasswordRecoverPage;