import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../utils/axios";
import { logout } from "../auth";

const typeFileApp = (value) => {
  let type = "";
  switch (value) {
    case "exe":
      type = "application/vnd.microsoft.portable-executable";
      break;
    case "rpm":
      type = "application/x-rpm";
      break;
    case "deb":
      type = "application/vnd.debian.binary-package";
      break;
      case "msi":
        type = "application/x-msdownload";
        break;
        case 'txt': type = ''; break;
        case 'zip': type = 'application/zip'; break;
        case 'xlsx': type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'; break;
        case 'xls': type = 'application/vnd.ms-excel'; break;
        case 'doc': type = 'application/msword'; break;
        case 'docx': type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; break;
    default:
      type = `application/${value}`;
  }
  return type;
};

export const getDocumentsList = createAsyncThunk(
  "getDocumentsList",
  async ({ page, pagination }, { rejectWithValue, dispatch }) => {
    try {
      const limit = 15;

      const { data } = await axiosInstance.get(`/api/v1/pim/docs/`, {
        params: {
          page: page,
          limit: limit,
        },
      });
      return { data, pagination };
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      else return rejectWithValue(err.response.data);
    }
  }
);

export const getDistributionsServerList = createAsyncThunk(
  "getDistributionsServerList",
  async ({ page, pagination }, { rejectWithValue, dispatch }) => {
    try {
      const limit = 10;
      const { data } = await axiosInstance.get(`/api/v1/pim/server-release/`, {
        params: { page: page, limit: limit },
      });
      return { data, pagination };
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      else return rejectWithValue(err.response.data);
    }
  }
);

export const getDistributionsAgentList = createAsyncThunk(
  "getDistributionsAgentList",
  async ({ page, pagination }, { rejectWithValue, dispatch }) => {
    try {
      const limit = 10;
      const { data } = await axiosInstance.get(`/api/v1/pim/agent-release/`, {
        params: { page: page, limit: limit },
      });
      return { data, pagination };
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      else return rejectWithValue(err.response.data);
    }
  }
);

export const getDistributionsCompatibilityList = createAsyncThunk(
  "getDistributionsCompatibilityList",
  async ({ page, pagination }, { rejectWithValue, dispatch }) => {
    try {
      const limit = 15;
      const { data } = await axiosInstance.get(`/api/v1/pim/compatibility/`, {
        params: {
          page: page,
          limit: limit,
        },
      });
      return { data, pagination };
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      else return rejectWithValue(err.response.data);
    }
  }
);
export const getDistributionsServerDetails = createAsyncThunk(
  "getDistributionsServerDetails",
  async (release_id, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/v1/pim/server-release/${release_id}/`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      else return rejectWithValue(err.response.data);
    }
  }
);
export const getDistributionsAgentDetails = createAsyncThunk(
  "getDistributionsAgentDetails",
  async (release_id, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.get(
        `/api/v1/pim/agent-release/${release_id}/`
      );
      return data;
    } catch (err) {
      if (err.response.status === 401) dispatch(logout());
      else return rejectWithValue(err.response.data);
    }
  }
);

export const getPimDocDownload = createAsyncThunk(
  "getPimDocDownload",
  async ({ docId, name, typeFile }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.get(`/api/v1/pim/docs/${docId}/download/`, 
     {
        responseType: "arraybuffer",
      });
      return { data, typeFile, name };
    } catch (err) {
     
      if (err.response.status === 401) dispatch(logout());
      else return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  download: {
    agents: {progress: 0, openModal: false}, 
    servers: {progress: 0, openModal: false}, 
  },
  documentsData: null,
  distributionsServerData: null,
  distributionsAgentData: null,
  distributionsCompatibilityData: null,
  distributionsServerDetails: null,
  distributionsAgentDetails: null,
  errors: null,
  nextPage: {
    nextPageDoc: 1,
    nextPageCompatibility: 1,
    nextPageAgent: null,
    nextPageServer: null,
  },
  loaders: {
    common: false,
    commonServer: false,
    commonAgent: false,
    docs: false,
    commonCompatibility: false,
    details: false
  },
};

const distributionsSlice = createSlice({
  name: "distributions",
  initialState,
  reducers: {
    resetData: (state) => {
      state.documentsData = null;
    },
    resetDataAllNextPages: (state) => {
      state.nextPage.nextPageDoc = null;
      state.nextPage.nextPageCompatibility = null;
      state.nextPage.nextPageAgent = null;
      state.nextPage.nextPageServer = null;
    },
    agentsDownload: (state, { payload }) => {
      state.download.agents.progress = payload.progress;
      state.download.agents.openModal = payload.openModal;
      state.download.agents.release = payload.release
    },
    serversDownload: (state, { payload }) => {
      state.download.servers.progress = payload.progress;
      state.download.servers.openModal = payload.openModal;
      state.download.servers.release = payload.release
    },
  },

  extraReducers: (builder) => {
    //getDocumentsList
    builder.addCase(getDocumentsList.pending, (state) => {
      state.loaders.docs = true;
    });
    builder.addCase(getDocumentsList.fulfilled, (state, { payload }) => {
      if (payload.pagination === true) {
        state.documentsData?.push(...payload?.data?.results);
      } else state.documentsData = payload?.data?.results;
      if (payload?.data?.next) {
        state.nextPage.nextPageDoc = !payload?.data?.previous? 2: state.nextPage.nextPageDoc + 1;;
      } else {
        state.nextPage.nextPageDoc = null;
      }
      state.loaders.docs = false;
      state.errors = null;
    });
    builder.addCase(getDocumentsList.rejected, (state, action) => {
      state.loaders.docs = false;
      state.errors = action.payload?.detail
        ? action.payload?.detail
        : action.payload?.error;
    });
    //getDistributionsServerList
    builder.addCase(getDistributionsServerList.pending, (state) => {
      state.loaders.commonServer = true;
    });
    builder.addCase(
      getDistributionsServerList.fulfilled,
      (state, { payload }) => {
        if (payload.pagination === true) {
          state.distributionsServerData?.push(...payload?.data?.results);
        } else state.distributionsServerData = payload?.data?.results;
        if (payload?.data?.next) {
          state.nextPage.nextPageServer = !payload?.data?.previous? 2: state.nextPage.nextPageServer + 1;;
        } else {
          state.nextPage.nextPageServer = null;
        }
        state.loaders.commonServer = false;
        state.errors = null;
      }
    );
    builder.addCase(getDistributionsServerList.rejected, (state, action) => {
      state.loaders.commonServer = false;
      state.errors = action.payload?.detail
        ? action.payload?.detail
        : action.payload?.error;
    });
    //getDistributionsAgentList
    builder.addCase(getDistributionsAgentList.pending, (state) => {
      state.loaders.commonAgent = true;
    });
    builder.addCase(
      getDistributionsAgentList.fulfilled,
      (state, { payload }) => {
        if (payload.pagination === true) {
          state.distributionsAgentData?.push(...payload?.data?.results);
        } else state.distributionsAgentData = payload?.data?.results;
        if (payload?.data?.next) {

          state.nextPage.nextPageAgent = !payload?.data?.previous? 2: state.nextPage.nextPageAgent + 1;
        } else {
          state.nextPage.nextPageAgent = null;
        }
        state.loaders.commonAgent = false;
      }
    );
    builder.addCase(getDistributionsAgentList.rejected, (state, action) => {
      state.loaders.commonAgent = false;
      state.errors = action.payload?.detail
        ? action.payload?.detail
        : action.payload?.error;
    });
    //getDistributionsCompatibilityList
    builder.addCase(getDistributionsCompatibilityList.pending, (state) => {
      state.loaders.commonCompatibility = true;
    });
    builder.addCase(
      getDistributionsCompatibilityList.fulfilled,
      (state, { payload }) => {
        if (payload.pagination === true) {
          state.distributionsCompatibilityData?.push(...payload?.data?.results);
        } else state.distributionsCompatibilityData = payload?.data?.results;
        if (payload?.data?.next) {
          state.nextPage.nextPageCompatibility =
          !payload?.data?.previous? 2: state.nextPage.nextPageCompatibility + 1;
        } else {
          state.nextPage.nextPageCompatibility = null;
        }
        state.loaders.commonCompatibility = false;
      }
    );
    builder.addCase(
      getDistributionsCompatibilityList.rejected,
      (state, action) => {
        state.loaders.commonCompatibility = false;
        state.errors = action.payload?.detail
          ? action.payload?.detail
          : action.payload?.error;
      }
    );
    //getDistributionsServerDetails
    builder.addCase(getDistributionsServerDetails.pending, (state) => {
      state.loaders.details = true;
    });
    builder.addCase(
      getDistributionsServerDetails.fulfilled,
      (state, { payload }) => {
        state.distributionsServerDetails = payload;
        state.loaders.details = false;
        state.errors = null;
      }
    );
    builder.addCase(getDistributionsServerDetails.rejected, (state, action) => {
      state.loaders.details = false;
      state.errors = action.payload?.detail
        ? action.payload?.detail
        : action.payload?.error;
    });
    //getDistributionsAgentDetails
    builder.addCase(getDistributionsAgentDetails.pending, (state) => {
      state.loaders.details = true;
    });
    builder.addCase(
      getDistributionsAgentDetails.fulfilled,
      (state, { payload }) => {
        state.distributionsAgentDetails = payload;
        state.loaders.details = false;
        state.errors = null;
      }
    );
    builder.addCase(getDistributionsAgentDetails.rejected, (state, action) => {
      state.loaders.details = false;
      state.errors = action.payload?.detail
        ? action.payload?.detail
        : action.payload?.error;
    });
    //getPimDocDownload
    builder.addCase(getPimDocDownload.pending, (state) => {
      state.loaders.docs = true;
    });
        builder.addCase(getPimDocDownload.fulfilled, (state, { payload }) => {
          state.loaders.docs = false;
          const blob = new Blob([payload.data], { type: typeFileApp(payload.typeFile) });
          // const blob = new Blob([payload.data], { type: `application/${payload.typeFile}` });
          const fileUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
        link.setAttribute('href', fileUrl)
        link.setAttribute('download', `${payload.name}`)
        link.style.display = 'none'
    
        document.body.appendChild(link)
    
        link.click()
    
        document.body.removeChild(link)
      }
    );
    builder.addCase(getPimDocDownload.rejected, (state, action) => {
      state.loaders.docs = false;
      state.errors =
        action.payload.detail || action.payload.message || "Ошибка сервера";
      // state.loaders.external = false;
    });
  },
});
export const { resetData, resetDataAllNextPages, agentsDownload, serversDownload } = distributionsSlice.actions;
export default distributionsSlice.reducer;
