import React from "react"
import Icon from '@ant-design/icons';

function IconSvg() {
  return (


    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ stroke: "currentcolor" }} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5374_41534)">
        <path d="M18 13.7142C18.004 12.6444 17.7218 11.5929 17.1828 10.6688C16.6438 9.74466 15.8675 8.98145 14.9343 8.45824C14.0011 7.93503 12.945 7.67085 11.8754 7.69307C10.8058 7.71528 9.76156 8.02309 8.85091 8.5846C7.94026 9.14611 7.19631 9.94089 6.69613 10.8866C6.19594 11.8323 5.95773 12.8946 6.00615 13.9634C6.05457 15.0321 6.38788 16.0685 6.97154 16.9651C7.5552 17.8617 8.36798 18.5859 9.32567 19.0628V22.0114C9.33014 22.1857 9.40255 22.3514 9.52745 22.4731C9.65235 22.5948 9.81986 22.6629 9.99424 22.6628H14.0057C14.1801 22.6629 14.3476 22.5948 14.4725 22.4731C14.5974 22.3514 14.6698 22.1857 14.6742 22.0114V19.0114C15.6621 18.5196 16.4952 17.765 17.0819 16.8304C17.6687 15.8959 17.9863 14.8176 18 13.7142V13.7142Z" style={{ stroke: "currentcolor" }} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 1.38855V3.95998" style={{ stroke: "currentcolor" }} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18.8572 3.83997L17.04 5.67425" style={{ stroke: "currentcolor" }} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M22.7658 9.1543H20.1943" style={{ stroke: "currentcolor" }} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.14258 3.83997L6.95972 5.67425" style={{ stroke: "currentcolor" }} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.23438 9.1543H3.8058" style={{ stroke: "currentcolor" }} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_5374_41534">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function IconDocs({ direction, style, ...props }) {
  return <Icon component={IconSvg} {...props} style={{
    ...style, transform: {
      "top": "rotate(90deg)",
      "right": "rotate(180deg)",
      "left": "",
      "bottom": "rotate(-90deg)",
    }[direction]
  }} />
}
