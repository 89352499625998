import React, { useState } from "react";
import { Space, Tabs, Typography, message } from "antd";
import WithoutAuthorization from "../../components/WithoutAuthorization";
import styles from "./UrlFilterNew.module.css";
import CheckUrlNew from "./CheckUrlNew";
import Api from "./Api";
import Documentation from "./Documentation";
import UrlAssets from "./UrlAssets";
import UrlKfeed from "./UrlKfeed";
import kasperImage from "../../icons/kaspersky.svg";
const { Title } = Typography;

function UrlFilterAreaNew({ permissions }) {
  const [, contextHolder] = message.useMessage();

  const [selectedTab, setSelectedTab] = useState(tabs[0].key);

  message.config({ duration: 5 });

  return (
    <>
      {(permissions?.full_access || permissions?.read_access) && (
        <Space direction="vertical" size={20} style={{ width: "100%" }}>
          {contextHolder}
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Title level={3} style={{ margin: 0 }}>
              URL FILTERING
            </Title>{" "}
            <div style={{ display: "flex", alignItems: "center" }}>
              <span className={styles.bannerTitle}>Powered by</span>
              <img className={styles.banner} src={kasperImage} />
            </div>
          </div>
          <Tabs
            items={tabs}
            activeKey={selectedTab}
            className={styles.tabs}
            onChange={(key) => setSelectedTab(key)}
          />
          {selectedTab === "assets" && (
            <UrlAssets
              data-testid='url-assets'
              setSelectedTab={setSelectedTab}
              permissions={permissions}
            />
          )}
          {selectedTab === "checkUrl" && <CheckUrlNew data-testid='check-url-new'/>}
          {selectedTab === "kfeed" && <UrlKfeed data-testid='url-kfeed'/>}
          {selectedTab === "api" && <Api data-testid='api'/>}
          {selectedTab === "documentation" && <Documentation data-testid='documentation'/>}
        </Space>
      )}
      {permissions?.full_access === false &&
        permissions?.read_access === false && <WithoutAuthorization />}
    </>
  );
}

const tabs = [
  { key: "assets", label: "Устройства" },
  { key: "checkUrl", label: "Check URL" },
  { key: "kfeed", label: "KFEED" },
  { key: "api", label: "API" },
  { key: "documentation", label: "Документация" },
];

export default UrlFilterAreaNew;
