import React from "react"
import Icon from '@ant-design/icons';

function IconSvg()
{
  return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon Frame">
      <circle id="Ellipse 7753" cx="10" cy="10" r="9.5" stroke="#EB5757" />
      <rect id="Rectangle 89" x="10" y="13.1667" width="0.00892857" height="0.00892857" stroke="#EB5757" strokeLinejoin="round" />
      <path id="Vector 26" d="M10 9.83325L10 6.49992" stroke="#EB5757" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  </svg>
  )
}

export function IconError(props)
{
  return <Icon component={IconSvg} {...props} />
}



