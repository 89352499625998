import React from "react";
import { Drawer } from "antd";
import { useSelector } from "react-redux";
import { getIsSuccessChangePassword } from "../../../store/auth/selectors";
import SetPasswordForm from "./SetPasswordForm";
import SetPasswordSuccess from "./SetPasswordSuccess";

function SetPasswordModal({ open, close }) {
  const isSuccess = useSelector(getIsSuccessChangePassword);

  return (
    <Drawer
      open={open}
      onClose={close}
      width={488}
      styles={{ header: { display: "none" } }}
      maskClosable={false}
    >
      {!isSuccess && <SetPasswordForm close={close} />}
      {isSuccess && <SetPasswordSuccess close={close} />}
    </Drawer>
  );
}

export default SetPasswordModal;
