import React from "react"
import Icon from '@ant-design/icons';

function IconSvg() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="plus">
        <path id="Vector" d="M13.6667 8.33333H3M8.33333 3V13.6667" stroke="#327FEF" strokeLinecap="round" />
      </g>
    </svg>
  )
}

export function IconPlus(props) {
  return <Icon component={IconSvg} {...props} />
}

