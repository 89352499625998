import React from "react"
import Icon from '@ant-design/icons';

function IconSvg()
{
  return (<svg width="40" height="40" viewBox="0 0 40 40" style={{ fill: "currentcolor" }} xmlns="http://www.w3.org/2000/svg">
    <g id="browser 1" clipPath="url(#clip0_5445_17646)">
      <path id="Vector" d="M35.625 4.375C35.9702 4.375 36.25 4.09518 36.25 3.75C36.25 3.40482 35.9702 3.125 35.625 3.125C35.2798 3.125 35 3.40482 35 3.75C35 4.09518 35.2798 4.375 35.625 4.375Z" />
      <path id="Vector_2" d="M33.125 4.375C33.4702 4.375 33.75 4.09518 33.75 3.75C33.75 3.40482 33.4702 3.125 33.125 3.125C32.7798 3.125 32.5 3.40482 32.5 3.75C32.5 4.09518 32.7798 4.375 33.125 4.375Z" />
      <path id="Vector_3" d="M30.625 4.375C30.9702 4.375 31.25 4.09518 31.25 3.75C31.25 3.40482 30.9702 3.125 30.625 3.125C30.2798 3.125 30 3.40482 30 3.75C30 4.09518 30.2798 4.375 30.625 4.375Z" />
      <path id="Vector_4" d="M37.8125 0H2.1875C1.60734 0 1.05094 0.230468 0.640704 0.640704C0.230468 1.05094 0 1.60734 0 2.1875L0 37.8125C0 38.3927 0.230468 38.9491 0.640704 39.3593C1.05094 39.7695 1.60734 40 2.1875 40H37.8125C38.3927 40 38.9491 39.7695 39.3593 39.3593C39.7695 38.9491 40 38.3927 40 37.8125V2.1875C40 1.60734 39.7695 1.05094 39.3593 0.640704C38.9491 0.230468 38.3927 0 37.8125 0ZM2.1875 1.25H37.8125C38.0611 1.25 38.2996 1.34877 38.4754 1.52459C38.6512 1.7004 38.75 1.93886 38.75 2.1875V6.25H1.25V2.1875C1.25 1.93886 1.34877 1.7004 1.52459 1.52459C1.7004 1.34877 1.93886 1.25 2.1875 1.25ZM37.8125 38.75H2.1875C1.93886 38.75 1.7004 38.6512 1.52459 38.4754C1.34877 38.2996 1.25 38.0611 1.25 37.8125V7.5H38.75V37.8125C38.75 38.0611 38.6512 38.2996 38.4754 38.4754C38.2996 38.6512 38.0611 38.75 37.8125 38.75Z" />
      <path id="Vector_5" d="M20.0313 26.3438C17.5875 26.3438 15.3688 27.4062 14.375 29.0625C14.3215 29.133 14.2836 29.214 14.2637 29.3002C14.2439 29.3864 14.2426 29.4759 14.2599 29.5626C14.2772 29.6494 14.3127 29.7315 14.3641 29.8035C14.4155 29.8755 14.4816 29.9358 14.558 29.9804C14.6344 30.0249 14.7194 30.0528 14.8074 30.062C14.8954 30.0713 14.9843 30.0617 15.0684 30.0341C15.1524 30.0064 15.2296 29.9612 15.2949 29.9014C15.3601 29.8417 15.4119 29.7688 15.4469 29.6875C16.2188 28.4094 18.0188 27.5844 20.0313 27.5844C21.9282 27.5844 23.6844 28.3469 24.5063 29.525C24.551 29.5971 24.6099 29.6593 24.6795 29.7078C24.749 29.7564 24.8277 29.7903 24.9107 29.8075C24.9938 29.8246 25.0795 29.8247 25.1626 29.8077C25.2456 29.7907 25.3244 29.757 25.394 29.7086C25.4637 29.6602 25.5227 29.5981 25.5676 29.5262C25.6125 29.4542 25.6423 29.3739 25.6551 29.29C25.6679 29.2062 25.6636 29.1206 25.6423 29.0385C25.621 28.9564 25.5832 28.8795 25.5313 28.8125C24.4688 27.2937 22.3594 26.3438 20.0313 26.3438Z" />
      <path id="Vector_6" d="M15.1843 22.3156C15.3038 22.418 15.4576 22.4715 15.6149 22.4654C15.7722 22.4594 15.9215 22.3942 16.0328 22.2829C16.1441 22.1715 16.2093 22.0223 16.2154 21.865C16.2214 21.7077 16.1679 21.5539 16.0655 21.4344L14.0093 19.375L16.0655 17.3156C16.1679 17.1961 16.2214 17.0423 16.2154 16.885C16.2093 16.7277 16.1441 16.5785 16.0328 16.4671C15.9215 16.3558 15.7722 16.2906 15.6149 16.2846C15.4576 16.2785 15.3038 16.332 15.1843 16.4344L13.1249 18.4906L11.0655 16.4344C10.946 16.332 10.7922 16.2785 10.6349 16.2846C10.4776 16.2906 10.3284 16.3558 10.2171 16.4671C10.1057 16.5785 10.0405 16.7277 10.0345 16.885C10.0284 17.0423 10.0819 17.1961 10.1843 17.3156L12.2405 19.375L10.1843 21.4344C10.1189 21.4904 10.0657 21.5594 10.0282 21.6369C9.99069 21.7144 9.96961 21.7989 9.96629 21.885C9.96296 21.971 9.97747 22.0569 10.0089 22.1371C10.0403 22.2173 10.088 22.2901 10.1489 22.351C10.2098 22.4119 10.2826 22.4596 10.3628 22.491C10.443 22.5224 10.5289 22.5369 10.6149 22.5336C10.701 22.5303 10.7855 22.5092 10.863 22.4717C10.9406 22.4342 11.0095 22.3811 11.0655 22.3156L13.1249 20.2594L15.1843 22.3156Z" />
      <path id="Vector_7" d="M29.8155 16.4344C29.6984 16.318 29.54 16.2526 29.3749 16.2526C29.2098 16.2526 29.0514 16.318 28.9343 16.4344L26.8749 18.4906L24.8155 16.4344C24.696 16.332 24.5422 16.2785 24.3849 16.2845C24.2276 16.2906 24.0784 16.3558 23.9671 16.4671C23.8557 16.5785 23.7905 16.7277 23.7845 16.885C23.7784 17.0423 23.8319 17.1961 23.9343 17.3156L25.9905 19.375L23.9343 21.4344C23.8689 21.4904 23.8157 21.5593 23.7782 21.6369C23.7407 21.7144 23.7196 21.7989 23.7163 21.885C23.713 21.971 23.7275 22.0569 23.7589 22.1371C23.7903 22.2173 23.838 22.2901 23.8989 22.351C23.9598 22.4119 24.0326 22.4596 24.1128 22.491C24.193 22.5224 24.2789 22.5369 24.3649 22.5336C24.451 22.5303 24.5355 22.5092 24.613 22.4717C24.6906 22.4342 24.7595 22.381 24.8155 22.3156L26.8749 20.2594L28.9343 22.3156C29.0538 22.418 29.2076 22.4715 29.3649 22.4654C29.5222 22.4594 29.6715 22.3942 29.7828 22.2828C29.8941 22.1715 29.9593 22.0223 29.9654 21.865C29.9714 21.7077 29.9179 21.5539 29.8155 21.4344L27.7593 19.375L29.8155 17.3156C29.9319 17.1985 29.9973 17.0401 29.9973 16.875C29.9973 16.7099 29.9319 16.5515 29.8155 16.4344Z" />
    </g>
    <defs>
      <clipPath id="clip0_5445_17646">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
  )
}

export function IconNotFound(props)
{
  return <Icon component={IconSvg} {...props} />
}


