import React from "react";
import { Button, Modal, Typography } from "antd";
import { IconSuccess } from "../../icons/IconSuccess";
import { IconClose } from "../../icons";

function ModalChangePasswordSuccess({ open, close })
{
	return (
		<Modal
			open={open}
			closable={false}
			footer={null}
			centered
			
		>
			<Button
				onClick={close}
				icon={<IconClose style={{ width: 12, height: 12 }} />}
				type="ghost"
				style={{
					position: "absolute",
					right: 16,
					top: 16,
					width: 32,
					height: 32,
					backgroundColor: "#F6F8FD",
					padding: 0,
				}}
			/>
			<div style={{
				display: "flex",
				alignItems: "center",
				textAlign: "center",
				flexDirection: "column",
				gap: 24,
			}}>
				<IconSuccess />

				<Typography.Title level={3}>Пароль успешно <br/>изменен!</Typography.Title>

				<Button
					onClick={close}
					style={{ background: "#2E496F", height: 48, paddingInline: 28 }}
					type="primary"
				>
					Ок
				</Button>
			</div>
		</Modal>
	)
}

export default ModalChangePasswordSuccess;