// export async function getFileHash(file) {
//     const buffer = await file.arrayBuffer();
//     const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
//     const hashArray = Array.from(new Uint8Array(hashBuffer));
//     const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
//     return hashHex;
// }


export function parseIfUserName(org, orgId_userId){
    const regex = /\d+_+\d+/
    let userName = "Не найдено"
    if (regex.test(orgId_userId)){
        org?.users?.forEach((user)=>{
            if (user?.id?.toString() === orgId_userId.split('_')[1]){
                userName = '';
                if (user?.first_name){
                    userName = user?.first_name + ' '
                }
                if (user?.last_name){
                    userName = userName + user?.last_name
                }
            }           
        })
        return userName
    }
    else if(orgId_userId?.length === 64) {
        return 'API'
    }
    else  return orgId_userId;

}