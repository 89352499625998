export const getRandomId = (url) => {
  let randomId;

  const r = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

  const rs = (len) =>
    new Array(len)
      .fill(0)
      .map(() => String.fromCharCode(r(97, 122)))
      .join("");
  randomId = rs(100) + url;
  return randomId;
};
export const changeDate = (date) => {
  if (date) {
    let arr = date.split("T");
    let index = arr[1].indexOf(".");
    let timeStr = "";
    if (index === -1) timeStr = arr[1];
    else timeStr = arr[1].slice(0, index);

    let str = <span>{arr[0].split("-").reverse().join(".")}{<br/>}{timeStr}</span>

    return str;
  } else return "";
};
