import React from "react";
import styles from "./OrgArea.module.css";
import { Space, Table, Typography } from "antd";
import Dot from "../UI/Dot";
import { useSelector } from "react-redux";
import { getOrganisation } from "../../store/auth/selectors";

const { Text, Link } = Typography;

function UserListNew() {
  const orgData = useSelector(getOrganisation);

  return (
    <>
      <Table
        columns={columns}
        dataSource={orgData?.users}
        className={styles.table}
        rowKey={(obj) => obj.id}
        pagination={false}
        scroll={{ x: 1000 }}
        size="small"
        style={{ overflowX: "auto" }}
      />
    </>
  );
}

const columns = [
  createColumn("Фамилия, имя", "last_name", (text, row) => (
    <Text>
      {row["last_name"]} {row["first_name"]}
    </Text>
  )),
  createColumn("E-mail", "email", (email) => (
    <Link className={styles.cellText} href={"mailto:" + email}>
      {email}
    </Link>
  )),
  createColumn("Роль", "role"),
  createColumn("Должность", "job_title"),
  createColumn("Телефон", "phone", (phone) => (
    <Link className={styles.cellText} href={"tel:" + phone}>
      {phone}
    </Link>
  )),
  createColumn("Статус", "is_active", (_, { is_active }) =>
    is_active === true ? (
      <div>
        {" "}
        <Dot color="#219653" />
        <Text className={styles.cellText}>Активен</Text>
      </div>
    ) : (
      <div>
        {" "}
        <Dot color="#EB5757" />
        <Text className={styles.cellText}>Неактивен</Text>
      </div>
    )
  ),
  createColumn("Дата входа", "last_login", (_, { last_login }) => (
    <div>
      <Dot color="#219653" />
      <Space direction="vertical" size={0}>
        <Text className={styles.cellText}>{last_login?.split(" ")[0]}</Text>
        <Text className={styles.cellText} style={{ color: "#7A7D86" }}>
          {last_login?.split(" ")[1]}
        </Text>
      </Space>
    </div>
  )),
];

function createColumn(title, key, render) {
  return { title, key, dataIndex: key, render };
}

export default UserListNew;
