import React from "react";
import { Alert, Button, Drawer, Space, Typography } from "antd";
import { IconClose, IconWarning } from "../../icons";

function ModalWarnings({ open, close, data, title }) {
  return (
    <Drawer
      open={open}
      onClose={close}
      width={700}
      styles={{ header: { display: "none" } }}
    >
      <Space
        style={{
          display: "flex",
          justifyContent: "end",
          paddingInline: 16,
          height: 56,
        }}
      >
        <Button
          onClick={close}
          icon={<IconClose style={{ width: 12, height: 12 }} />}
          type="ghost"
          style={{
            width: 32,
            height: 32,
            backgroundColor: "#F6F8FD",
            padding: 0,
          }}
        />
      </Space>
      <Space
        direction="vertical"
        size={12}
        style={{
          paddingInline: 48,
          paddingBottom: 32,
          display: "flex",
          position: "relative",
        }}
      >
        <Typography.Title level={3}>
          {!title ? "Warnings" : title}
        </Typography.Title>
        {data?.length > 0 &&
          data?.map((item) => (
            <Alert
              message={item.message}
              type="warning"
              key={item.message + "error"}
              showIcon
              icon={<IconWarning />}
            >
              {item.message}
            </Alert>
          ))}
      </Space>
    </Drawer>
  );
}

export default ModalWarnings;
