import React from "react"
import Icon from '@ant-design/icons';

function IconSvg()
{
  return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor"

style={{ stroke: "currentcolor" }}  xmlns="http://www.w3.org/2000/svg">
<g id="interface-text-formatting-filter-1--funnel-filter-round-oil" clipPath="url(#clip0_5374_40661)">
<path id="Vector" d="M23.1431 0.857178H0.857422C0.858368 3.36614 1.70473 5.80159 3.2599 7.77044C4.81507 9.73929 6.98831 11.1266 9.42885 11.7086V23.1429L14.5717 19.7143V11.7086C17.0122 11.1266 19.1855 9.73929 20.7407 7.77044C22.2958 5.80159 23.1422 3.36614 23.1431 0.857178Z" stroke="#000001" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_5374_40661">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
  )
}

export function IconFilterUrl({ direction, style, ...props })
{
  return <Icon component={IconSvg} {...props} style={{
    ...style, transform: {
    "top": "rotate(90deg)",
    "right": "rotate(180deg)",
    "left": "",
    "bottom": "rotate(-90deg)",
  }[direction] }} />
}
