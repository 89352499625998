import React from "react"
import Icon from '@ant-design/icons';

function IconSvg()
{
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" style={{ stroke: "currentcolor", fill: "currentcolor" }} xmlns="http://www.w3.org/2000/svg">
      <path d="M7.63281 5.66772L22.0012 5.66772" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M7.63281 12.3005L22.0012 12.3005" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M7.63281 18.9333L22.0012 18.9333" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M2.38158 13.6809C3.1446 13.6809 3.76316 13.0623 3.76316 12.2993C3.76316 11.5363 3.1446 10.9177 2.38158 10.9177C1.61855 10.9177 1 11.5363 1 12.2993C1 13.0623 1.61855 13.6809 2.38158 13.6809Z" stroke="none" />
      <path d="M2.38158 7.04807C3.1446 7.04807 3.76316 6.42952 3.76316 5.66649C3.76316 4.90347 3.1446 4.28491 2.38158 4.28491C1.61855 4.28491 1 4.90347 1 5.66649C1 6.42952 1.61855 7.04807 2.38158 7.04807Z" stroke="none" />
      <path d="M2.38158 20.3137C3.1446 20.3137 3.76316 19.6951 3.76316 18.9321C3.76316 18.1691 3.1446 17.5505 2.38158 17.5505C1.61855 17.5505 1 18.1691 1 18.9321C1 19.6951 1.61855 20.3137 2.38158 20.3137Z" stroke="none" />
    </svg>
  )
}

export function IconOrganization(props)
{
  return <Icon component={IconSvg} {...props} />
}