export const viewExtension = (file) => {
  if (file) {
    let index = file.lastIndexOf("/");
    return file.slice(index + 1);
  } else {
    return "";
  }
};

export function getExtension(filename) {
  const parts = filename.split('.');
  return parts[parts.length - 1];
}

export const viewFileName = (file) => {
  if (file) {
    let index = file.lastIndexOf("/");
    let fileNameWithExtension = file.slice(index + 1);
    let lastDotIndex = fileNameWithExtension.lastIndexOf(".");
    if (lastDotIndex !== -1) {
      return fileNameWithExtension.slice(0, lastDotIndex);
    } else {
      return fileNameWithExtension;
    }
  } else {
    return "";
  }
};
export const typeFileApp = (value) => {
  let type = "";
  switch (value) {
    case "exe":
      type = "application/vnd.microsoft.portable-executable";
      break;
    case "rpm":
      type = "application/x-rpm";
      break;
    case "deb":
      type = "application/vnd.debian.binary-package";
      break;
      case "msi":
        type = "application/x-msdownload";
        break;
  
    default:
      type = `application/${value}`;
  }

  return type;
};
export const baseUrl = ()=> {
  let baseUrlClear = "/";
      if (process.env.REACT_APP_API_SERVER === "localhost:8000") {
        baseUrlClear = `http://${process.env.REACT_APP_API_SERVER}`;
      } else {
        baseUrlClear = `https://${process.env.REACT_APP_API_SERVER}`;
      }
      return baseUrlClear
}