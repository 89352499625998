import React from "react";
import { Modal } from "antd";
import AddEdlLInstanceNew from "./AddEdlLInstanceNew";
import AddEdlLInstanceFile from "./AddEdlLInstanceFile";

function ModalAddLInctance({ open, close, item, type }) {
  return (
    <Modal open={open} closable={false} footer={null} centered>
      {type === "single" && <AddEdlLInstanceNew close={close} item={item} />}
      {type === "file" && <AddEdlLInstanceFile close={close} item={item} />}
    </Modal>
  );
}

export default ModalAddLInctance;
