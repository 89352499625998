import React from "react";
import { Space, Typography, message } from "antd";
import WithoutAuthorization from "../../components/WithoutAuthorization";

import DocumentationList from "./DocumentationList";
const { Title } = Typography;

function DocumentationArea({ permissions }) {
  const [, contextHolder] = message.useMessage();

  message.config({ duration: 5 });

  return (
    <Space direction="vertical" size={20} style={{ width: "100%" }}>
      {contextHolder}
      <Title level={3} style={{ margin: 0 }}>
        Документация
      </Title>
      {(permissions?.full_access || permissions?.read_access) && (
        <DocumentationList />
      )}
      {permissions?.full_access === false &&
        permissions?.read_access === false && <WithoutAuthorization />}
    </Space>
  );
}

export default DocumentationArea;
