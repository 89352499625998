import React from "react";
import "./PreloaderNew.css";

const PreloaderNew = () => {
  return (
    <div className="loaderPosition">
      <div className="loader" />
    </div>
  );
};

export default PreloaderNew;
