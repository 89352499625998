import React from "react"
import Icon from '@ant-design/icons';

function IconSvg()
{
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" style={{ stroke: "currentcolor" }} xmlns="http://www.w3.org/2000/svg">
      <g id="Component 88">
        <path id="Vector" d="M15.5234 5.83337H9.52344C8.41887 5.83337 7.52344 6.72881 7.52344 7.83338V15.5C7.52344 16.6046 8.41887 17.5 9.52344 17.5H15.5234C16.628 17.5 17.5234 16.6046 17.5234 15.5V7.83337C17.5234 6.7288 16.628 5.83337 15.5234 5.83337Z" strokeWidth="1.2" strokeLinecap="round" />
        <path id="Vector_2" d="M14.1904 2.5H6.19043C5.08586 2.5 4.19043 3.39543 4.19043 4.5V14.1667" strokeWidth="1.2" strokeLinecap="round" />
      </g>
    </svg>
  )
}

export function IconCopy(props)
{
  return <Icon component={IconSvg} {...props} />
}

