import React from "react"
import Icon from '@ant-design/icons';

function IconSvg()
{
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ stroke: "currentcolor" }} xmlns="http://www.w3.org/2000/svg">
      <g id="Component 43/interface/05">
        <path id="Vector 1" d="M2.5 9.2172V4.30054C2.5 3.47211 3.17157 2.80054 4 2.80054H8.91667C9.74509 2.80054 10.4167 3.47211 10.4167 4.30054V9.2172C10.4167 10.0456 9.74509 10.7172 8.91667 10.7172H4C3.17157 10.7172 2.5 10.0456 2.5 9.2172Z" strokeWidth="1.5" />
        <path id="Vector 3" d="M2.5 20.2992V15.3826C2.5 14.5541 3.17157 13.8826 4 13.8826H8.91667C9.74509 13.8826 10.4167 14.5541 10.4167 15.3826V20.2992C10.4167 21.1277 9.74509 21.7992 8.91667 21.7992H4C3.17157 21.7992 2.5 21.1277 2.5 20.2992Z" strokeWidth="1.5" />
        <path id="Vector 2" d="M13.582 9.21721V4.30054C13.582 3.47211 14.2536 2.80054 15.082 2.80054H19.9987C20.8271 2.80054 21.4987 3.47211 21.4987 4.30054V9.2172C21.4987 10.0456 20.8271 10.7172 19.9987 10.7172H15.082C14.2536 10.7172 13.582 10.0456 13.582 9.21721Z" strokeWidth="1.5" />
        <path id="Vector 4" d="M13.582 20.2992V15.3826C13.582 14.5541 14.2536 13.8826 15.082 13.8826H19.9987C20.8271 13.8826 21.4987 14.5541 21.4987 15.3826V20.2992C21.4987 21.1277 20.8271 21.7992 19.9987 21.7992H15.082C14.2536 21.7992 13.582 21.1277 13.582 20.2992Z" strokeWidth="1.5" />
      </g>
    </svg>
  )
}

export function IconDashboard(props)
{
  return <Icon component={IconSvg} {...props} />
}