import React from "react"
import Icon from '@ant-design/icons';

function IconSvg({width, height})
{
  return (
    <svg width={width || "12"} height={height || "12"} viewBox="0 0 12 12" fill="none" style={{ stroke: "currentcolor" }} xmlns="http://www.w3.org/2000/svg">
      <path id="Vector" d="M1.42871 1L11.4287 11M1.42871 11L11.4287 1.29447" strokeLinecap="round" />
    </svg>
  )
}

export function IconMarkFalse(props)
{
  return <Icon component={IconSvg} {...props} />
}


