import urlfilter from ".";

export const getUrlData = ({ urlfilter }) => urlfilter.urlData;
export const getUrlCheckErrors = ({ urlfilter }) => urlfilter.errors;
export const getUpdUrlData = ({ urlfilter }) => urlfilter.updatedUrlData;
export const getUpdStatus = ({ urlfilter }) => urlfilter.updateStatus;
export const getUrlLoaders = ({ urlfilter }) => urlfilter.loaders.common;
export const getDataApi = ({ urlfilter }) => urlfilter.apiData;
export const getUrlAssetsData = ({ urlfilter }) => urlfilter.assetsData;
export const getUrlGrapData = ({ urlfilter }) => urlfilter.graphData;
export const getUrlKfeedHitsData = ({ urlfilter }) => urlfilter.kfeedHitsData;
export const getUrlKfeedDataDetail = ({ urlfilter }) => urlfilter.urlDataKfeedDetail;
export const getKfeedSucess= ({ urlfilter }) => urlfilter.kfeedSuccess;
export const getKfeedErrors = ({ urlfilter }) => urlfilter.kfeedErrors;
export const getOrgFallbacksInfo = ({ urlfilter }) => urlfilter.orgFallbacksInfo;
//filter
export const getUrlSnFilter = ({ urlfilter }) => urlfilter.snFilter;
export const getUrlSnDropdown = ({ urlfilter }) => urlfilter.assetsDropdown;

//fallback-logs 
export const getFallbackLogsInfo = ({urlfilter})=>urlfilter.fallbackLogs
export const getFallbackLogsLoader = ({urlfilter})=>urlfilter.loaders.fallbackLogs