// eslint-disable react-hooks/exhaustive-deps
import React, { useState } from "react";
import styles from "./EdlStyles.module.css";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line no-unused-vars
import {
  getCheckListsInstance,
  setProgress,
  switchAddListSuccess,
  switchAddSuccess,
} from "../../store/edl";
import {
  getEdlAddInstanceErrors,
  getEdlAddLoaders,
  getEdlDataUser,
  getEdlSuccessAddList,
  getFileProgress,
} from "../../store/edl/selectors";
import { getUser } from "../../store/auth/selectors";
import { Alert, Button, Col, Form, Progress, Space, Typography } from "antd";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import { IconClose } from "../../icons";

function AddEdlLInstanceFile({ close, item }) {
  const { Title } = Typography;

  const active = item?.is_active;
  const type = item?.list_type;
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const addSuccess = useSelector(getEdlSuccessAddList);
  const errors = useSelector(getEdlAddInstanceErrors);
  const userData = useSelector(getUser);
  const userDataEdl = useSelector(getEdlDataUser);
  const [fileError, setFileError] = useState(false);
  const isLoading = useSelector(getEdlAddLoaders);

  const progress = useSelector(getFileProgress);
  const saveInstance = async () => {
    const validate = await placeFileContent(file);

    if (file?.size <= 52428800 && validate) {
      const formData = new FormData();
      formData.append("file", file);

      if (userData?.first_name || userData?.last_name)
        formData.append(
          "updated_by",
          `${userData?.first_name} ${userData?.last_name}`
        );
      else formData.append("updated_by", `${userData?.email}`);
      dispatch(
        getCheckListsInstance({
          data: formData,
          idList: item.id,
          typeList: "file",
        })
      );
    } else setFileError(true);
  };

  const handleFormLabel = (type) => {
    let formLabel = "";
    // eslint-disable-next-line default-case
    switch (type) {
      case "IP_ADDRESS_LIST": {
        formLabel =
          active === true
            ? userDataEdl?.list_stats?.ip_addresses_limits.active
            : userDataEdl?.list_stats?.ip_addresses_limits.inactive;
        break;
      }
      case "DOMAIN_LIST": {
        formLabel =
          active === true
            ? userDataEdl?.list_stats?.domains_limits.active
            : userDataEdl?.list_stats?.domains_limits.inactive;
        break;
      }
      case "URL_LIST": {
        formLabel =
          active === true
            ? userDataEdl?.list_stats?.urls_limits.active
            : userDataEdl?.list_stats?.urls_limits.inactive;
        break;
      }
    }
    return formLabel;
  };
  const validateFile = (type, stringAmount) => {
    let result = false;
    if (stringAmount <= 200000) {
      // eslint-disable-next-line default-case
      switch (type) {
        case "IP_ADDRESS_LIST": {
          if (
            stringAmount <=
            (active === true
              ? userDataEdl?.list_stats?.ip_addresses_limits.active
              : userDataEdl?.list_stats?.ip_addresses_limits.inactive)
          )
            result = true;
          break;
        }
        case "DOMAIN_LIST": {
          if (
            stringAmount <=
            (active === true
              ? userDataEdl?.list_stats?.domains_limits.active
              : userDataEdl?.list_stats?.domains_limits.inactive)
          )
            result = true;
          break;
        }
        case "URL_LIST": {
          if (
            stringAmount <=
            (active === true
              ? userDataEdl?.list_stats?.urls_limits.active
              : userDataEdl?.list_stats?.urls_limits.inactive)
          )
            result = true;
          break;
        }
      }
    }
    return result;
  };
  function placeFileContent(file) {
    const content = readFileContent(file)
      .then((content) => {
        return validateFile(type, content.split(/\r\n|\r|\n/).length);
      })
      .catch((error) => console.log(error, "error"));
    return content;
  }

  function readFileContent(file) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  }
  const resetAll = () => {
    dispatch(switchAddSuccess());
    dispatch(setProgress(0));
    close();
    dispatch(switchAddListSuccess());
  };

  return (
    <>
      <Button
        onClick={resetAll}
        icon={<IconClose style={{ width: 12, height: 12 }} />}
        type="ghost"
        style={{
          position: "absolute",
          right: 16,
          top: 16,
          width: 32,
          height: 32,
          backgroundColor: "#F6F8FD",
          padding: 0,
        }}
      />
      <Title style={{ marginBottom: "24px" }} level={2}>
        Загрузить записи файлом
      </Title>
      <Form
        size="large"
        name="AddList"
        layout="vertical"
        requiredMark={false}
        className={styles.form}
        style={{ width: "100%", position: "relative" }}
        onFinish={() => {
          saveInstance();
        }}
      >
        {errors && <Alert message={errors} type="error" showIcon />}
        {isLoading && <PreloaderNew />}

        {addSuccess && (
          <Alert
            message="Success"
            // description=' Файл загружен и добавлен в очередь на обработку. После успешного завершения процесса, новые записи будут доступны в списке.'
            description={addSuccess}
            type="success"
            showIcon
          ></Alert>
        )}

        <Col span={16}>
          {progress !== 0 && <Progress percent={Math.floor(progress)} />}
        </Col>

        <Space direction="horizontal" size={"large"}>
          <label
            className={styles.input_file}
            style={
              fileError
                ? { border: "1px solid red", width: "360px" }
                : { width: "360px" }
            }
          >
            <span className={styles.input_file_btn}>Выберите файл</span>
            <span className={styles.input_file_text_in} type="text">
              {file?.name || "Файл не выбран"}
            </span>
            <input
              type="file"
              className={styles.file}
              disabled={addSuccess}
              onChange={async (e) => {
                setFileError(false);

                const validate = await placeFileContent(e?.target?.files[0]);
                if (e?.target?.files[0]?.size <= 52428800 && validate) {
                  setFile(e.target.files[0]);
                } else setFileError(true);
              }}
              accept=".txt"
            />
          </label>
        </Space>
        <div className={styles.placeholderWrapper}>
          <span
            style={fileError ? { color: "red" } : {}}
            className={styles.placeholderText}
          >
            Допустимый размер файла 50МБ
          </span>
          <span
            style={fileError ? { color: "red" } : {}}
            className={styles.placeholderText}
          >
            Максимальное кол-во строк в одном файле 200000
          </span>
          <span
            style={fileError ? { color: "red" } : {}}
            className={styles.placeholderText}
          >
            Допустимое кол-во строк в лимите {handleFormLabel(type)}
          </span>
          <span className={styles.placeholderText}>
            Поддерживаемые типы файлов: ['txt']
          </span>
        </div>
        {addSuccess === false && (
          <div
            style={{
              display: "flex",

              height: 56,
              marginTop: "24px",
              gap: "24px",
            }}
          >
            <Button
              type="primary"
              onClick={close}
              style={{
                width: "168px",
                borderRadius: 4,
                fontSize: 14,
                border: "1px solid #CED9EE",
                background: "transparent",
                color: " #2E496F",
              }}
            >
              Отменить
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isLoading || fileError ? true : false}
              style={{
                width: "168px",
                borderRadius: 4,
                fontSize: 14,
                background: "#2E496F",
              }}
            >
              Добавить
            </Button>
          </div>
        )}
        {addSuccess && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: 56,
              marginTop: "24px",
              gap: "24px",
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                dispatch(switchAddSuccess());
                dispatch(switchAddListSuccess());
                close();
                dispatch(setProgress(0));
              }}
              style={{
                width: "168px",
                borderRadius: 4,
                fontSize: 14,
                background: "#2E496F",
              }}
            >
              Закрыть
            </Button>
          </div>
        )}
      </Form>
    </>
  );
}
export default AddEdlLInstanceFile;
