export const getDocumentDistributionsData = ({ distributions }) =>
  distributions.documentsData;
export const getCheckErrors = ({ distributions }) => distributions.errors;
export const getLoaders = ({ distributions }) => distributions.loaders.common;
export const getAgentLoaders = ({ distributions }) =>
  distributions.loaders.commonAgent;
export const getCompatibilityLoaders = ({ distributions }) =>
  distributions.loaders.commonCompatibility;
export const getServerLoaders = ({ distributions }) =>
  distributions.loaders.commonServer;
export const getDistributionsServerData = ({ distributions }) =>
  distributions.distributionsServerData;
export const getDistributionsAgentData = ({ distributions }) =>
  distributions.distributionsAgentData;
export const getDistributionsCompatibilityData = ({ distributions }) =>
  distributions.distributionsCompatibilityData;
export const getDistributionsServerDetails = ({ distributions }) =>
  distributions.distributionsServerDetails;
export const getDistributionsAgentDetails = ({ distributions }) =>
  distributions.distributionsAgentDetails;
export const getDocumentNextPage = ({ distributions }) =>
  distributions.nextPage.nextPageDoc;
export const getCompatibilityNextPage = ({ distributions }) =>
  distributions.nextPage.nextPageCompatibility;
export const getAgentNextPage = ({ distributions }) =>
  distributions.nextPage.nextPageAgent;
export const getServerNextPage = ({ distributions }) =>
  distributions.nextPage.nextPageServer;
  export const getDisDetailLoader = ({distributions}) => distributions.loaders.details
  export const getDisDocsLoader = ({distributions}) => distributions.loaders.docs

  export const getAgentsDownloadData = ({distributions}) => distributions.download.agents
  export const getServersDownloadData = ({distributions}) => distributions.download.servers
