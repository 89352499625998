const pages = {
  dashboard: { id: "dashboard", path: "/dashboard" },
  organization: { id: "organization", path: "/" },
  url_filtering: { id: "url_filtering", path: "/url_filter" },
  threat_vault: { id: "threat_vault", path: "/threat_vault" },
  threat_lists: { id: "edl", path: "/edl" },
  edl_cloud: { id: "edl_cloud", path: "/edl_cloud" },
  documentation: { id: "documentation", path: "/documentation" },
  sandbox: { id: "sandbox", path: "/sandbox" },
  profile: { id: "profile", path: "/profile" },
  security: { id: "security", path: "/security" },
  settings: { id: "settings", path: "/settings" },
  notifications: { id: "notifications", path: "/notifications" },
  distributions: { id: "distributions", path: "/distributions" },
  eula: {id: 'eula', path: "/eula"}
};

export default pages;
