import React from "react"
import Icon from '@ant-design/icons';

function IconSvg()
{
  return (
    <svg width="18" height="32" viewBox="0 0 18 32" fill="none" style={{ stroke: "currentcolor" }} xmlns="http://www.w3.org/2000/svg">
      <path id="Line 536" d="M11 12L7 16L11 20" />
    </svg>
  )
}

export function IconArrow({ direction, style, ...props })
{
  return <Icon component={IconSvg} {...props} style={{
    ...style, transform: {
    "top": "rotate(90deg)",
    "right": "rotate(180deg)",
    "left": "",
    "bottom": "rotate(-90deg)",
  }[direction] }} />
}
