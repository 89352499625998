import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getUrlGrapData } from '../../../store/urlfilter/selectors';
import './newGraphKfeed.css'
import { useSelector } from 'react-redux';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const NewGraphKfeed = () => {
    const urlKfeedChartData = useSelector(getUrlGrapData);
    // const urlKfeedChartData = []
    const getHoverColor = (color) => {
        if (color == '#E52E5A') {
            return '#B60B34'
        }
        else if (color == '#F6B9C8') {
            return '#D993A4'
        }
        else if (color == '#219653') {
            return '#1C7C47'
        }
        else if (color == '#ACDCC1') {
            return '#97B8A5'
        }

    };
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const getActualFormatAxes = (date) => {
        let actualDate = '';
        actualDate = date.split('.')[0] + '.' + date.split('.')[1];
        return actualDate;
    };

    const createExternalTooltip = (chart) => {
        let tooltipEl = chart.canvas.parentNode.querySelector('div');

        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.style.backgroundColor = 'white';
            tooltipEl.style.fontSize = '13px'
            tooltipEl.style.fontWeight = '500'
            tooltipEl.style.lineHeight = '18px'
            tooltipEl.style.padding = '8px';
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.position = 'absolute';
            const table = document.createElement('table');
            tooltipEl.appendChild(table);
            chart.canvas.parentNode.appendChild(tooltipEl);
        }

        return tooltipEl;
    };

    const externalTooltipHandler = (context) => {
        const { chart, tooltip } = context;
        const tooltipEl = createExternalTooltip(chart);
    
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }
    
        const dataIndex = tooltip.dataPoints[0].dataIndex;
        const totalBars = chart.data.labels.length;
        const isLastBars = dataIndex >= totalBars/2;
    
        const titledata = urlKfeedChartData[dataIndex].date;
        const title = `Запросов за: ${titledata}`;
    
        const bodyLines = tooltip.body.map(b => b.lines);
    
        const tableHead = document.createElement('thead');
        const trHead = document.createElement('tr');
        const th = document.createElement('th');
        const textHead = document.createTextNode(title);
        th.appendChild(textHead);
        trHead.appendChild(th);
        tableHead.appendChild(trHead);
        th.style.color = '#4B4F55'
    
        const tableBody = document.createElement('tbody');
    
        bodyLines.forEach((body, i) => {
            const colors = tooltip.labelColors[i];
    
            const span = document.createElement('span');
            span.style.background = colors.backgroundColor;
            span.style.borderColor = colors.borderColor;
            span.style.borderWidth = '2px';
            span.style.borderRadius = '50%';
            span.style.marginRight = '10px';
            span.style.height = '10px';
            span.style.width = '10px';
            span.style.display = 'inline-block';
    
            const tr = document.createElement('tr');
            const td = document.createElement('td');
    
            const text = document.createTextNode(body);
            td.appendChild(span);
            td.appendChild(text);
            tr.appendChild(td);
            tableBody.appendChild(tr);
            tr.style.color = '#989AA5'

        });
    
        const tableRoot = tooltipEl.querySelector('table');
        while (tableRoot.firstChild) {
            tableRoot.firstChild.remove();
        }
    
        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
    
        let tooltipX = chart.canvas.offsetLeft + tooltip.caretX;
        const tooltipY = chart.canvas.offsetTop + tooltip.caretY - tooltip.height / 2;
    
        if (isLastBars) {
            tooltipX -= tooltip.width + 10;
        }
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = `${tooltipX}px`;
        tooltipEl.style.top = `${tooltipY}px`;
        tooltipEl.style.font = tooltip.options.bodyFont.string;
    };
    



    const chartData = {
        labels: urlKfeedChartData?.map(item => getActualFormatAxes(item.date)),
        datasets: [
            {
                label: 'Дополнительные',
                data: urlKfeedChartData?.map(item => item.secondary_cnt),
                backgroundColor: urlKfeedChartData?.map(item =>
                    item.kfeed_mode_replacing ? 'rgba(172, 220, 193, 0.8)' : 'rgba(246, 185, 200, 0.8)'
                ),
                hoverBackgroundColor: urlKfeedChartData?.map(item =>
                    item.kfeed_mode_replacing ? 'rgba(151, 184, 165, 0.8)' : 'rgba(217, 147, 164, 0.8)'
                ),
                stack: 'Stack 0',
                barThickness: 20,
                borderRadius: 6,
            },
            {
                label: 'Прямые',
                data: urlKfeedChartData?.map(item => item.direct_cnt),
                backgroundColor: urlKfeedChartData?.map(item =>
                    item.kfeed_mode_replacing ? 'rgba(33, 150, 83, 0.8)' : 'rgba(229, 46, 90, 0.8)'
                ),
                hoverBackgroundColor: urlKfeedChartData?.map(item =>
                    item.kfeed_mode_replacing ? 'rgba(28, 124, 71, 0.8)' : 'rgba(182, 11, 52, 0.8)'
                ),
                stack: 'Stack 0',
                borderRadius: 6,
                barThickness: 20,
            },
        ],
    };
    

    const options = {
        maintainAspectRatio: false,
        interaction: {
            mode: 'index',
            intersect: false,
            onHover: (elements) => {
                if (elements.length) {
                    const index = elements[0].index;
                    setHoveredIndex(index);
                } else {
                    setHoveredIndex(null);
                }
            },
        },
        plugins: {
            tooltip: {
                enabled: false,

                external: externalTooltipHandler,
                itemSort: function (a, b) {
                    const order = ['Прямые', 'Дополнительные'];
                    return order.indexOf(a.dataset.label) - order.indexOf(b.dataset.label);
                },
            },
            legend: {
                display: false
            },
            datalabels: {
                display: false,
            }
        },

        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                        size: '10px',
                        family: 'Muller'
                    }
                }

            },
            y: 
            {
                stacked: true,
                border:{
                    display: false,
                }
            },
        },
    };

    const updatedDatasets = chartData.datasets.map((dataset) => {
        return {
            ...dataset,
            backgroundColor: hoveredIndex !== null
                ? dataset.data.map((_, index) => (index === hoveredIndex ? getHoverColor(dataset.backgroundColor) : dataset.backgroundColor))
                : dataset.backgroundColor,
        };
    });

    return (
        <>
            <div className='stacked-bar-chart' style={{ height: '330px', width: '100%' }} >
                <Bar style={{ height: '330px', width: '100%' }} data={{ ...chartData, datasets: updatedDatasets }} options={options} />
            </div>
            <div className='legend-monitoring' style={{
                display: 'flex', alignItems: 'center', justifyContent: 'end', gap: '8px', width: '100%', height: '16px',
                marginTop: '16px'
            }}>
                <span style={{ fontSize: '12px' }}>Monitoring:</span>
                <span style={{ height: '16px', width: '16px', borderRadius: '6px', backgroundColor: '#E52E5A' }} />
                <span style={{ fontSize: '12px' }}>Прямые</span>
                <span style={{ height: '16px', width: '16px', borderRadius: '6px', backgroundColor: '#F6B9C8' }} />
                <span style={{ fontSize: '12px' }}>Дополнительные</span>
            </div>
            <div className='legend-replacing' style={{
                display: 'flex', alignItems: 'center', justifyContent: 'end', gap: '8px', width: '100%', height: '16px',
                marginTop: '5px', marginBottom: '4px'
            }}>
                <span style={{ fontSize: '12px' }}>Replacing:</span>
                <span style={{ height: '16px', width: '16px', borderRadius: '6px', backgroundColor: '#219653' }} />
                <span style={{ fontSize: '12px' }}>Прямые</span>
                <span style={{ height: '16px', width: '16px', borderRadius: '6px', backgroundColor: '#ACDCC1' }} />
                <span style={{ fontSize: '12px' }}>Дополнительные</span>
            </div>
        </>

    );
};

export { NewGraphKfeed };
