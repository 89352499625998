import React from "react";
import { Space, Typography } from "antd";
import DistributionsList from "./DistributionsList";
import WithoutAuthorization from "../../components/WithoutAuthorization";

const { Title } = Typography;

export const DistributionsArea = ({ permissions }) => {
  return (
    <Space direction="vertical" size={10} style={{ width: "100%" }}>
      <Space style={{ justifyContent: "space-between", width: "100%" }}>
        <Title level={3} style={{ margin: 0 }}>
          PARUS INTEGRITY MONITORING
        </Title>

        <Space style={{ gap: "32px" }}>
          <a
            style={{ textDecoration: "none", color: "#0047C7" }}
            href="#section1"
          >
            Cовместимость версий
          </a>
          <a
            style={{ textDecoration: "none", color: "#0047C7" }}
            href="#section2"
          >
            Документы и утилиты
          </a>
        </Space>
      </Space>
      {(permissions?.full_access || permissions?.read_access) && (
        <DistributionsList permissions={permissions} />
      )}
      {permissions?.full_access === false &&
        permissions?.read_access === false && <WithoutAuthorization />}
    </Space>
  );
};
