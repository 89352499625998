import React from "react";
import LoginNew from "../components/UI/LoginNew/LoginNew";


function LoginPage() {
  return (
<LoginNew
  // <Login/>
  />
  );
}

export default LoginPage;