import React from "react"
import Icon from '@ant-design/icons';

function IconSvg()
{
  return (
    <svg width="24" height="24" viewBox="0 0 16 15" style={{ stroke: "currentcolor" }} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Filter Vertical">
        <path id="Vector 753" d="M1 3.33325H3.33333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector 754" d="M1 11.1111H5.66667" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector 757" d="M12.6665 11.1111L14.9998 11.1111" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Vector 758" d="M10.3335 3.33325L15.0002 3.33325" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Rectangle 2129" d="M3.3335 3.33333C3.3335 2.60854 3.3335 2.24614 3.45191 1.96027C3.60979 1.57912 3.91261 1.27629 4.29377 1.11841C4.57963 1 4.94203 1 5.66683 1C6.39163 1 6.75403 1 7.03989 1.11841C7.42105 1.27629 7.72387 1.57912 7.88175 1.96027C8.00016 2.24614 8.00016 2.60854 8.00016 3.33333C8.00016 4.05813 8.00016 4.42053 7.88175 4.7064C7.72387 5.08755 7.42105 5.39038 7.03989 5.54826C6.75403 5.66667 6.39163 5.66667 5.66683 5.66667C4.94203 5.66667 4.57963 5.66667 4.29377 5.54826C3.91261 5.39038 3.60979 5.08755 3.45191 4.7064C3.3335 4.42053 3.3335 4.05813 3.3335 3.33333Z" strokeWidth="1.5" />
        <path id="Rectangle 2130" d="M8 11.1112C8 10.3864 8 10.024 8.11841 9.7381C8.27629 9.35695 8.57912 9.05412 8.96027 8.89624C9.24614 8.77783 9.60854 8.77783 10.3333 8.77783C11.0581 8.77783 11.4205 8.77783 11.7064 8.89624C12.0876 9.05412 12.3904 9.35695 12.5483 9.7381C12.6667 10.024 12.6667 10.3864 12.6667 11.1112C12.6667 11.836 12.6667 12.1984 12.5483 12.4842C12.3904 12.8654 12.0876 13.1682 11.7064 13.3261C11.4205 13.4445 11.0581 13.4445 10.3333 13.4445C9.60854 13.4445 9.24614 13.4445 8.96027 13.3261C8.57912 13.1682 8.27629 12.8654 8.11841 12.4842C8 12.1984 8 11.836 8 11.1112Z" strokeWidth="1.5" />
      </g>
    </svg>
  )
}

export function IconFilter(props)
{
  return <Icon component={IconSvg} {...props} />
}

