import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../store/auth";

const WindowFocusHandler = () => {
  let timer = null;
  const dispatch = useDispatch();

  const onFocus = () => {
    if (timer) clearTimeout(timer);
  };

  const onBlur = () => {
    timer = setTimeout(() => {
      dispatch(logout());
    }, [3600000]);
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    onFocus();
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  return <></>;
};

export default WindowFocusHandler;
