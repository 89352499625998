import React from "react"
import Icon from '@ant-design/icons';

function IconSvg()
{
  return (
<svg width="24" height="24" viewBox="0 0 24 24" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
<g id="interface-file-clipboard-text--edition-form-task-checklist-edit-clipboard">
<g id="Group">
<path id="Vector" d="M16.2859 2.57141H18.8573C19.3119 2.57141 19.748 2.75202 20.0695 3.07351C20.391 3.395 20.5716 3.83104 20.5716 4.2857V21.4286C20.5716 21.8832 20.391 22.3192 20.0695 22.6407C19.748 22.9622 19.3119 23.1428 18.8573 23.1428H5.143C4.68834 23.1428 4.2523 22.9622 3.93081 22.6407C3.60932 22.3192 3.42871 21.8832 3.42871 21.4286V4.2857C3.42871 3.83104 3.60932 3.395 3.93081 3.07351C4.2523 2.75202 4.68834 2.57141 5.143 2.57141H7.71442" stroke="#000001" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_2" d="M14.572 0.857178H9.42913C8.48236 0.857178 7.71484 1.62469 7.71484 2.57146V3.42861C7.71484 4.37538 8.48236 5.14289 9.42913 5.14289H14.572C15.5188 5.14289 16.2863 4.37538 16.2863 3.42861V2.57146C16.2863 1.62469 15.5188 0.857178 14.572 0.857178Z" stroke="#000001" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_3" d="M7.71484 9.42859H16.2863" stroke="#000001" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_4" d="M7.71484 13.7144H16.2863" stroke="#000001" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_5" d="M7.71484 18H16.2863" stroke="#000001" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</g>
</svg>
  )
}

export function IconClipboard({ direction, style, ...props })
{
  return <Icon component={IconSvg} {...props} style={{
    ...style, transform: {
    "top": "rotate(90deg)",
    "right": "rotate(180deg)",
    "left": "",
    "bottom": "rotate(-90deg)",
  }[direction] }} />
}
