import React from "react"
import Icon from '@ant-design/icons';

function IconSvg()
{
  return (
<svg width="25" height="25" viewBox="0 0 25 25" fill="currentColor"

xmlns="http://www.w3.org/2000/svg">
<g id="programming-bug--code-bug-security-programming-secure-computer" clipPath="url(#clip0_5374_44672)">
<g id="Group">
<path id="Vector" fill="currentColor"

d="M12.5004 21.9286C16.7609 21.9286 20.2147 18.4748 20.2147 14.2143C20.2147 9.9538 16.7609 6.5 12.5004 6.5C8.23994 6.5 4.78613 9.9538 4.78613 14.2143C4.78613 18.4748 8.23994 21.9286 12.5004 21.9286Z" stroke="#000001" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_2"fill="currentColor"

d="M4.78599 14.2144H1.35742" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_3"fill="currentColor"

d="M1.35742 20.2144C2.21282 20.2169 3.05863 20.0343 3.83684 19.6792C4.61506 19.3241 5.30727 18.8049 5.86599 18.1572" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_4"fill="currentColor"

d="M5.86599 10.2715C5.30727 9.62381 4.61506 9.10461 3.83684 8.74953C3.05863 8.39445 2.21282 8.21189 1.35742 8.21438" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_5" fill="currentColor"

d="M20.2148 14.2144H23.6434" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_6" fill="currentColor"

d="M23.6433 20.2144C22.7879 20.2169 21.9421 20.0343 21.1639 19.6792C20.3857 19.3241 19.6935 18.8049 19.1348 18.1572" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_7"fill="currentColor"

d="M19.1348 10.2715C19.6935 9.62381 20.3857 9.10461 21.1639 8.74953C21.9421 8.39445 22.7879 8.21189 23.6433 8.21438" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_8" fill="currentColor"

d="M14.626 6.79153C15.2819 6.41685 15.8272 5.87544 16.2065 5.22216C16.5858 4.56888 16.7857 3.82695 16.786 3.07153" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_9" fill="currentColor"

d="M8.21484 3.07153C8.21509 3.82695 8.415 4.56888 8.79432 5.22216C9.17364 5.87544 9.7189 6.41685 10.3748 6.79153" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_10" fill="currentColor"

d="M4.97461 12.5H20.026" stroke="#000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</g>
<defs>
<clipPath id="clip0_5374_44672">
<rect width="24" height="24" fill="white" transform="translate(0.5 0.5)"/>
</clipPath>
</defs>
</svg>
  )
}

export function IconBug({ direction, style, ...props })
{
  return <Icon component={IconSvg} {...props} style={{
    ...style, transform: {
    "top": "rotate(90deg)",
    "right": "rotate(180deg)",
    "left": "",
    "bottom": "rotate(-90deg)",
  }[direction] }} />
}
