import React from "react";
import styles from "./SetPasswordModal.module.css";
import { Button, Form, Input, Alert, Space, Typography } from "antd";
import { IconClose } from "../../../icons/IconClose";
import { useDispatch, useSelector } from "react-redux";
import PreloaderNew from "../PreloaderNew/PreloaderNew";
import { setPassword } from "../../../store/auth";
import { getChangePassLoader, getError } from "../../../store/auth/selectors";

const { Title } = Typography;

function SetPasswordForm({ close }) {
  const isLoading = useSelector(getChangePassLoader);
  const errors = useSelector(getError);
  const dispatch = useDispatch();
  return (
    <>
      <Space
        style={{
          display: "flex",
          justifyContent: "end",
          paddingInline: 16,
          height: 56,
        }}
      >
        <Button
          onClick={close}
          icon={<IconClose style={{ width: 12, height: 12 }} />}
          type="ghost"
          style={{
            width: 32,
            height: 32,
            backgroundColor: "#F6F8FD",
            padding: 0,
          }}
        />
      </Space>

      <div
        style={{
          padding: "80px 64px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {isLoading && <PreloaderNew />}
        <Title style={{ marginBottom: "24px" }} level={2}>
          Изменить пароль
        </Title>
        <Form
          size="large"
          name="auth"
          layout="vertical"
          requiredMark={false}
          className={styles.form}
          style={{ width: "100%", position: "relative" }}
          onFinish={({ passwordNew, passwordOld }) => {
            dispatch(
              setPassword({
                new_password: passwordNew,
                current_password: passwordOld,
              })
            );
          }}
        >
          {errors && <Alert message={errors} type="error" showIcon />}
          {/* {isLoading && <PreloaderNew />} */}
          <Form.Item
            label="Старый Пароль"
            name="passwordOld"
            hasFeedback
            className={styles.input}
            rules={[
              { required: true, message: "Пожалуйста, введите старый пароль" },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Новый Пароль"
            name="passwordNew"
            hasFeedback
            className={styles.input}
            rules={[
              { required: true, message: "Пожалуйста, введите новый пароль" },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Повторите Пароль"
            name="passwordNewConfirm"
            dependencies={["passwordNew"]}
            hasFeedback
            className={styles.input}
            rules={[
              {
                required: true,
                message: "Пожалуйста, повторите новый пароль!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("passwordNew") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Пароли не совпадают!"));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Space
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: 56,
            }}
          >
            <Button
              type="primary"
              onClick={close}
              style={{
                width: "168px",
                borderRadius: 4,
                fontSize: 14,
                border: "1px solid #CED9EE",
                background: "transparent",
                color: " #2E496F",
              }}
            >
              Отменить
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isLoading ? true : false}
              style={{
                width: "168px",
                borderRadius: 4,
                fontSize: 14,
                background: "#2E496F",
              }}
            >
              Изменить
            </Button>
          </Space>
        </Form>{" "}
      </div>
    </>
  );
}

export default SetPasswordForm;
