import React from "react";
import styles from "./LoginNew.module.css";
import background_image from "../../../images/loginBackground.jpg";
import { useSelector, useDispatch } from "react-redux";
import { getLogin } from "../../../store/auth";
import { getAuthLoader, getLoginError } from "../../../store/auth/selectors";
import { Form, Input, Button, Spin, Alert, Typography } from "antd";
import { getUrlLoaders } from "../../../store/urlfilter/selectors";
import PreloaderNew from "../PreloaderNew/PreloaderNew";

const { Title } = Typography;

function LoginNew() {
  const dispatch = useDispatch();
  const errors = useSelector(getLoginError);
  const isLoader = useSelector(getUrlLoaders);
  const isLoading = useSelector(getAuthLoader);

  return (
    <Spin size="large" spinning={isLoader}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto 490px",
          height: "100vh",
        }}
      >
        <img
          src={background_image}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          alt="background"
        />
        <div
          style={{
            padding: "80px 64px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Title level={2} style={{ marginBottom: 24 }}>
            Вход на портал
          </Title>
          {/* {errors && <Alert type="error" message={JSON.stringify(errors, null, 4)} style={{ marginBottom: 16, maxWidth: 350 }} />} */}
          {errors && (
            <Alert
              message="Authorization Error"
              description={errors}
              type="error"
              showIcon
            />
          )}
          <Form
            size="large"
            name="auth"
            layout="vertical"
            requiredMark={false}
            className={styles.form}
            style={{ width: "100%", position: "relative" }}
            onFinish={({ email, password }) => {
              const data = { email, password };
              dispatch(getLogin(data));
            }}
          >
            {isLoading && <PreloaderNew />}
            <Form.Item
              label="E-mail"
              className={styles.input}
              name="email"
              rules={[
                { required: true, message: "Пожалуйста, введите email!" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Пароль"
              name="password"
              className={styles.input}
              rules={[
                { required: true, message: "Пожалуйста, введите пароль!" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <div
                style={{
                  display: "flex",
                }}
              >
                {/* <Form.Item
                                    style={{ marginBottom: 0 }}
                                    name="remember"
                                    valuePropName="checked"
                                >
                                    <Checkbox>Запомнить меня</Checkbox>
                                </Form.Item>
                                <Popover
                                    overlayInnerStyle={{ padding: "20px 24px" }}
                                    content={<Text style={{ maxWidth: 220, display: "block" }}>
                                        Поставьте галочку, если хотите входить без необходимости повторного ввода данных
                                    </Text>}
                                    trigger="click"
                                >
                                    <button style={{ background: "none", border: "none", padding: 0 }}>
                                        <IconInfo style={{ color: "#327FEF" }} />
                                    </button>
                                </Popover> */}
                <div style={{ flexGrow: 1 }} />
                <Button
                  type="link"
                  size="small"
                  style={{ padding: 0, fontSize: 14 }}
                  href="/passrecover"
                >
                  Забыли пароль?
                </Button>
              </div>
            </Form.Item>

            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={isLoading ? true : false}
                style={{
                  width: "100%",
                  borderRadius: 4,
                  fontSize: 14,
                  background: "#2E496F",
                }}
              >
                Войти
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
}

export default LoginNew;
